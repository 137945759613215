import { 
  AddProductRequest,
  UpdateProductRequest,
  AddProduct,
  Product,
  GetSiteModificationMetricsRequest,
  SitesClient
} from '@acst/mono-corp-sites';

var mesa = require("../../store/mesa_client.js").default;

const setupRequest = (req, obj) => {
  for (const prop in req) { // eslint-disable-line no-restricted-syntax
    if (prop.startsWith('set') && prop !== 'setCreatedAt' && prop !== 'setUpdatedAt') {
      let fieldName = prop.substr(3);
      fieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);

      if (typeof obj[fieldName] !== 'undefined') {
        req[prop](obj[fieldName]);
      }
    }
  }

  return req;
}

const getDefaultState = () => {
  return {
    product: {},
    products: [],
    productStatuses: [
      'Active', 'Inactive'
    ],
    productMetrics: [],
    sitesClient: new SitesClient(mesa.do, "")
  }
}

const state = getDefaultState()

const api = {
  listProducts: async () => {
    const response = (await state.sitesClient.listProducts()).toObject();
    return response.productsList.map(product => ({
      ...product,
      statusText: product.status === 1 ? 'Active' : 'Inactive'
    }));
  },
  addProduct: (product) => {
    let productRequest = new AddProductRequest();
    let productModel = new AddProduct();
    setupRequest(productRequest, {
      product: setupRequest(productModel, product),
    });
    return state.sitesClient.addProduct(productRequest);
  },
  updateProduct: (product) => {
    let productRequest = new UpdateProductRequest();
    let productModel = new Product();
    setupRequest(productRequest, {
      product: setupRequest(productModel, product),
    });
    return state.sitesClient.updateProduct(productRequest);
  },
  getProductMetrics: async ({ realmSiteId, productId }) => {
    let request = new GetSiteModificationMetricsRequest();
    setupRequest(request, { productId, realmSiteId });
    return (await state.sitesClient.getSiteModificationMetrics(request)).toObject();
  },
}

export default {
    state,

    getters: {
      getProduct(state) {
        return state.product;
      },
      getProducts(state) {
        return state.products;
      },
      getProductStatuses(state) {
        return state.productStatuses;
      },
      getProductMetrics(state) {
        return state.productMetrics;
      },
    },

    mutations: {
      SET_PRODUCT(state, payload) {
        state.product = payload;
      },
      SET_PRODUCTS(state, payload) {
        state.products = payload;
      },
      ADD_PRODUCT(state, product) {
        state.products.push(product);
      },
      DELETE_PRODUCT(state, index) {
        state.products.splice(index, 1);
      },
      UPDATE_PRODUCT(state, payload) {
        const index = state.products.findIndex(x => x.productId === payload.productId);
        Object.assign(state.products[index], payload);
      },
      SET_PRODUCT_METRICS(state, payload) {
        state.productMetrics = payload;
      },
    },
      
    actions: {
      setProduct({ commit }, product) {
        commit('SET_PRODUCT', product);
      },
      setProducts({ commit }, products) {
        commit('SET_PRODUCTS', products);
      },
      async addProduct({ commit }, product) {
        await api.addProduct(product);
        commit('ADD_PRODUCT', product);
      },
      async updateProduct({ commit }, product) {
        await api.updateProduct(product);
        commit('UPDATE_PRODUCT', product);
      },
      deleteProduct({ commit }, index) {
        commit('DELETE_PRODUCT', index);
      },
      async listProducts({ commit }) {
        const products = await api.listProducts();
        commit('SET_PRODUCTS', products);
      },
      async getProductMetrics({ commit }, payload) {
        const metrics = await api.getProductMetrics(payload);
        commit('SET_PRODUCT_METRICS', metrics);
      },
    }
}