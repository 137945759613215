import {
  bus
} from '../../../main'

export default {
  name: 'account-search',
  components: {
  },
  data () {
    return {
      searchDialog: false,
      errorDialog: false,
      errorMessage: "",
      lastSeenBeginDate: new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10),
      lastSeenEndDate: new Date().toISOString().substr(0,10),
      lockedBeginDate: new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10),
      lockedEndDate: new Date().toISOString().substr(0,10),
      createdAtBeginDate: new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10),
      createdAtEndDate: new Date().toISOString().substr(0,10),
      menuCreatedAtStart: false,
      menuCreatedAtEnd: false,
      menuLockedAtStart: false,
      menuLockedAtEnd: false,
      menuLastSeenStart: false,
      menuLastSeenEnd: false,
      emailState: "",
      emailValidatedState: false,
      siteIDState: "",
      showDatePicker: false,
    }
  },

  created () {
    bus.$on('resetCreatedStartDate', () => {
      this.createdAtBeginDate = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
    });

    bus.$on('resetCreatedEndDate', () => {
      this.createdAtEndDate = new Date().toISOString().substr(0,10);
    });

    bus.$on('resetLockedStartDate', () => {
      this.lockedBeginDate = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
    });

    bus.$on('resetLockedEndDate', () => {
      this.lockedEndDate = new Date().toISOString().substr(0,10);
    });

    bus.$on('resetLastSeenStartDate', () => {
      this.lastSeenBeginDate = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
    });

    bus.$on('resetLastSeenEndDate', () => {
      this.lastSeenEndDate = new Date().toISOString().substr(0,10);
    });

    bus.$on('resetEmail', () => {
      this.emailState = "";
    });
  },

  mounted() {},

  methods: {
    searchAccounts() {
      // add a buffer to catch today's accounts
      var createdTodayDateBuffer = new Date(this.createdAtEndDate);
      var newCreatedEndDate = new Date(createdTodayDateBuffer.getTime()).toISOString().substr(0, 10);

      var lockedTodayDateBuffer = new Date(this.lockedEndDate);
      var newLockedEndDate = new Date(lockedTodayDateBuffer.getTime()).toISOString().substr(0, 10);

      var lastSeenTodayDateBuffer = new Date(this.lastSeenEndDate);
      var newLastSeenEndDate = new Date(lastSeenTodayDateBuffer.getTime()).toISOString().substr(0, 10);

      // set the new filter object
      if (this.validateSearch()) {
        var searchFilters;
        searchFilters = {
          siteID: this.siteIDState,
          email: this.emailState,
          emailValidated: this.emailValidatedState,
          createdAt: {
            startDate: this.createdAtBeginDate,
            stopDate: newCreatedEndDate
          },
          lockedAt: {
            startDate: this.lockedBeginDate,
            stopDate: newLockedEndDate
          },
          lastSeen: {
            startDate: this.lastSeenBeginDate,
            stopDate: newLastSeenEndDate
          },
        }
        this.searchDialog = false;

        // set the filters in the store
        this.$store.dispatch('settingAccountSearchFilters', searchFilters);
        bus.$emit('listAccounts');
      }
    },

    openFilters()  {
      this.searchDialog = true;
    },

    closeFilters()  {
      this.searchDialog = false;
      this.resetForm();
    },

    dismissError() {
      this.errorMessage = "";
      this.errorDialog = false;
    },

    validateSearch() {
      if (this.createdAtEndDate < this.createdAtBeginDate) {
        this.errorMessage = "Start date for 'Created On' must be before End Date"
        this.errorDialog =  true;
        this.createdAtBeginDate = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
        this.createdAtEndDate = new Date().toISOString().substr(0,10);
        return false
      }

      if (this.lockedEndDate < this.lockedBeginDate) {
        this.errorMessage = "Start date for 'Locked' must be before End Date"
        this.errorDialog =  true;
        this.lockedBeginDate = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
        this.lockedEndDate = new Date().toISOString().substr(0,10);
        return false
      }

      if (this.lastSeenEndDate < this.lastSeenBeginDate) {
        this.errorMessage = "Start date for 'Locked' must be before End Date"
        this.errorDialog =  true;
        this.lastSeenBeginDate = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
        this.lastSeenEndDate = new Date().toISOString().substr(0,10);
        return false
      }
      return true
    },

    resetForm() {
      this.lastSeenBeginDate = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
      this.lastSeenEndDate = new Date().toISOString().substr(0,10);
      this.lockedBeginDate = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
      this.lockedEndDate = new Date().toISOString().substr(0,10);
      this.createdAtBeginDate = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
      this.createdAtEndDate = new Date().toISOString().substr(0,10);
      this.emailState = "";
      this.emailValidatedState = "";
      this.siteIDState = "";
    }
  }
}