import ExpandableNavBar from '../../components/ExpandableNavBar/index.vue'
export default {
  name: 'conversions',
  components: {
    ExpandableNavBar
  },
  props: [],
  data: () => ({
    navItems: [
      { link: '../Conversions/ListView', title: 'Conversions Table', icon: 'mdi-view-dashboard' },
    ],
    backgroundColor: '#BDBDBD',
    maintModeInterval: undefined,
  }),
  mounted() {
    this.$store.dispatch('getMaintenanceModeStatus');
    this.maintModeInterval = setInterval(() => {
      this.$store.dispatch('getMaintenanceModeStatus');
    }, 10000);
  },
  beforeDestroy() {
    if (this.maintModeInterval) {
      clearInterval(this.maintModeInterval);
    }
  },
  computed: {
    computedBackgroundColor: function () {
      if (this.$store.getters.getTheme) {
        this.backgroundColor='black';
      } else {
        this.backgroundColor='#f2f3f6';
      }
      return this.backgroundColor;
    }
  },
}