import { ConversionRequest, ImportStatus, JobStatus, JobType } from "@acst/mono-realm-conversions";
import ConversionsExpandErrorTableRow from "../ConversionsExpandErrorTableRow";
import filters from "@/filters/filters"

export default {
  name: 'conversions-import-feedback',
  components: {
    ConversionsExpandErrorTableRow,
  },
  props: {
    poll: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      importLogIntervalID: undefined,
      jobLogIntervalID: undefined,
      expanded_file: undefined,
      headers: function(){
        let headerList = [
          { text: 'Status', value: 'status', align: 'center', sortable: false, },
          { text: 'File Name', value: 'fileName', align: 'center', sortable: false, },
          { text: 'Start Time', value: 'startTime', align: 'center', sortable: false, sort: (a,b) => !a ? 1 : !b ? -1 : b.seconds - a.seconds, },
          { text: 'End Time', value: 'lastUpdated', align: 'center', sortable: false, sort: (a,b) => !a ? 1 : !b ? -1 : b.seconds - a.seconds, }
        ];

        const colWidth = 3;
        const width = 100/((headerList.length + 1) * colWidth) - 1
        headerList.forEach(h => {
          h.width = `${width * colWidth}%`
        });
        headerList.unshift({ text: '', value: '', align: 'center', width: `${width}%`})
        return headerList;
      }(),
      ImportStatus,
      ImportStatusByValueMap: Object.fromEntries(
        Object.entries(ImportStatus)
        .map(([k,v]) => [
          v,
          k.replace('IMPORT', '')
            .toLowerCase()
            .replace(/^[a-z]/, match => match.toUpperCase())
        ])
      ),
      importErrorHeaders : [
        { text: 'Id', value: 'id', sortable: false, },
        { text: 'Path', value: 'path', sortable: false, },
        { text: 'Error', value: 'error', sortable: false, },
      ]
    }
  },
  mounted() {
    this.fetchJobLog();
    this.fetchImportLog();
    if (this.poll) {
      this.handlePollingForJobStatus();
      this.handlePollingForFeedback();
    }
  },
  beforeDestroy() {
    this.$store.dispatch('clearPolling', this.jobLogIntervalID);
    this.$store.dispatch('clearPolling', this.importLogIntervalID);
    this.$store.dispatch('resetConversionsPollForImportFeedback');
  },
  watch: {
    poll(newVal) {
      if (newVal) {
        this.handlePollingForJobStatus();
        this.handlePollingForFeedback();
      } else {
        this.$store.dispatch('getConversionDetails', this.newConversionRequest());
        this.$store.dispatch('clearPolling', this.jobLogIntervalID);
        this.$store.dispatch('clearPolling', this.importLogIntervalID);
      }
    }
  },
  computed: {
    items() {
      if (this.importJobIsPendingOrQueued) {
        return [];
      }
      return this.$store.getters.getConversionsImportLog ?? [];
    },
    importJob() {
      let filteredJobs = this.$store.getters.getConversionsJobLog.filter(j => j.type === JobType.JOBTYPEIMPORT);
      return filteredJobs.length && filteredJobs[0];
    },
    importJobIsPendingOrQueued() {
      return this.importJob && (this.importJob.status === JobStatus.JOBSTATUSPENDING || this.importJob.status === JobStatus.JOBSTATUSQUEUED);
    },
    importJobIsDone() {
      return this.importJob && (this.importJob.status === JobStatus.JOBSTATUSERRORED || this.importJob.status === JobStatus.JOBSTATUSCOMPLETED);
    },
    showFileErrors() {
      return file => file.status === ImportStatus.IMPORTERRORED && file.fileName === this.expanded_file;
    },
  },
  methods: {
    formatSeconds: filters.formatSeconds,
    fetchImportLog() {
      return this.$store.dispatch('getImportLog', this.newConversionRequest()).then(() => {
        if (this.items.length) {
          let isComplete = this.items.reduce((acc, cur) => cur.status !== ImportStatus.IMPORTCOMPLETED ? cur.status : acc, ImportStatus.IMPORTCOMPLETED) === ImportStatus.IMPORTCOMPLETED;
          if (isComplete) {
            this.$store.dispatch('resetConversionsPollForImportFeedback');
          } else if (!this.$store.getters.getConversionsPollForImportFeedback) {
            this.$store.dispatch('setConversionsPollForImportFeedback', true);
          }
        }
      });
    },
    fetchJobLog() {
      return this.$store.dispatch('getConversionJobLog', this.newConversionRequest()).then(() => {
        if (this.importJobIsDone) {
          this.$store.dispatch('resetConversionsPollForImportFeedback');
        } else if (this.importJob && !this.$store.getters.getConversionsPollForImportFeedback) {
          this.$store.dispatch('setConversionsPollForImportFeedback', true);
        }
      });
    },
    toggleExpandedFile(file) {
      this.expanded_file = this.expanded_file === file.fileName ? undefined : file.fileName;
    },
    handlePollingForJobStatus() {
      this.$store.dispatch('initPolling', {
        cb: this.fetchJobLog,
        duration: 1000,
        debubMsg: 'polling for job statuses',
      }).then(ivlID => {
        this.jobLogIntervalID = ivlID;
      });
    },
    handlePollingForFeedback() {
      this.$store.dispatch('initPolling', {
        cb: this.fetchImportLog,
        duration: 1000,
        debugMsg: 'polling for import feedback',
      }).then(ivlID => {
        this.importLogIntervalID = ivlID;
      });
    },
    newConversionRequest() {
      let req = new ConversionRequest();
      req.setConversionId(this.$route.params.id);
      return req;
    }
  }
}


