import ConversionsSearch from "../ConversionsSearch/index.vue"
import ConversionsCreate from "../ConversionsCreate/index.vue"
import ConversionsFeedback from "../ConversionsFeedback/index.vue"
import ConversionsInMaintenanceAlert from "@/components/Conversions/ConversionsInMaintenanceAlert";
import filters from "../../../filters/filters"
import { ConversionListRequest } from "@acst/mono-realm-conversions";
import { CheckUserRequest } from "@acst/mono-realm-conversions";

import {
  mapGetters
} from 'vuex';
import {
  bus
} from '../../../main'

export default {
  name: 'conversions-list-view',
  components: {
    ConversionsSearch,
    ConversionsCreate,
    ConversionsFeedback,
    ConversionsInMaintenanceAlert,
  },
  data() {
    return {
      footerProps: {
        'items-per-page-options': [],
        'items-per-page-text': ''
      },
      search: '',
      loading: true,
      initialErrorMessage: '',
      superAdmin: false,
      totalConversions: 1000,
      totalFetched: 0,
      page: 1,
      itemsPerPage: 10,
      allConversions: [],
      displayedConversions: [],
      searchFilterPills: [],
      statusEnum: {
        0: 'New',
        1: 'Ready',
        2: 'Running',
        3: 'Paused',
        4: 'Errored',
        5: 'Canceled',
        6: 'Completed',
        7: 'Running With Errors',
        8: 'Ready to Copy Site',
        9: 'Copying Site',
        10: 'Error with Copy Site',
        11: 'Copy Site Finished',
      },
      typeEnum: {
        0: 'NotSpecified',
        1: 'Full',
        2: 'People',
        3: 'Accounting',
      },
    }
  },

  beforeDestroy () {
    this.$store.dispatch('settingConversionSearchFilters', []);
  },

  created() {
    // user permission check
    this.superAdmin = this.$store.getters.hasPermission('conversions:superadmin');
  },

  mounted() {
    bus.$emit('resetTimer');
    setTimeout(() => {
      this.initialErrorMessage = this.$store.getters.getConversionsInitialErrorMessage;
    }, 100);

    this.getConversions(0, this.itemsPerPage)
    this.checkUser()
  },

  computed: {
    ...mapGetters(['getConversionsInitialErrorMessage']),
    params() {
      return {
        query: this.search
      };
    },
    headers() {
      let headers = [
        {
          text: 'Status',
          value: 'status',
          align: "left",
          sortable: false
        },
        {
          text: 'Site Number',
          value: 'siteNumber',
          sortable: false
        },
        {
          text: 'Name',
          value: 'siteName',
          sortable: false
        },
        {
          text: 'Type',
          value: 'type',
          sortable: false
        },
        {
          text: 'Created By',
          value: 'createdBy',
          sortable: false
        },
        {
          text: 'Date Created',
          value: 'createdAt',
          sortable: false
        },
      ];

      if(this.superAdmin) {
        headers.splice(2, 0, {
          text: 'Conversion Id',
          value: 'id',
          sortable: false
        });
      }

      return headers
    },
    searchFilterValues() {
      return this.$store.getters.getConversionSearchFilters;
    },
  },
  watch: {
    searchFilterValues: {
      deep: true, 
      handler() {
        this.searchFilterPills == this.searchFilterPills.splice(0, this.searchFilterPills.length);
        //  Push active searchFilters to display
        if (this.searchFilterValues.siteName != undefined && this.searchFilterValues.siteName != "") {
          this.searchFilterPills.push(this.makeNewFilterObject("Site Name", this.searchFilterValues.siteName, this.searchFilterValues.siteName));
        }

        if (this.searchFilterValues.siteNumber != undefined && this.searchFilterValues.siteNumber != "") {
          this.searchFilterPills.push(this.makeNewFilterObject("Site Number", this.searchFilterValues.siteNumber, this.searchFilterValues.siteNumber));
        }

        if (this.searchFilterValues.onlyFollowed == true) {
          this.searchFilterPills.push(this.makeNewFilterObject("Followed By Me", "", "True"));
        }

        if (this.searchFilterValues.statuses != undefined && this.searchFilterValues.statuses != [] && this.searchFilterValues.statuses != "") {
          var s
          for (s = 0; s < this.searchFilterValues.statuses.length; s++) {
            this.searchFilterPills.push(this.makeNewFilterObject("Status", this.searchFilterValues.statuses[s], this.statusEnum[this.searchFilterValues.statuses[s]]));
          }
        }

        if (this.searchFilterValues.conversionTypes != undefined && this.searchFilterValues.conversionTypes != [] && this.searchFilterValues.conversionTypes != "") {
          var t
          for (t = 0; t < this.searchFilterValues.conversionTypes.length; t++) {
            this.searchFilterPills.push(this.makeNewFilterObject("Conversion Type", this.searchFilterValues.conversionTypes[t], this.typeEnum[this.searchFilterValues.conversionTypes[t]]));
          }
        }

        if (this.searchFilterValues.dateCreatedOnStart != undefined && this.searchFilterValues.dateCreatedOnStart != "") {
          this.searchFilterPills.push(this.makeNewFilterObject("Created After", this.searchFilterValues.dateCreatedOnStart, this.searchFilterValues.dateCreatedOnStart));
        }

        if (this.searchFilterValues.dateCreatedOnEnd != undefined && this.searchFilterValues.dateCreatedOnEnd != "") {
          this.searchFilterPills.push(this.makeNewFilterObject("Created Before", this.searchFilterValues.dateCreatedOnEnd, this.searchFilterValues.dateCreatedOnEnd));
        }

        if (this.searchFilterValues.includeInactive == true) {
          this.searchFilterPills.push(this.makeNewFilterObject("Archived Included", "", "True"));
        }

        //reset list
        this.allConversions = [];
        this.displayedConversions = [];
        this.totalConversions = 1000;
        this.totalFetched = 0;
        if (this.page == 1) {
          this.getConversions(0, this.itemsPerPage)
        } else {
          //page watcher will handle reload
          this.page = 1;
        }
      }
    },
    page: {
      handler(page) {
        let offset = (page - 1) * this.itemsPerPage;
        if(offset >=  this.totalFetched) {
          this.getConversions(offset, this.itemsPerPage);
        } else {
          let end = offset + this.itemsPerPage < this.totalConversions ? offset + this.itemsPerPage : this.totalConversions;
          this.displayedConversions = this.allConversions.slice(offset, end);
        }
      },
      deep: true,
    }
  },
  methods: {
    secondsToDate: filters.secondsToDate,
    formatSeconds: filters.formatSeconds,
    
    // removeFilter removes a search filter from the searchFilters array and the vuex filters when a filter is clicked
    removeFilter(filter) {
      // update vuex with the updated list
      this.$store.dispatch('removeConversionSearchFilter', filter);
    },

    // resets the searchFilters
    resetFilters() {
      this.$store.dispatch('settingConversionSearchFilters', []); // Reset displayed searchFilters
    },
    
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
    },

    makeNewFilterObject(type, value, name) {
      return {
        filterType: type,
        filterValue: value,
        filterDisplayName: name
      }
    },

    getConversions(offset, limit) {
      this.loading = true;
      let listConversionsRequest = new ConversionListRequest();
      listConversionsRequest.setSiteNumber(this.searchFilterValues.siteNumber);
      listConversionsRequest.setSiteName(this.searchFilterValues.siteName);
      listConversionsRequest.setStatusList(this.searchFilterValues.statuses);
      listConversionsRequest.setStartDate(this.searchFilterValues.dateCreatedOnStart);
      listConversionsRequest.setStopDate(this.searchFilterValues.dateCreatedOnEnd);
      listConversionsRequest.setIncludeInactive(this.searchFilterValues.includeInactive);
      listConversionsRequest.setConversionTypesList(this.searchFilterValues.conversionTypes);

      listConversionsRequest.setOffset(offset);
      listConversionsRequest.setLimit(limit);

      if (this.searchFilterValues.onlyFollowed) {
        listConversionsRequest.setUserIdsList(this.searchFilterValues.followerIds)
      }

      this.$store.dispatch("getConversions", listConversionsRequest).then((value) => {
        let count = value.conversionsList.length;
        var items = this.formatConversions(value.conversionsList);
        this.allConversions = this.allConversions.concat(items)
        this.loading = false;
        this.totalFetched = this.totalFetched + count;

        //if count is zero or less than limit we have reached end of list
        if(count == 0) {
          this.totalConversions = offset + count;
          this.page = this.page - 1;
          return;
        } else if(count != limit) {
          this.totalConversions = offset + count;
        } else {
          this.totalConversions = offset + limit + this.itemsPerPage;
        }
        
        //set displayed conversions to those in the selected page
        let end = offset + limit < this.totalConversions ? offset + limit : this.totalConversions;
        this.displayedConversions = this.allConversions.slice(offset, end);
      })
    },

    formatConversions(conversions) {
      let items = [];
      if (conversions == null) {
        return;
      }

      var i;
      var newDataRowItem;
      var newStatusEnum;
      var newTypeEnum;
      var colorLookup;
      for (i = 0; i < conversions.length; i++) {
        newStatusEnum = this.statusEnum[conversions[i].status]; // map
        newTypeEnum = this.typeEnum[conversions[i].conversionType]; // map
        var isPausedString = '';
        var newStatusWord;

        if (newStatusEnum == undefined) {
          newStatusWord = 'New'
        }
        else {
          newStatusWord = newStatusEnum.replace('Conversion', '');
        }

        if (newStatusWord == "Running With Errors" || newStatusWord == "Error with Copy Site") {
          colorLookup = "runningWithErrors";
        }
        else {
          colorLookup = newStatusWord.toLowerCase();
        }

        if (conversions[i].isPaused) {
          isPausedString = ' (Paused)'
        }

        newDataRowItem = {
          id: conversions[i].id,
          siteID: conversions[i].siteId,
          siteNumber: conversions[i].siteNumber,
          siteName: conversions[i].siteName,
          status: newStatusWord + isPausedString,
          colorLookup: colorLookup,
          type: newTypeEnum,
          createdBy: conversions[i].createdBy,
          createdAt: conversions[i].createdAt.seconds
        }

        // colorLookup matches the ListView colors to dual status of newStatusWord and paused for correct color.
        if (conversions[i].isPaused) {
          newDataRowItem.colorLookup = 'Paused';
        }

        items.push(newDataRowItem);
      }

      return items;
    },

    // get the current dev-console user and make sure they are added to the conversion user's table
    checkUser() {
      let checkUserRequest = new CheckUserRequest();
      let user = this.$store.getters.getUserObject;

      checkUserRequest.setEmailAddress(user.email);
      checkUserRequest.setFirstName(user.givenName);
      checkUserRequest.setLastName(user.familyName);
      checkUserRequest.setRole(0);

      this.$store.dispatch("checkWatcher", checkUserRequest);
    }
  },
}