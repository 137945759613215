import { AddSiteRequest } from "@acst/mono-corp-sites";
import { AddSiteProductRequest } from "@acst/mono-corp-sites";
// import { DeleteSiteProductRequest } from "@acst/mono-corp-sites";
import { Address } from "@acst/mono-corp-sites";
import {
  bus
} from '../../../main'

export default {
  name: 'client-create',
  components: {
  },

  data () {
    return {
      permission: true,
      e6: 1,
      createDialog: false,
      stepNum: 1,
      errorMessage: [],
      showError: false,
      // this is a stub number until we are able to get the real external id
      externalID: "5",
      siteNumberState: "",
      siteNameState: "",
      crmIdState: "",
      addressLine1State: "",
      addressLine2State: "",
      addressCityState: "",
      addressRegionState: "",
      addressPostalCodeState: "",
      addressCountryState: "",
      mailingIsSameState: true,
      mailingAddressLine1State: "",
      mailingAddressLine2State: "",
      mailingAddressCityState: "",
      mailingAddressRegionState: "",
      mailingAddressPostalCodeState: "",
      mailingAddressCountryState: "",
      phoneState: "",
      websiteState: "",
      emailState: "",
      userRefState: "",
      timeZoneState: "",
      averageWeeklyAttendanceState: 0,
      logoUrlState: "",
      productState: [],
      productList: [],
      denominationsState: -1,
      denominationGroupState: -1,
      denominations: {},
      denominationGroups: {},

      // we will need to either list all of these, or make an endpoint to list them for us
      timeZoneList: [
        "Eastern Standard Time"
      ],

      // we will need to either list all of these, or make an endpoint to list them for us
      countryList: [
        "US"
      ],

      // we will need to either list all of these, or make an endpoint to list them for us
      regionList: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
    }
  },

  computed: {
    selectedDenominationGroup : function () {
      if (this.denominations != undefined) {
        var state = this;
        var denomsDisplay = Object.values(this.denominations).filter(function (denomination) {
          return denomination.enum >= state.denominationGroupState && denomination.enum < (state.denominationGroupState + 1000) && denomination.enum != 0;
        });
        return denomsDisplay;
      }
      return [];
    }
  },
  
  mounted() {
    this.loadProducts();
    this.$store.dispatch('getDenominationsList').then(() => {
      this.denominations = this.$store.getters.denominations;
      this.denominationGroups = this.$store.getters.denominationGroups;
    });
  },

  methods: {
    // form AddSiteRequest and call the endpoint
    createNewSite() {
      var req = new AddSiteRequest;
      req.setSiteNumber(this.siteNumberState);
      req.setSiteName(this.siteNameState);
      req.setCrmId(this.crmIdState);

      var address = new Address;
      address.setLine1(this.addressLine1State);
      address.setLine2(this.addressLine2State);
      address.setCity(this.addressCityState);
      address.setRegion(this.addressRegionState);
      address.setPostalCode(this.addressPostalCodeState);
      address.setCountry(this.addressCountryState);
      req.setAddress(address);

      req.setMailingIsSame(this.mailingIsSameState);

      if (!this.mailingAddressIsSameState) {
        var mailAddress = new Address;
        mailAddress.setLine1(this.mailingAddressLine1State);
        mailAddress.setLine2(this.mailingAddressLine2State);
        mailAddress.setCity(this.mailingAddressCityState);
        mailAddress.setRegion(this.mailingAddressRegionState);
        mailAddress.setPostalCode(this.mailingAddressPostalCodeState);
        mailAddress.setCountry(this.mailingAddressCountryState);

        req.setMailingAddress(mailAddress);
      }

      req.setPhone(this.phoneState);
      req.setWebsite(this.websiteState);
      req.setEmail(this.emailState);
      req.setUserRef(this.userRefState);
      req.setTimeZone(this.timeZoneState);
      req.setAverageWeeklyAttendance(this.averageWeeklyAttendanceState);
      req.setLogoUrl(this.logoUrlState);
      req.setDenomination(this.denominationsState);
      

      // Add the new site
      this.$store.dispatch('addSite', req).then(() => {
        // Add the new products after the site has been created
        setTimeout(() => {
          var p;
          for (p = 0; p < this.productState.length; p++) {
            this.addSiteProduct(this.productList[this.productState[p]].productId);
          }

          // Refresh the client list
          bus.$emit('listClients');
          this.createDialog = false;
          this.stepNum = 1;
          this.resetForm();
        }, 500);
      });
    },

    cancelCreate() {
      this.resetForm();
      this.createDialog = false;
      this.stepNum = 1;
      this.errorMessage = [];
      this.showError = false;
    },

    resetForm() {
      this.siteNumberState = "";
      this.siteNameState = "";
      this.crmIdState = "";
      this.denominationsState = -1;
      this.denominationGroupState = -1;
      this.addressLine1State = "";
      this.addressLine2State = "";
      this.addressCityState = "";
      this.addressRegionState = "";
      this.addressPostalCodeState = "";
      this.addressCountryState = "";
      this.mailingIsSameState = true;
      this.mailingAddressLine1State = "";
      this.mailingAddressLine2State = "";
      this.mailingAddressCityState = "";
      this.mailingAddressRegionState = "";
      this.mailingAddressPostalCodeState = "";
      this.mailingAddressCountryState = "";
      this.phoneState = "";
      this.websiteState = "";
      this.emailState = "";
      this.userRefState = "";
      this.timeZoneState = "";
      this.averageWeeklyAttendanceState = 0;
      this.logoUrlState = "";
      this.productState  = []
    },

     // Pull a list of all the products to display for filtering by products
     loadProducts() {
      this.$store.dispatch('getProductList').then(() => {
        setTimeout(() => {
          this.productList = this.$store.getters.allGlobalSitesProducts;
        }, 1000);
      });
    },

    moveToProductView() {
      if (this.validateCreate()) {
        this.stepNum = 2;
        this.errorMessage = [];
        this.showError = false;
      } else {
        this.showError = true;
      }
    },

    validateCreate() {
      this.errorMessage = [];
      var errorFound = false;

      if (this.denominationsState == -1) {
        this.errorMessage.push("Denomination is Required");
        errorFound = true;
      }
      if (this.siteNameState == "") {
        this.errorMessage.push("Site Name is Required");
        errorFound = true;
      }
      if (this.siteNumberState == "") {
        this.errorMessage.push("Site Number is Required");
        errorFound = true;
      }
      if (this.crmIdState == "") {
        this.errorMessage.push("CRM ID is Required");
        errorFound = true;
      }
      if (this.addressLine1State == "") {
        this.errorMessage.push("Address Line 1 is Required");
        errorFound = true;
      }
      if (this.addressCityState == "") {
        this.errorMessage.push("City is Required");
        errorFound = true;
      }
      if (this.addressCountryState == "") {
        this.errorMessage.push("Country is Required");
        errorFound = true;
      }
      if (this.addressPostalCodeState == "") {
        this.errorMessage.push("Postal Code is Required");
        errorFound = true;
      }
      if (this.addressRegionState == "") {
        this.errorMessage.push("Region is Required");
        errorFound = true;
      }
      if (this.phoneState == "") {
        this.errorMessage.push("Phone Number is Required");
        errorFound = true;
      }
      if (this.emailState == "") {
        this.errorMessage.push("Email Address is Required");
        errorFound = true;
      }
      if (this.averageWeeklyAttendanceState == 0) {
        this.errorMessage.push("Average Weekly Attendance is Required");
        errorFound = true;
      }
      if (this.userRefState == "") {
        this.errorMessage.push("User Ref is required");
        errorFound = true;
      }
      else if (this.userRefState.length != 36) {
        this.errorMessage.push("Invalid User Ref, must be UUID");
        errorFound = true;
      }
      if (this.timeZoneState == "") {
        this.errorMessage.push("Time Zone is Required");
        errorFound = true;
      }
      if (!this.mailingIsSameState) {
        if (this.mailingAddressLine1State == "") {
          this.errorMessage.push("If Mailing Address is Different, Mailing Address Line 1 is Required");
          errorFound = true;
        }
        if (this.mailingAddressCityState == "") {
          this.errorMessage.push("If Mailing Address is Different, Mailing Address City is Required");
          errorFound = true;
        }
        if (this.mailingAddressCountryState == "") {
          this.errorMessage.push("If Mailing Address is Different, Mailing Address Country is Required");
          errorFound = true;
        }
        if (this.mailingAddressPostalCodeState == "") {
          this.errorMessage.push("If Mailing Address is Different, Mailing Address Postal Code is Required");
          errorFound = true;
        }
        if (this.mailingAddressRegionState == "") {
          this.errorMessage.push("If Mailing Address is Different, Mailing Address Region is Required");
          errorFound = true;
        }
      }
      if (errorFound) {
        return false;
      }

      return true;
    },

    dismissErrorMessageAlert() {
      this.errorMessage = [];
      this.showError = false;
    },

    // Add a product to a site  
    addSiteProduct(productID) {
      // this check prevents further errors when there has been an issue with site create
      var siteID = this.$store.getters.currentSiteID;
      if (siteID != undefined && siteID != "") {
        var req = new AddSiteProductRequest;
        req.setSiteId(siteID);
        req.setProductId(productID);
        req.setExternalId(this.externalID);

        this.$store.dispatch('addSiteProduct', req);
      }
    },

    // // Remove a product from a site
    // deleteSiteProduct() {
    //   var req = new DeleteSiteProductRequest;
    //   req.setSiteId(this.$store.getters.clientDetails.siteID);
    //   req.setProductId(productID);
    //   req.setExternalId(this.externalID);

    //   this.$store.dispatch('deleteSiteProduct', req).then(() => {
    //     bus.$emit('refreshProducts');
    //   });
    // }
  },
}