import { GetErrorsRequest, ResetAreaStatusesRequest } from "@acst/mono-realm-conversions";

export default {
  name: 'conversions-area-errors-card',
  components: {},
  props: {
    areaType: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    resetCallback: {
      type: Function,
      required: true,
    }
  },
  data () {
    return {
      expandedMessageId: null,
      expandedDataId: null,
      expandedChunkMessageIndex: null,
      showRecordErrors: true,
      showChunkErrors: false,
      activeTab: 0,
    }
  },
  watch: {
    show(show) {
      if (!show) return;

      let req = new GetErrorsRequest();
      req.setConversionId(this.$route.params.id);
      req.setAreaType(this.areaType);
      this.$store.dispatch('getAreaStatusErrors', req);
    }
  },
  computed: {
    stagingTableName() {
      return this.$store.getters.getConversionsAreaErrors.tableName ?? '';
    }, 
    recordErrors() {
      return this.$store.getters.getConversionsAreaErrors.errorsList ?? [];
    },
    chunkErrors() {
      return this.$store.getters.getConversionsAreaErrors.chunkErrorsList ?? [];
    }
  },
  methods: {
    truncateText(text) {
      const maxChars = 120;
      
      if (text.length <= maxChars) return text;

      return text.slice(0,maxChars - 3)+'...';
    },
    messageText({entityId, message}) {
      if (entityId === this.expandedMessageId) {
        return message;
      }
      return this.truncateText(message);
    },
    dataText({entityId, data}) {
      if (entityId === this.expandedDataId) {
        return data;
      }
      return this.truncateText(data);
    },
    chunkMessageText(message, i) {
      if (this.expandedChunkMessageIndex === i) {
        return message;
      }
      return this.truncateText(message);
    },
    renderButtonForError(text) {
      return text !== this.truncateText(text)
    },
    resetAreaErrors() {
      let req = new ResetAreaStatusesRequest();
      req.setConversionId(this.$route.params.id);
      req.setAreasList([this.areaType]);

      this.$store.dispatch('resetConversionAreaStatuses', req).then(() => {
        this.resetCallback();
      })
    }
  }
}


