import {
    DevConsoleClient,
    ListApplicationTokensRequest,
    TokenRequest
} from "@acst/mono-ops-devconsole";
import router from '../../router';

var mesa = require("../../store/mesa_client.js").default;
export default {
    state: {
        consoleClient: new DevConsoleClient(mesa.do, ""),
        applications: {},
        applicationTokens: [],
        userTokens: [],
        newTokenValue: null,
    },
    // Accessors used to retrieve data from our global store
    getters: {
        // {TEST}
        //async getEnvironmentVars2({state}) {
        // state.consoleClient.getEnvironments().then(function(resp){
        //     console.log(resp.toObject())
        // })
        // .catch(function(resp) {
        //     console.log(resp);
        // });
        //}
        applications: (state) => state.applications,
        applicationTokens: (state) => state.applicationTokens,
        userTokens: (state) => state.userTokens,
        newTokenValue: (state) => state.newTokenValue,
    },
    // Setters that are used by 'Actions' to set data inside of our store.
    // Mutators are synchronus methods that are called by Asynchronus 'Actions' to "Do" things.
    mutations: {
        setConsoleClientURL(state, url) {
            state.consoleClient.setSourceURL(url);
        },
        setApplications(state, apps) {
            state.applications = apps;
        },
        addApplication(state, app) {
            state.applications.push(app);
        },
        deleteApplication(state, id) {
            state.applications = state.applications.filter(app => app.id !== id);
        },
        setApplicationTokens(state, tokens) {
            state.applicationTokens = tokens;
        },
        addApplicationToken(state, token) {
            state.applicationTokens.push([token.id, token.name]);
            state.newTokenValue = token.token;
        },
        addUserToken(state, token) {
            state.userTokens.push([token.id, token.name]);
            state.newTokenValue = token.token;
        },
        setUserTokens(state, tokens) {
            state.userTokens = tokens;
        },
        setToken(state, token) {
            const appTokenIdx = state.applicationTokens.findIndex(appToken => appToken[0] === token[0]);
            if (appTokenIdx > -1) {
                state.applicationTokens[appTokenIdx] = token;
            }

            const userTokenIdx = state.userTokens.findIndex(userToken => userToken[0] === token[0]);
            if (userTokenIdx > -1) {
                state.userTokens[userTokenIdx] = token;
            }

            if (token.token) {
                state.newTokenValue = token.token;
            }
        },
        removeToken(state, id) {
            const appTokenIdx = state.applicationTokens.findIndex(appToken => appToken[0] === id);
            if (appTokenIdx > -1) {
                state.applicationTokens.splice([appTokenIdx], 1)
            }

            const userTokenIdx = state.userTokens.findIndex(userToken => userToken[0] === id);
            if (userTokenIdx > -1) {
                state.userTokens.splice([userTokenIdx], 1)
            }
        },
        resetNewTokenValue(state) {
            state.newTokenValue = null;
        }
    },

    actions: {
        // Request JWT from DevCon-Core so we can log in successfully.
        // Until this promise returns we cannot make authenticated calls to DevConsole or Relay.
        // If you're getting 401's on initial page load this is most likely your issue!
        // Grab JWT and UserObj from response that came from DevCore.
        async getCoreToken({ state, dispatch }) {
            // Setup our request to retrieve a new token from Core service
            let newTokenReq = new TokenRequest();
            newTokenReq.setUrl(window.location.href);

            // Return our client call we're making to core
            return await state.consoleClient.token(newTokenReq).then(function (response) {
                window.localStorage.setItem("jwt", response.getToken());
                dispatch('saveUserObject', response.getUser().toObject());
                dispatch('storeUserPermissions', window.localStorage.getItem("jwt"));
                // This flag is used to determine if login flow has been completed on initial login
                dispatch('saveLoggingIn', true);
                router.push('/dashboard'); // redirect in-session
            })
                .catch(function (response) { // catch any errors from the backend service
                    console.log(`Unable to Log In: ${response.message}`);
                });
        },

        // Redirect requests such as logging in or being logged out
        async redirect({ state }, redirectRequest) {
            return await state.consoleClient.redirect(redirectRequest).then(function (response) {
                return response.toObject();
            })
                .catch(function (response) {
                    console.log(response);
                });
        },

        // Forcibly logout, clearing jwt, localstorage, database tokens, etc
        async logout({ state }) {
            return await state.consoleClient.logout();
        },

        // Wasn't being used??
        async validateLogin({ state }) {
            return await state.consoleClient.validateLogin();
        },

        async getLoggedInUserInfo({ state }) {
            return await state.consoleClient.getLoggedInUserInfo().then(function (response) {
                return response.toObject();
            });
        },

        // Below here is Admin-Area specific Console-Core Calls
        // Returns current environments from Core
        async getCoreEnvironments({ state }) {
            return await state.consoleClient.getEnvironments().then(function (resp) {
                return resp.toObject();
            })
                .catch(function (resp) {
                    console.log(resp);
                });
        },

        async updateEnvironmentsFromCore({ state, dispatch }) {
            return await state.consoleClient.getEnvironments().then(function (resp) {
                dispatch('setNewEnvVarsObject', resp.toObject().environmentvarsList);
            })
                .catch(function (resp) {
                    console.log(resp);
                });
        },

        // Adds a new environment to the database
        async addEnvironmentVariable({ state }, AddEnvironmentVariableRequest) {
            return await state.consoleClient.addEnvironment(AddEnvironmentVariableRequest).then(function (response) {
                return response.toObject();
            });
        },

        // Updates an existing environment to the database
        async updateEnvironmentVariable({ state }, UpdateEnvironmentVariableRequest) {
            return await state.consoleClient.updateEnvironment(UpdateEnvironmentVariableRequest).then(function (response) {
                return response.toObject();
            })
        },

        // Delete a given Environment based on id from Core-Db
        async deleteEnvironment({ state }, DeleteEnvironmentRequest) {
            return await state.consoleClient.deleteEnvironment(DeleteEnvironmentRequest).then(function (response) {
                return response.toObject();
            })
        },

        async getUserList({ state }) {
            return await state.consoleClient.getUserList().then(function (response) {
                return response.toObject();
            });
        },

        async getInactiveUserList({ state }) {
            return await state.consoleClient.getInactiveUserList().then(function (response) {
                return response.toObject();
            });
        },

        async getUserDetails({ state }, GetUserDetailsRequest) {
            return await state.consoleClient.getUserDetails(GetUserDetailsRequest).then(function (response) {
                return response.toObject();
            });
        },

        // ************************************************************************
        // TODO: Bug with refresh rate due to no returned value to update off of***
        // ************************************************************************
        // Update Permissions for Users
        // Null return, so we can't pivot off of this for a refresh. Change the GoLang Endpoint!
        async updateUserPermission({ state }, UpdatePermissionsRequest) {
            return state.consoleClient.updateUserPermissions(UpdatePermissionsRequest);
        },

        // Null return, so we can't pivot off of this for a refresh. Change the GoLang Endpoint!
        async deactivateUser({ state }, DeactivateUserRequest) {
            return await state.consoleClient.deactivateUser(DeactivateUserRequest).then(function (response) {
                return response.toObject();
            });
        },

        // Null return, so we can't pivot off of this for a refresh. Change the GoLang Endpoint!
        async activateUser({ state }, ActivateUserRequest) {
            return await state.consoleClient.activateUser(ActivateUserRequest).then(function (response) {
                return response.toObject();
            });
        },

        async listUserPermissions({ state }, ListPermissionRequest) {
            return await state.consoleClient.listUserPermissions(ListPermissionRequest).then(function (response) {
                return response.toObject();
            })
        },

        async updateConsoleClientURL(context, url) {
            context.commit("setConsoleClientURL", url);
        },

        async listUserTokens({ state, commit }) {
            const response = await state.consoleClient.listUserTokens();
            commit('setUserTokens', response.toObject().tokensMap);

        },

        async listApplicationTokens({ state, commit }, applicationID) {
            let request = new ListApplicationTokensRequest().setApplicationId(applicationID)
            const response = await state.consoleClient.listApplicationTokens(request);
            commit('setApplicationTokens', response.toObject().tokensMap);
        },

        async addUserToken({ state, commit }, request) {
            const response = await state.consoleClient.addUserToken(request);
            commit('addUserToken', response.toObject());
        },

        async addApplicationToken({ state, commit }, request) {
            const response = await state.consoleClient.addApplicationToken(request);
            commit('addApplicationToken', response.toObject());
        },

        async updateToken({ state, commit }, request) {
            const response = await state.consoleClient.updateToken(request);
            commit('setToken', response.toObject());
        },

        async deleteToken({ state, commit }, request) {
            await state.consoleClient.deleteToken(request);
            commit('removeToken', request.getId());
        },

        async getToken({ state, commit }, GetTokenRequest) {
            return await state.consoleClient.getToken(GetTokenRequest).then(function (response) {
                return response.toObject();
            }).catch(err => {
                console.log(err);
                commit('setErrorMessage', 'Error getting token: ' + err.message);
            });
        },

        async listApplications({ state, commit }) {
            const response = await state.consoleClient.listApplications();
            commit('setApplications', response.toObject().applicationsList);
        },

        async addApplication({ state, commit }, request) {
            const response = await state.consoleClient.addApplication(request);
            commit('addApplication', response.toObject());
        },

        async deleteApplication({ state, commit }, request) {
            await state.consoleClient.deleteApplication(request);
            commit('deleteApplication', request.getId());
        },

        clearNewTokenValue({ commit }) {
            commit('resetNewTokenValue');
        }
    }
}