// NOTE: This is the global notification bar that will show errors/warnings/etc on the Parent View.
// Avoid changing this unless you see a feature that you think would be nice to have at a global-level.

import { mapGetters } from 'vuex';
import { bus } from '@/main';

export default {
  data: () => ({
    msg: "",
    msgIsError: false,
    msgExists: false,
    timeout: -1,
  }),

  created () {
    bus.$on('notify', () => {
      this.openNotificationBar()
    })
  },

  methods: {
    ...mapGetters(['getNotification']),
    destroyNotification() {
      this.msg = ""
      this.msgIsError = false
      this.msgExists = false
    },

    openNotificationBar() {
      var self = this;
      setTimeout(() => {
        let notification = self.getNotification();
        self.msg = notification.message;
        self.msgIsError = notification.isError;
        if (self.msg != "") {
          self.msgExists = true
        }

        //Destroy error after timeout ends
        self.setSnackbarTimer
      }, 450);
    },

    setSnackbarTimer() {
      setTimeout(() => {
        this.destroyError; }, 
      this.timeout);
    }

  },

  destroyed () {
    this.destroyNotification()
  },
}