// NOTE: This module will be used as an example once Relay is ready for use.

// This module directly accesses only the parent service "Relay".
// Nested Services should have their own module created that contain their specific calls.
// import { ExampleClient } from "@acst/mono-ops-relay";
// var mesa = require("../../store/mesa_client.js").default;
export default {
    // state: {
    //     // ************************************************************************
    //     // TODO:
    //     // Change this from static -> use selectedEnvironment before committing.
    //     // ************************************************************************
    //     exampleClient: new ExampleClient(mesa.do, "")
    // },
    // // Accessors used to retrieve data from our global store
    // getters: {
    // },
    // // Setters that are used by 'Actions' to set data inside of our store.
    // // Mutators are synchronus methods that are called by Asynchronus 'Actions' to "Do" things.
    // mutations: {
    // },
      
    // actions: {
    //     async helloWorldExampleCall({state}) {
    //         return await state.exampleClient.helloWorldExample().then(function(response){
    //             return response.toObject();
    //         });
    //     },

    //     async helloWorld() {
    //         console.log("Hello There Mister!");
    //     }
    // }
}


