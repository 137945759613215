// NOTE: Use this as an example of how to build a new 'Area' from scratch
// An example of an Area-NavBar included.
import ExpandableNavBar from '../../components/ExpandableNavBar/index.vue'
export default {
  name: 'exampler',
  components: {
    ExpandableNavBar
  },
  props: [],
  data: () => ({
    // This object will drive what is shown, and can be navigated to, inside the dynamic NavBar in this Area.
    navItems: [
      { link: '../Exampler/Landing', title: 'Landing', icon: 'mdi-sheep' },
      { link: '../Exampler/Stuff', title: 'Stuff', icon: 'mdi-sheep' },
    ],
  }),
  computed: {
  },
  mounted () {
  },
  methods: {
  }
}


