import { InsertEnvironmentRequest, UpdateEnvironmentRequest, DeleteEnvironmentRequest } from "@acst/mono-ops-devconsole";
// import { RelayClient } from "@acst/mono-ops-relay";
export default {
  name: 'admin-environments',
  components: {},
  props: [],
  data () {
    return {
      showEnvModal: false,
      deleteDialog: false,
      formIsValid: false,
      labelText: '',
      connText: '',
      proxyText: '',
      inputRules: [],
      labelInputRules: [],
      envVariables: [],
      actionType: "",
      envVariableId: "",
      hasInitialized: false,
      envVariableIdForDeletion: "",
      connSuccess: false,
      connAttempted: false,
      envTestName: "",
      modalTitleText: "Something Broke!"
    }
  },
  computed: {

  },
  mounted () {
    this.CreateInputRules();
    this.RefreshEnvironmentVars();
  },
  methods: {
    // Form Validation in method form so we can fire it whenever we want!
    ValidateVarsInputs(){
      this.formIsValid = this.$refs.varsInputForm.validate();
    },

    // Input Rules applied to our inputs
    CreateInputRules() {
      this.inputRules.push(value => !!value || 'Required.');
      this.inputRules.push(value => (value && value.length > 0) || 'Required.');
      this.labelInputRules.push(value => !!value || 'Required.');
      this.labelInputRules.push(value => (value && value.length > 0) || 'Required.');
      this.labelInputRules.push(v => /^[a-z0-9\-/]*$/.test(v) || 'Lowercase, letters, numbers, and special `-` only.')
    },
    
    // Drives Dialog for Delete
    ShowDeleteDialog(varId) {
      this.deleteDialog = true;
      if (varId != "") {
        this.envVariableIdForDeletion = varId;
      }
    },

    // If the delete button is clicked in the Delete Dialog then we do the thing
    DeleteVariableClicked: function(ans) {
      if (ans === "yes"){
        if (this.envVariableIdForDeletion != "") {
          this.DeleteEnvVar(this.envVariableIdForDeletion);
        }
      } else if (ans === "no"){
        this.envVariableIdForDeletion = "";
      }
      this.deleteDialog = false;
    },

    // Button event for showing modal
    DoVariableActionClicked: function(ans) {

      // Manually fire form valdiation on submit to make sure our inputs are passing rules
      this.ValidateVarsInputs(); 

      // If they clicked 'Create' AND Inputs are passing given Rules
      if (ans === "yes" && this.formIsValid){
        if (this.actionType == "add"){
          this.AddEnvironmentVar(this.labelText, this.connText, this.proxyText);
        } else if (this.actionType == "edit"){
          this.UpdateEnvironmentVar(this.labelText, this.connText, this.proxyText, this.envVariableId);
        }
      }else if (ans === "yes" && !this.formIsValid){ 
        // Give users a chance to fix their errors
      } else{ // Blanket catch, also handles 'Cancel' clicks
        this.showEnvModal = false
        this.ClearAddEnvTextValues();
      }
    },

    // Clear inputs if we close the modal
    ClearAddEnvTextValues(){
      this.labelText = "";
      this.connText = "";
      this.proxyText = "";
      this.actionType = "";
      this.envVariableId = "";
      this.modalTitleText = "Something Broke!";

      // Reset connection attempt variables
      this.connAttempted = false;
      this.connSuccess = false;
      this.envTestName = "";
      if (this.$refs.varsInputForm){
        this.$refs.varsInputForm.resetValidation();
      }
    },

    // Drives the Add-Dialog for add a new Environment
    AddEnvVariable() {
      this.ClearAddEnvTextValues();
      this.actionType = "add";
      this.modalTitleText = "Adding New Environment Variable";
      this.showEnvModal = true;
    },

    // Drives the Edit-Dialog for add a new Environment
    EditEnvVariable(varIdToShow){
      this.actionType = "edit";
      this.modalTitleText = "Editing Environment Variable";
      this.showEnvModal = true;

      // Find EnvVar item in array and reference it
      var envVarToUpdate = this.envVariables.find(envVar => envVar.id === varIdToShow);

      // Set fields based on clicked element
      this.labelText = envVarToUpdate["label"]
      this.connText = envVarToUpdate["connectionString"]
      this.proxyText = envVarToUpdate["proxyConnectionString"]
      this.envVariableId = envVarToUpdate.id;
    },

    // Grab latest Environments that were previously saved from DevCon-Core
    RefreshEnvironmentVars: function() {
      // This changes context inside of a promise
      let that = this;

      // Try to save our new Environment Variable
      this.$store.dispatch('getCoreEnvironments')
      .then(function(response) {
        // Assign incoming variables to vars obj so we can interact with them
        that.envVariables = response["environmentvarsList"];
        that.hasInitialized = true;
      })
      .catch(function(response) {
        console.log('///////----////////');
        console.log(`Error: ${response.message}`); // do not update list of variables being displayed due to err
        console.log('///////----////////');
      });
    },

    // HTTP Call to Dev-Core to save a new environment variable
    AddEnvironmentVar: function(envLabel, envConnString, envProxyString) {
      // This changes context inside of a promise
      let that = this;
      
      // Creating our request for inserting a new Environment Variable
      let newEnvironmentVar = new InsertEnvironmentRequest();
      newEnvironmentVar.setLabel(envLabel);
      newEnvironmentVar.setConnectionString(envConnString);
      newEnvironmentVar.setProxyConnectionString(envProxyString)

      // Try to save our new Environment Variable
      this.$store.dispatch('addEnvironmentVariable', newEnvironmentVar)
      .then(function(response) {
        that.showEnvModal = false // Close modal once we get a response back
        that.ClearAddEnvTextValues(); // Clear previous input values

        // Refresh Environment Variables state for FrontEnd
        if (response["success"] == true) {
          that.RefreshEnvironmentVars();
        }
      })
      .catch(function(response) {
        console.log('///////----////////');
        console.log(response);
        console.log(`Error: ${response.message}`); // do not update list of variables being displayed due to err
        console.log('///////----////////');
      });
    },

    // HTTP Call to Dev-Core to save a new environment variable
    UpdateEnvironmentVar: function(envLabel, envConnString, envProxyString, envVarId) {

      let varToUpdate = new UpdateEnvironmentRequest();
      varToUpdate.setLabel(envLabel);
      varToUpdate.setConnectionString(envConnString);
      varToUpdate.setProxyConnectionString(envProxyString);
      varToUpdate.setId(envVarId);

      // This changes context inside of a promise
      let that = this;

      // Try to save our new Environment Variable
      this.$store.dispatch('updateEnvironmentVariable', varToUpdate)
      .then(function(response) {
        that.showEnvModal = false // Close modal once we get a response back
        that.ClearAddEnvTextValues(); // Clear previous input values

        // Refresh Environment Variables state for FrontEnd
        if (response["success"] == true) {
          that.RefreshEnvironmentVars();
        }
      })
      .catch(function(response) {
        console.log('///////----////////');
        console.log(`Error: ${response.message}`); // do not update list of variables being displayed due to err
        console.log('///////----////////');
      });
    },

    // Uses the ID of an environment that we want to remove from DevCon-Core
    DeleteEnvVar: function(envVarId) {
      let deleteRequest = new DeleteEnvironmentRequest();
      deleteRequest.setId(envVarId);

      // This changes context inside of a promise
      let that = this;

      this.$store.dispatch('deleteEnvironment', deleteRequest)
      .then(function(response) {
        // Refresh Environment Variables state for FrontEnd
        if (response["success"] == true) {
          that.RefreshEnvironmentVars();
        }
      })
      .catch(function(response) {
        console.log('///////----////////');
        console.log(`Error: ${response.message}`); // do not update list of variables being displayed due to err
        console.log('///////----////////');
      });
    },
    // ------------------------------------------------------------------------
    // NOTE: Once Relay/Proxy is done we can re-implement this helper method!
    // ------------------------------------------------------------------------
    // TestConnection () {
    //   console.log("Testing ConnectionString: " + this.connText);
    //   let that = this;

    //   // Do Adhoc call to test connection string
    //   // Figure out how to do an adhoc call to another environment
    //   let mesa = require("../../../store/mesa_client.js").default;
    //   let adhocRelayClient = new RelayClient(mesa.do, this.connText);

    //   adhocRelayClient.getServices().then(function(response){
    //     let responseVal = response.toObject();
    //     that.connAttempted = true;
    //     that.connSuccess = true;
    //     that.envTestName = responseVal.environment;
    //     console.log(responseVal);
    //   })
    //   .catch(function(response) {
    //     that.connAttempted = true;
    //     that.connSuccess = false;
    //     console.log('///////----////////');
    //     console.log(`Error: ${response.message}`); // do not update list of variables being displayed due to err
    //     console.log('///////----////////');
    //   });
    // }
  }
}


