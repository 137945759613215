import filters from "../../../filters/filters"

export default {
  name: 'conversions-in-maintenance-alert',
  mounted() {
    this.$store.dispatch('getMaintenanceModeStatus');
  },
  methods: {
    secondsToDateTime : filters.secondsToDateTime
  },
  computed: {
    getThemedStyles() {
      return el => {
        if (this.$store.getters.getTheme) return ''
        if (el === 'parent') return 'grey darken-1'
        if (el === 'text-box') return 'white--text'
      }
    },
    inMaintenance() {
      return this.$store.getters.getConversionsInMaintenance;
    },
    inMaintenanceAt() {
      return this.$store.getters.getConversionsInMaintenanceAt;
    },
  },
}


