import ClientCreate from "../ClientCreate/index.vue"
// import { Denomination } from "@acst/mono-corp-sites";
import {
  bus
} from '../../../main'

export default {
  name: 'client-search',
  components: {
    ClientCreate
  },

  data () {
    return {
      permission: true,
      filterDialog: false,
      errorDialog: false,
      errorMessage: "",
      statuses: [
        {
          name: "Active",
          value: 1,
        },
        {
          name: "Deactivated",
          value: 2,
        },
      ],
      statusState: [],
      products: [],
      productState: [],
      denominationGroupState: 0,
      denominationState: [],
      denominations: {},
      denominationGroups: {},
      searchStringState: '',
      locationState: [],
      locations: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
      maxAttend: 0,
      minAttend: 0,
      maxAttendancePossible: 0,
      dateCreatedOnStart: new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10),
      dateCreatedOnEnd: new Date().toISOString().substr(0,10),
      menuCreatedOnStart: false,
      menuCreatedOnEnd: false,
      dateDeactivatedOnStart: new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10),
      dateDeactivatedOnEnd: new Date().toISOString().substr(0,10),
      menuDeactivatedOnStart: false,
      menuDeactivatedOnEnd: false,
      showDatePicker: true,
    }
  },
  
  created() {
    bus.$on('clearSearch', () => {
      this.searchStringState = "";
    });

    bus.$on('resetCreatedStartDate', () => {
      this.dateCreatedOnStart = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
    });

    bus.$on('resetCreatedEndDate', () => {
      this.dateCreatedOnEnd = new Date().toISOString().substr(0,10);
    });

    bus.$on('resetDeactivatedStartDate', () => {
      this.dateDeactivatedOnStart = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
    });

    bus.$on('resetDeactivatedEndDate', () => {
      this.dateDeactivatedOnEnd = new Date().toISOString().substr(0,10);
    });

    bus.$on('resetMinAttend', () => {
      this.minAttend = 0;
    });

    bus.$on('resetMinAttend', () => {
      this.maxAttend = 0;
    });
  },

  computed: {
    selectedDenominationGroup : function () {
      if (this.denominations != undefined) {
        var state = this;
        var denomsDisplay = Object.values(this.denominations).filter(function (denomination) {
          return denomination.enum >= state.denominationGroupState && denomination.enum < (state.denominationGroupState + 1000) && denomination.enum != 0;
        });
        return denomsDisplay;
      }
      return [];
    }
  },

  mounted() {
    this.$store.dispatch('getDenominationsList').then(() => {
      this.denominations = this.$store.getters.denominations;
      this.denominationGroups = this.$store.getters.denominationGroups;
    });
  },

  methods: {
    searchClients() {
      // add a buffer to catch today's conversions.
      var createdTodayDateBuffer = new Date(this.dateCreatedOnEnd);
      var newCreatedEndDate = new Date(createdTodayDateBuffer.getTime()).toISOString().substr(0, 10);

      var deactivatedTodayDateBuffer = new Date(this.dateDeactivatedOnEnd);
      var newDeactivatedEndDate = new Date(deactivatedTodayDateBuffer.getTime()).toISOString().substr(0, 10);

      // set the new filter object
      if (this.validateSearch()) {
        var searchFilters;
        searchFilters = {
          statuses: this.statusState,
          products: this.productState,
          denominations: this.denominationState,
          searchString: this.searchStringState,
          locations: this.locationState,
          attendance: {
            min: this.minAttend,
            max: this.maxAttend
          },
          dateCreated: {
            startDate: this.dateCreatedOnStart,
            stopDate: newCreatedEndDate
          },
          dateDeactivated: {
            startDate: this.dateDeactivatedOnStart,
            stopDate: newDeactivatedEndDate
          }
        }
        this.filterDialog = false;

        // set the filters in the store
        this.$store.dispatch('settingClientSearchFilters', searchFilters);

        // tell the list to refresh with these new filters and close the filter dialog
        bus.$emit('listClients'); // eventBus for refreshing table with get all data
      }
    },

    // Check the search filters, return true if valid
    validateSearch() {
      if (this.maxAttend != 0 && this.maxAttend < this.minAttend) {
        this.errorMessage = "Minimum attendance must be less than or equal to maximum attendance"
        this.errorDialog =  true;
        this.maxAttend = 0;
        this.minAttend = 0;
        return false
      }

      if (this.dateCreatedOnEnd < this.dateCreatedOnStart) {
        this.errorMessage = "Start date for 'Created On' must be before End Date"
        this.errorDialog =  true;
        this.dateCreatedOnStart = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
        this.dateCreatedOnEnd = new Date().toISOString().substr(0,10);
        return false
      }

      if (this.dateDeactivatedOnEnd < this.dateDeactivatedOnStart) {
        this.errorMessage = "Start date for 'Deactivated' must be before End Date"
        this.errorDialog =  true;
        this.dateDeactivatedOnStart = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
        this.dateDeactivatedOnEnd = new Date().toISOString().substr(0,10);
        return false
      }
      return true
    },

    openFilters()  {
      this.loadProducts()
      this.maxAttendancePossible = this.$store.getters.maxAttendance;
      this.filterDialog = true;
    },

    // Reset the filters and close the dialog
    closeFilter() {
      this.filterDialog = false;
      this.resetForm();
    },

    dismissError() {
      this.errorMessage = "";
      this.errorDialog = false;
    },

    // Pull a list of all the products to display for filtering by products
    loadProducts() {
      this.$store.dispatch('getProductList').then(() => {
        setTimeout(() => {
          this.products = this.$store.getters.allGlobalSitesProducts;
        }, 1000);
      });
    },

    // Clear out the data from the form
    resetForm() {
      this.statusState = [];
      this.denominationsState = 0;
      this.denominationGroupState = [];
      this.productState = [];
      this.locationState = [];
      this.searchStringState = "",
      this.maxAttend = 0;
      this.minAttend = 0;
      this.dateCreatedOnStart = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
      this.dateCreatedOnEnd = new Date().toISOString().substr(0,10);
      this.dateDeactivatedOnStart = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
      this.dateDeactivatedOnEnd = new Date().toISOString().substr(0,10);
    }
  },
}