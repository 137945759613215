//SiteList Imports
import SiteList from '../../views/SiteList/index.vue'
import ClientList from './ClientList/index.vue'
import ClientDetailView from './ClientDetailView/index.vue'

export default [
    {
        path: '/SiteList',
        component: SiteList,
        children: [
          {
            name: 'ClientList',
            path: '',
            component: ClientList
          },
          {
            name: 'ClientList.Detail',
            path: 'Detail/:id',
            component: ClientDetailView
          },
        ]
      },
];