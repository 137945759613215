import axios from "axios";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default {
  do: function(url, req, config) {
    var defaultConfig = {
      handleError: true
    };

    config = { ...defaultConfig, ...config };

    if (localStorage.getItem("deviceID") == null) {
      localStorage.setItem("deviceID", makeid(6));
    }

    var axiosConfig = {
      headers: {
        "Content-Type": "application/protobuf+base64",
        "X-DevCon-DeviceID": localStorage.getItem("deviceID")
      }
    };

    return executePost(url, req, config, axiosConfig);
  }
};

var executePost = function(url, req, config, axiosConfig) {
  var jwt = localStorage.getItem("jwt");

  if (jwt) {
    axiosConfig.headers.Authorization = "Grant " + jwt;
  }

  return axios
    .post(url, req, axiosConfig)
    .then(function(resp) {
      if (resp && resp.status && resp.status === 200) {
        //takes the config authorization header and stores it in localstorage
        if (typeof resp.headers.authorization !== "undefined") {
          var grant = resp.headers.authorization;
          var grantSlice = grant.split(" ");

          localStorage.setItem("jwt", grantSlice[1]);
        }

        return resp.data;
      }

      throw returnAsMesaError(resp);
    })
    .catch(function(error) {
      throw returnAsMesaError(error);
    });
};

var returnAsMesaError = function(error) {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.code &&
    error.response.data.code_text &&
    error.response.data.message
  ) {
    return error.response.data;
  }

  return {
    code: 2,
    code_text: "internal",
    message: error.message,
    meta: {
      error: error
    },
    stack: null
  };
};