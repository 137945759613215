import ClientChurchInfo from "../ClientChurchInfo/index.vue"
import { GetSiteRequest } from "@acst/mono-corp-sites";

export default {
  name: 'client-detail-view',
  components: {
    ClientChurchInfo
  },

  data () {
    return {
      permission: true,
      client: [],
      errorMessage: "",
      errorExists: true,
      loading_start: false,
      dropDownItems: [],
      view: 'Church Info',
    }
  },

  computed: {
    computedColor: function () {
      return this.$store.getters.getTheme ? 'white' : '#0073B0';
    }
  },

  mounted() {
    this.createDetailView(this.$route.params.id);
  },

  methods: {
    setView(item) {
      this.view = item.name;
    },

    createDetailView(id) {
      var getChurchDetails;
      getChurchDetails = new GetSiteRequest;
      getChurchDetails.setSiteId(id);

      this.$store.dispatch('getClientDetails', getChurchDetails).then(() => {
        this.setClientDetails(this.$store.getters.clientDetails);
      });
    },

    setClientDetails(e) {
      this.client = {
        siteId: e.siteId,
        globalName: e.siteName,
        globalSiteNumber: e.siteNumber,
        products: e.siteProductsList,
      };

      this.loadDropdownItems(e);
    },

    loadDropdownItems(e) {
      this.dropDownItems = [];

      let currentID = 1;

      this.dropDownItems.push(
        {
          id: currentID,
          name: 'Church Info'
        }
      );
      currentID++;

      this.dropDownItems.push(
        {
          id: currentID,
          name: 'Technical Info'
        }
      );
      currentID++;

      var products;
      products = [];
      saveCurrentID = currentID

      if (e.siteProductsList != undefined) {
        var saveCurrentID;
        currentID++;

        var i;
        var product;
        for (i=0; i < e.siteProductsList.length; i++) {
          product = {
            id: currentID,
            name: e.siteProductsList[i].displayName
          };
          products.push(product);
          currentID++;
        }
      }

      this.dropDownItems.push(
        {
          id: saveCurrentID,
          name: 'Products',
          children: products
        }
      );
      currentID++;
      
      this.dropDownItems.push(
        {
          id: currentID,
          name: 'People'
        }
      );
      currentID++;

      this.dropDownItems.push(
        {
          id: currentID,
          name: 'Conversion History'
        }
      );
      currentID++;

      this.dropDownItems.push(
        {
          id: currentID,
          name: 'Support'
        }
      );
      currentID++;

      this.dropDownItems.push(
        {
          id: currentID,
          name: 'Experiments'
        }
      );
    }
  },
}