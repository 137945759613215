import TokensView from '../../components/Tokens/TokensView.vue';
import Tokens from '../../views/Tokens.vue';

export default [
    {
        path: '/Tokens',
        component: Tokens,
        children: [
            {
                name: 'TokensView',
                path: '',
                component: TokensView,
            },
        ]
    },
];