// NOTE: This is a dynamic NavigationBar component, do not change anything here!
// This component takes in a "navObject" with items defined to be dynamically built out.
// Material-Icons reference: https://materialdesignicons.com/
export default {
  name: 'expandable-nav-bar',
  components: {},
  props: [
    'navItemList'
  ],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


