import { ListSitesRequest } from "@acst/mono-corp-sites";
import { AttendanceFilter } from "@acst/mono-corp-sites";
import { DateFilter } from "@acst/mono-corp-sites";
// import { Denomination } from "@acst/mono-corp-sites";
import ClientSearch from "../ClientSearch/index.vue"
import {
  bus
} from '../../../main'

export default {
  name: 'client-list',
  components: {
    ClientSearch,
  },

  data () {
    return {
      permission: true,
      footerProps: {
        'items-per-page-options': [10],
        'items-per-page-text': ''
      },
      search: '',
      loading: false,
      errorMessage: '',
      hover: false,
      headers: [
        {
          text: 'Global Site #',
          value: 'globalSiteNumber',
          align: "left", 
          sortable: true
        },
        {
          text: 'Global Name / Global URL',
          value: 'globalName', 
          sortable: true
        },
        {
          text: 'Location',
          value: 'location', 
          sortable: true
        },
        {
          text: 'Denomination',
          value: 'denomination', 
          sortable: true
        },
        {
          text: 'Status',
          value: 'status', 
          sortable: true
        },
        {
          text: 'TWA',
          value: 'twa', 
          sortable: true
        },
        {
          text: 'Products',
          value: 'products', 
          sortable: true
        },
        {
          text: 'Date Created',
          value: 'dateCreated', 
          sortable: true
        },
        {
          text: 'Date Deactivated',
          value: 'dateDeactivated', 
          sortable: true
        },
      ],
      dataRowItems: [], // created objects with correct data,
      searchFilters: [],
      showFilters: false,
      statuses: {},
      denominations: {},
      denominationGroups: {},
    }
  },

  computed: {
    computedColor: function() {
      return this.$store.getters.getTheme ? 'black' : 'white';
    }
  },

  created() {
    bus.$on('listClients', () => {
      this.listClients();
    });
  },

  mounted() {
    this.listClients();
    this.$store.dispatch('getDenominationsList').then(() => {
      this.denominations = this.$store.getters.denominations;
      this.denominationGroups = this.$store.getters.denominationGroups;
    });
    this.$store.dispatch('getStatusList').then(() => {
      this.statuses = this.$store.getters.globalSiteStatuses;
    });
  },

  methods: {
    listClients() {
      // reset the list
      this.dataRowItems = [];

      // create a new site list request variable, and load the search filters
      var newSiteListRequest = new ListSitesRequest();
      newSiteListRequest.setStatusList(this.$store.getters.clientSearchFilters.statuses);
      newSiteListRequest.setSearchString(this.$store.getters.clientSearchFilters.searchString);
      newSiteListRequest.setProductsList(this.$store.getters.clientSearchFilters.products);
      newSiteListRequest.setDenominationsList(this.$store.getters.clientSearchFilters.denominations);
      newSiteListRequest.setStatesList(this.$store.getters.clientSearchFilters.locations)
      if (this.$store.getters.clientSearchFilters.attendance != undefined) {
        var attendance = new AttendanceFilter();
        attendance.setMin(this.$store.getters.clientSearchFilters.attendance.min);
        attendance.setMax(this.$store.getters.clientSearchFilters.attendance.max);
        newSiteListRequest.setAttendance(attendance);
      }

      if (this.$store.getters.clientSearchFilters.dateDeactivated != undefined) {
        var deactivated = new DateFilter();
        deactivated.setStartDate(this.$store.getters.clientSearchFilters.dateDeactivated.startDate);
        deactivated.setStopDate(this.$store.getters.clientSearchFilters.dateDeactivated.stopDate);

        var deactivatedStartSearchDateDefault = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
        var deactivatedEndSearchDateDefault = new Date().toISOString().substr(0,10);

        // checks if the default range has been set
        if (this.$store.getters.clientSearchFilters.dateDeactivated.startDate == deactivatedStartSearchDateDefault) {
          deactivated.setStartDate("");
          deactivatedStartSearchDateDefault = "";
        } else {
          deactivatedStartSearchDateDefault = this.$store.getters.clientSearchFilters.dateDeactivated.startDate;
        }

        if (this.$store.getters.clientSearchFilters.dateDeactivated.stopDate == deactivatedEndSearchDateDefault) {
          deactivated.setStopDate("");
        }

        newSiteListRequest.setDateDeactivated(deactivated)
      }

      if (this.$store.getters.clientSearchFilters.dateCreated != undefined) {
        var created = new DateFilter();
        created.setStartDate(this.$store.getters.clientSearchFilters.dateCreated.startDate);
        created.setStopDate(this.$store.getters.clientSearchFilters.dateCreated.stopDate);
        
        var createStartSearchDateDefault = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
        var createEndSearchDateDefault = new Date().toISOString().substr(0,10);
        
        // checks if the default range has been set
        if (this.$store.getters.clientSearchFilters.dateCreated.startDate == createStartSearchDateDefault) {
          created.setStartDate("");
          createStartSearchDateDefault = "";
        } else {
          createStartSearchDateDefault = this.$store.getters.clientSearchFilters.dateCreated.startDate;
        }

        if (this.$store.getters.clientSearchFilters.dateCreated.stopDate == createEndSearchDateDefault) {
          created.setStopDate("");
        }

        newSiteListRequest.setDateCreated(created)

      }
    
      this.$store.dispatch('listSites', newSiteListRequest).then(() => {
        setTimeout(() => {
          this.setClientsForList(this.$store.getters.allGlobalSitesClients);
        }, 300);
      });
    },

    setClientsForList(e) {
      this.dataRowItems = [];
      this.setFiltersForDisplay(this.$store.getters.clientSearchFilters)
      if (e == null) {
        return;
      }

      var i;
      var newDataRowItem;
      var maxAttendance = 0;
      for (i = 0; i < e.length; i++) {
        if (e[i].averageWeeklyAttendance > maxAttendance) {
          maxAttendance = e[i].averageWeeklyAttendance;
        }
        var p;
        var products = [];
        var numProducts = 0;
        for (p = 0; p < e[i].siteProductsList.length; p++) {
          var newProduct = {
            name: e[i].siteProductsList[p].displayName,
            productID: e[i].siteProductsList[p].productId,
          };
          products.push(newProduct);
        }
        if (products.length == 0) {
          var stubProduct = {
            name: "There are no products for this site"
          };
          products.push(stubProduct);
          numProducts = 0;
        } else {
          numProducts = products.length;
        }

        var addressHasSecondLine;
        if (e[i].address.line2 != undefined && e[i].address.line2 != "") {
          addressHasSecondLine = true;
        } 

        var endedDate;
        if (e[i].deactivatedAt == undefined || e[i].deactivatedAt == "") {
          endedDate = "--";
        }
        else {
          endedDate = this.trimDate(e[i].deactivatedAt.seconds);
        }
  
        var createdDate;
        if (e[i].createdAt == undefined || e[i].createdAt == "") {
          createdDate = "--";
        }
        else {
          createdDate = this.trimDate(e[i].createdAt.seconds);
        }

        newDataRowItem = {
          id: i,
          globalSiteNumber: e[i].siteNumber,
          globalName: e[i].siteName,
          globalURL: e[i].siteSlug,
          location: e[i].location,
          address: e[i].address.line1,
          addressHasSecondLine: addressHasSecondLine,
          address2: e[i].address.line2,
          city: e[i].address.city,
          region: e[i].address.region,
          zipcode: e[i].address.postalCode,
          country: e[i].address.country,
          denomination: this.denominations[e[i].denomination].name,
          status: this.statuses[e[i].status].name,
          twa: e[i].averageWeeklyAttendance,
          products: numProducts,
          productList: products,
          dateCreated: createdDate.date,
          dateCreatedTime: createdDate.time,
          dateDeactivated: endedDate.date,
          dateDeactivatedTime: endedDate.time,
          globalSiteID: e[i].siteId,
        }
        this.dataRowItems.push(newDataRowItem);
      }

      this.$store.dispatch('settingMaxAttendance', maxAttendance);
    },

    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
    },

    // setFiltersForDisplay recieves an object of all the searchFilters and labels them
    setFiltersForDisplay(e) {
      // Reset searchFilters
      this.searchFilters.splice(0, this.searchFilters.length);

      // //  Push active searchFilters to display
      if (e.statuses != undefined && e.statuses != [] && e.statuses != "") {
        var s;
        for (s = 0; s < e.statuses.length; s++){
          this.searchFilters.push(this.makeNewFilterObject("Status", e.statuses[s], this.statuses[e.statuses[s]].name));
        }
      }

      if (e.denominations != undefined && e.denominations != [] && e.denominations != "") {
        var d;
        for (d = 0; d < e.denominations.length; d++){
          this.searchFilters.push(this.makeNewFilterObject("Denomination", e.denominations[d], this.denominations[e.denominations[d]].name));
        }
      }

      if (e.products != undefined && e.products != [] && e.products != "") {
        var p;
        var storeProducts = this.$store.getters.allGlobalSitesProducts;
        for (p = 0; p < e.products.length; p++){
          this.searchFilters.push(this.makeNewFilterObject("Product", e.products[p], storeProducts[e.products[p]].name));
        }
      }

      if (e.locations != undefined && e.locations != [] && e.locations != "") {
        var l;
        for (l = 0; l < e.locations.length; l++){
          this.searchFilters.push(this.makeNewFilterObject("Location", e.locations[l], e.locations[l]));
        }
      }

      if (e.searchString != undefined && e.searchString != ""){
        this.searchFilters.push(this.makeNewFilterObject("Search", e.searchString, e.searchString));
      }

      if (e.attendance != undefined) {
        if (e.attendance != "" && e.attendance.min != undefined && e.attendance.min != 0){
          this.searchFilters.push(this.makeNewFilterObject("Minimum Attendance",  e.attendance.min,  e.attendance.min));
        }

        if (e.attendance != "" && e.attendance.max != undefined && e.attendance.max != 0){
          this.searchFilters.push(this.makeNewFilterObject("Maximum Attendance",  e.attendance.max,  e.attendance.max));
        }
      }

      // default dates for checking change in date query
      var startSearchDateDefault = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
      var endSearchDateDefault = new Date().toISOString().substr(0,10);
      if (e.dateCreated != undefined && e.dateCreated != "") {
        if (e.dateCreated.startDate != undefined && e.dateCreated.startDate != "" && e.dateCreated.startDate != startSearchDateDefault) {
          this.searchFilters.push(this.makeNewFilterObject("Created After",  e.dateCreated.startDate, e.dateCreated.startDate));
        }

        if (e.dateCreated.stopDate != undefined && e.dateCreated.stopDate != "" && e.dateCreated.stopDate != endSearchDateDefault) {
          this.searchFilters.push(this.makeNewFilterObject("Created Before",  e.dateCreated.stopDate, e.dateCreated.stopDate));
        }
      }

      if (e.dateDeactivated != undefined && e.dateDeactivated != "") {
        if (e.dateDeactivated.startDate != undefined && e.dateDeactivated.startDate != "" && e.dateDeactivated.startDate != startSearchDateDefault) {
          this.searchFilters.push(this.makeNewFilterObject("Deactivated After",  e.dateDeactivated.startDate, e.dateDeactivated.startDate));
        }
        if (e.dateDeactivated.stopDate != undefined && e.dateDeactivated.stopDate != "" && e.dateDeactivated.stopDate != endSearchDateDefault) {
          this.searchFilters.push(this.makeNewFilterObject("Deactivated Before",  e.dateDeactivated.stopDate, e.dateDeactivated.stopDate));
        }
      }

      // Only display the filters if any are active
      if (this.searchFilters.length > 0) {
        this.showFilters = true;
      }
      else {
        this.showFilters = false;
      }
    },

    // removeFilter removes a search filter from the searchFilters array and the vuex filters when a filter is clicked
    removeFilter(filter) {
      if (filter.filterType == "Search") {
        bus.$emit('clearSearch');
      } 
      else if (filter.filterType == "Minimum Attendance") {
        bus.$emit('resetMinAttend');
      }
      else if (filter.filterType == "Maximum Attendance") {
        bus.$emit('resetMinAttend');
      }
      else if (filter.filterType == "Created After") {
        bus.$emit('resetCreatedStartDate');
      }
      else if (filter.filterType == "Created Before") {
        bus.$emit('resetCreatedEndDate');
      }
      else if (filter.filterType == "Deactivated After") {
        bus.$emit('resetDeactivatedStartDate');
      }
      else if (filter.filterType == "Deactivated Before") {
        bus.$emit('resetDeactivatedEndDate');
      }
      
      // update vuex with the updated list
      this.$store.dispatch('removeSearchFilter', filter).then(() => {
        this.listClients();
      });
    },

    // resets the searchFilters
    resetFilters() {
      this.$store.dispatch('settingClientSearchFilters', []); // Reset displayed searchFilters
      this.searchFilters = [];
    },

    trimDate(e) {
      if (e == null) {
        console.log("Invalid/Null Date")
        return;
      }

      var date = new Date(1970, 0, 1); // Epoch
      date.setSeconds(e);
      var newDate = new Date(date); // pass in new date.
      const dateTimeFormat = new Intl.DateTimeFormat('en-US', {month: '2-digit', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'America/New_York'});
      const [{ value: month },,{ value: day },,{ value: year },,{value: hour},,{value: minute},,{value:seconds},,{value: timeZone}] = dateTimeFormat .formatToParts(newDate);
      var returnedDate = {
        date: `${month}/${day}/${year}`,
        time: `${hour}:${minute}:${seconds} ${timeZone}`
      }
      return returnedDate;
    },

    makeNewFilterObject(type, value, name) {
      return {
        filterType: type,
        filterValue: value,
        filterDisplayName: name
      }
    },
  },
}