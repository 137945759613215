var mesa = require("../../store/mesa_client.js").default;
import { SitesClient } from "@acst/mono-corp-sites";
import { ListSitesRequest } from "@acst/mono-corp-sites";
import { AttendanceFilter } from "@acst/mono-corp-sites";
import { DateFilter } from "@acst/mono-corp-sites";
import Vue from "vue";

const getDefaultState = () => {
  return {
    clients: [],
    client_details: {},
    client_search_filters: [],
    client_search_string: "",
    products: {},
    product_details: {},
    client_list_maximum_attendance: 0,
    site_id: "",
    denominations: {},
    denomination_groups: {},
    global_site_statuses: {},
    sitesClient: new SitesClient(mesa.do, "")
  }
}

const state = getDefaultState()
  
export default {
  state,

  getters: {
    // gets the clients to list
    allGlobalSitesClients: state => state.clients,

    clientDetails: state => state.clientDetails,

    // get the filters (status, denom, products) to filter the clients by
    clientSearchFilters: state => state.client_search_filters,

    allGlobalSitesProducts: state => state.products,

    // specific product details
    productDetails: state => state.product_details,

    maxAttendance: state => state.client_list_maximum_attendance,

    // gets the site id for a newly added site
    currentSiteID: state => state.site_id,

    denominations: state => state.denominations,

    denominationGroups: state => state.denomination_groups,

    globalSiteStatuses: state => state.global_site_statuses,
  },

  mutations: {
    resetClients(state) {
      state.clients = [];
    },

    setallGlobalSitesClients(state, clients) {
      Vue.set(state, "clients",  clients);
    },

    setClientDetails(state, client_details) {
      Vue.set(state, "client_details", client_details);
    },

    setClientSearchFilters(state, filters) {
      Vue.set(state, "client_search_filters", filters)
    },

    setProducts(state, products) {
      products.forEach((product, i) => {
        Vue.set(state.products, product.productId,  {
          enum: i,
          productId: product.productId,
          name: product.displayName,
          crmProductId: product.crmProductId,
          crmProductName: product.crmProductName,
          status: product.status,
          subscriptionSubtitle: product.subscriptionSubtitle,
          description: product.description,
          icon: product.icon,
          realeaseNotes: product.releaseNotesLink,
          quickLink: product.quickLink,
          quickLinkTitle: product.quickLinkTitle
        })
      });
    },

    setProductDetails(state, product_details) {
      Vue.set(state, "product_details", product_details);
    },

    setMaxAttendance(state, maxAttend) {
      state.client_list_maximum_attendance = maxAttend;
    }, 
    
    setCurrentSiteID(state, site_id) {
      state.site_id = site_id;
    },
    
    setDenominations(state, denoms) {
      denoms.forEach((denom, i) => {
        Vue.set(state.denominations, denom[0], {
          id: i,
          enum: denom[0],
          name: denom[1]
        })
      });
    },
    
    setDenominationGroups(state, denomGroups) {
      denomGroups.forEach((denomGroup, i) => {
        Vue.set(state.denomination_groups, denomGroup[0], {
          id: i,
          enum: denomGroup[0],
          name: denomGroup[1]
        })
      });
    },

    setGlobalSiteStatuses(state, statuses) {
      statuses.forEach((status, i) => {
        Vue.set(state.global_site_statuses, status[0], {
          id: i,
          enum: status[0],
          name: status[1]
        })
      });
    },
  },
    
  actions: {
    // addSite creates a new site
    async addSite({ commit }, AddSiteRequest) {
      return await state.sitesClient.addSite(AddSiteRequest).then(function(response) {
          commit('setErrorMessage', '');
          commit('setCurrentSiteID', response.toObject().siteId)
          return response.toObject();
      }).catch(function(error) {
          console.log(error);
          commit('setErrorMessage', 'An Error Occured Creating the Site');
      });
    },
    
    // listSites retrieves a filtered list of all the client list
    async listSites({ commit }, ListSitesRequest) {
      return await state.sitesClient.listSites(ListSitesRequest).then(function(response) {
          commit('setallGlobalSitesClients', response.toObject().sitesList);
          commit('setErrorMessage', '');
          return response.toObject();
      }).catch(function(error) {
          console.log(error);
          commit('setErrorMessage', 'An Error Occured Getting Clients');
      });
    },

    // getClientDetails from endpoint
    async getClientDetails({ commit }, GetSiteRequest) {
      return await state.sitesClient.getSite(GetSiteRequest).then(function(response) {
          commit('setClientDetails', response.toObject().site);
          commit('setErrorMessage', '');
          return response.toObject();
      }).catch(function(error) {
          console.log(error);
          commit('setErrorMessage', 'An Error Occured Getting Client Details');
      });
    },

    // getProductList retrieves a list of all the products
    async getProductList({ commit }) {
      return await state.sitesClient.listProducts().then(function(response) {
          commit('setProducts', response.toObject().productsList);
          commit('setErrorMessage', '');
          return response.toObject();
      }).catch(function(error) {
          console.log(error);
          commit('setErrorMessage', 'An Error Occured Getting the Products List');
      });
    },

    // getProductDetails from endpoint
    async getProductDetails({ commit }, ProductRequest) {
      return await state.sitesClient.getProduct(ProductRequest).then(function(response) {
          commit('setProductDetails', response.toObject().product);
          commit('setErrorMessage', '');
          return response.toObject();
      }).catch(function(error) {
          console.log(error);
          commit('setErrorMessage', 'An Error Occured Getting Product Details');
      });
    },

    // getDenominationsList retrieves a list of all the denominations and denomination groups
    async getDenominationsList({ commit }) {
      return await state.sitesClient.listDenominations().then(function(response) {
          commit('setDenominations', response.toObject().denominationsMap);
          commit('setDenominationGroups', response.toObject().denominationGroupsMap);
          commit('setErrorMessage', '');
          return response.toObject();
      }).catch(function(error) {
          console.log(error);
          commit('setErrorMessage', 'An Error Occured Getting the Denominations List ' + error);
      });
    },

    // getStatusList retrieves a list of all the denominations and denomination groups
    async getStatusList({ commit }) {
      return await state.sitesClient.listSiteStatuses().then(function(response) {
          commit('setGlobalSiteStatuses', response.toObject().siteStatusesMap);
          commit('setErrorMessage', '');
          return response.toObject();
      }).catch(function(error) {
          console.log(error);
          commit('setErrorMessage', 'An Error Occured Getting the Status List ' + error);
      });
    },

    // settingClientSearchFilters will set the search filters to be displayed
    settingClientSearchFilters({commit}, e) {
      commit('setClientSearchFilters', e)
    },

    // settingMaximumAttendance will set the maximum attendance option to be shown in the search filter
    settingMaxAttendance({commit}, e) {
      commit('setMaxAttendance', e)
    },

    // removeSearchFilter takes a search filter as a string and removes it from vuex
    removeSearchFilter({commit, dispatch, getters}, filter) {
      var allFilters = getters.clientSearchFilters;
      if (filter.filterType == "Denomination") {
        var d
        for (d = 0; d < allFilters.denominations.length; d++) {
          if (allFilters.denominations[d] == filter.filterValue) {
            allFilters.denominations.splice(d, 1);
          }
        }
      }

      else if (filter.filterType == "Search") {
        allFilters.searchString = "";
      }

      else if (filter.filterType == "Status") {
        var s
        for (s = 0; s < allFilters.statuses.length; s++) {
          if (allFilters.statuses[s] == filter.filterValue) {
            allFilters.statuses.splice(s, 1);
          }
        }
      }

      else if (filter.filterType == "Product") {
        var p
        for (p = 0; p < allFilters.products.length; p++) {
          if (allFilters.products[p] == filter.filterValue) {
            allFilters.products.splice(p, 1);
          }
        }
      }

      else if (filter.filterType == "Location") {
        var l
        for (l = 0; l < allFilters.locations.length; l++) {
          if (allFilters.locations[l] == filter.filterValue) {
            allFilters.locations.splice(l, 1);
          }
        }
      }

      else if (filter.filterType == "Minimum Attendance") {
        allFilters.attendance.min = 0;
      }

      else if (filter.filterType == "Maximum Attendance") {
        allFilters.attendance.max = 0;
      }

      else if (filter.filterType == "Created Before") { 
        allFilters.dateCreated.stopDate = new Date().toISOString().substr(0,10);
      }

      else if (filter.filterType == "Created After") {
        allFilters.dateCreated.startDate = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
      }

      else if (filter.filterType == "Deactivated Before") { 
        allFilters.dateDeactivated.stopDate = new Date().toISOString().substr(0,10);
      }

      else if (filter.filterType == "Deactivated After") {
        allFilters.dateDeactivated.startDate = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
      }

      let clientListFilterVar = new ListSitesRequest();
      clientListFilterVar.setSearchString(allFilters.searchString);
      clientListFilterVar.setDenominationsList(allFilters.denominations);
      clientListFilterVar.setStatusList(allFilters.statuses);
      clientListFilterVar.setProductsList(allFilters.products);
      clientListFilterVar.setStatesList(allFilters.locations);

      var attendance = new AttendanceFilter();
      if (allFilters.attendance != undefined) {
        attendance.setMin(allFilters.attendance.min);
        attendance.setMax(allFilters.attendance.max);
      }
      clientListFilterVar.setAttendance(attendance);

      var deactivated = new DateFilter();
      if (allFilters.dateDeactivated != undefined) {
        deactivated.setStartDate(allFilters.dateDeactivated.startDate);
        deactivated.setStopDate(allFilters.dateDeactivated.stopDate);

        var deactivatedStartSearchDateDefault = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
        var deactivatedEndSearchDateDefault = new Date().toISOString().substr(0,10);

        // checks if the default range has been set
        if (allFilters.dateDeactivated.startDate == deactivatedStartSearchDateDefault) {
          deactivated.setStartDate("");
          deactivatedStartSearchDateDefault = "";
        } else {
          deactivatedStartSearchDateDefault = allFilters.dateDeactivated.startDate;
        }

        if (allFilters.dateDeactivated.stopDate == deactivatedEndSearchDateDefault) {
          deactivated.setStopDate("");
        }       
      }
      clientListFilterVar.setDateDeactivated(deactivated);

      var created = new DateFilter();
      if (allFilters.dateCreated != undefined) {
        created.setStartDate(allFilters.dateCreated.startDate);
        created.setStopDate(allFilters.dateCreated.stopDate);
        
        var createStartSearchDateDefault = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
        var createEndSearchDateDefault = new Date().toISOString().substr(0,10);
        
        // checks if the default range has been set
        if (allFilters.dateCreated.startDate == createStartSearchDateDefault) {
          created.setStartDate("");
          createStartSearchDateDefault = "";
        } else {
          createStartSearchDateDefault = allFilters.dateCreated.startDate;
        }

        if (allFilters.dateCreated.stopDate == createEndSearchDateDefault) {
          created.setStopDate("");
        }
      }
      clientListFilterVar.setDateCreated(created);

      commit('setClientSearchFilters', allFilters);
      var sitesResponse = dispatch('listSites', clientListFilterVar);
      return sitesResponse;
    },

    // addSiteProduct adds a product to a certain client
    async addSiteProduct({ commit }, AddSiteProductRequest) {
      return await state.sitesClient.addSiteProduct(AddSiteProductRequest).then(function(response) {
        commit('setErrorMessage', '');
        return response.toObject();
      }).catch(function(error) {
          console.log(error);
          commit('setErrorMessage', 'An Error Occured Adding Product to Site');
      });
    },

    // deleteSiteProduct removes a product from a certain client
    async deleteSiteProduct({ commit }, DeleteSiteProductRequest) {
      return await state.sitesClient.deleteSiteProduct(DeleteSiteProductRequest).then(function(response) {
        commit('setErrorMessage', '');
        return response.toObject();
      }).catch(function(error) {
          console.log(error);
          commit('setErrorMessage', 'An Error Occured Removing Product from Site');
      });
    }
  },
}