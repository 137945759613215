import filters from "@/filters/filters";
import { ConversionRequest, JobStatus, JobType } from "@acst/mono-realm-conversions";
import ConversionsExpandErrorTableRow from "../ConversionsExpandErrorTableRow";

export default {
  name: 'conversions-job-log',
  components: {
    ConversionsExpandErrorTableRow,
  },
  props: [],
  data() {
    return {
      headers: function () {
        let headerList = [
          { text: 'Job Status', value: 'status', align: 'center', sortable: false, },
          { text: 'Job Type', value: 'jobType', align: 'center', sortable: false, },
          { text: 'End Time', value: 'updatedAt', align: 'center', sortable: false, sort: (a, b) => !a ? -1 : !b ? 1 : a.seconds - b.seconds, },
        ];

        const colWidth = 3;
        const width = 100 / ((headerList.length + 1) * colWidth) - 1
        headerList.forEach(h => {
          h.width = `${width * colWidth}%`
        });
        headerList.unshift({ text: '', value: '', align: 'center', width: `${width}%`, sortable: false, })
        return headerList;
      }(),
      JobStatus,
      JobStatusByValueMap: Object.fromEntries(Object.entries(JobStatus).map(([k, v]) => [v, k])),
      JobType,
      JobTypeByValueMap: Object.fromEntries(Object.entries(JobType).map(([k, v]) => [v, k])),
      expandedJobId: undefined,
      loading: false,
      interval: undefined,
    }
  },
  beforeDestroy() {
    this.stopPollingForJobs();
  },
  watch: {
    hasIncompleteJobs(newVal) {
      if (newVal) {
        this.pollForJobs();
      } else {
        this.stopPollingForJobs();
      }
    }
  },
  computed: {
    showJobErrors() {
      return job => job.jobId === this.expandedJobId
    },
    jobs() {
      return this.$store.getters.getConversionsJobLog ?? []
    },
    hasIncompleteJobs() {
      return this.jobs.reduce((hasIncomplete, job) => hasIncomplete || !(job.status === JobStatus.JOBSTATUSERRORED || job.status === JobStatus.JOBSTATUSCOMPLETED), false)
    },
  },
  mounted() {
    this.fetchJobsForConversion().then(() => {
      if (this.hasIncompleteJobs) {
        this.pollForJobs();
      }
    });
  },
  methods: {
    secondsToDateTime: filters.secondsToDateTime,
    loadJobsForConversion() {
      this.loading = true;
      this.fetchJobsForConversion().then(() => {
        this.loading = false;
      })
    },
    fetchJobsForConversion() {
      let req = new ConversionRequest();
      req.setConversionId(this.$route.params.id);
      return this.$store.dispatch('getConversionJobLog', req);
    },
    typeText(job) {
      if (job.type === JobType.JOBTYPENOTSPECIFIED) {
        return '--';
      }
      let typ = this.JobTypeByValueMap[job.type]
      typ = typ
        .replace('JOBTYPE', '')
        .toLowerCase()
        .replace('conversion', ' Conversion');
      typ = typ[0].toUpperCase() + typ.slice(1);
      return typ;
    },
    statusText(job) {
      if (job.status === JobStatus.JOBSTATUSNOTSET) {
        return '--'
      }
      let stat = this.JobStatusByValueMap[job.status];
      stat = stat
        .replace('JOBSTATUS', '')
        .toLowerCase();
      stat = stat[0].toUpperCase() + stat.slice(1);
      return stat;
    },
    toggleExpandedJob(job) {
      this.expandedJobId = this.expandedJobId === job.jobId ? undefined : job.jobId;
    },
    pollForJobs() {
      this.$store.dispatch('initPolling', this.fetchJobsForConversion).then(ivlID => {
        this.interval = ivlID;
      });
    },
    stopPollingForJobs() {
      this.$store.dispatch('clearPolling', this.interval);
    },
    retryImport() {
      this.$emit('retryImport');
    },
    retryStartConversion() {
      this.$emit('retryStartConversion');
    },
    retryHardReset() {
      this.$emit('retryHardReset');
    },
  }
}


