
export default {
  name: 'conversions-expand-error-table-row',
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    colspan: {
      type: Number,
      required: true,
    },
  },
}


