export default {
  name: 'login',
  components: {},
  props: {
    source: String,
  },
  data () {
    return {
      loader: null,
      loading: false,
      snackbar: false,
      text: ""
    };
  },
  created() {
    // Redirect based on the given URL from Google to DevCon-Core
    this.$store.dispatch('redirect')
      .then(function(response) {
        window.location.href = response.url;
      })
      .catch(function(response) {
        console.log(`Unable to Log In: ${response.message}`);
      });
  },
  computed: {

  },
  mounted () {

  },
  methods: {
  }
}


