import { PingServiceListRequest } from "@acst/mono-realm-conversions";
import filters from "../../../filters/filters"

export default {
  name: 'conversions-service-status',
  components: {},
  props: [],
  data () {
    return {
      pinging: false,
      serviceMap: new Map(),
      serviceStatusItems: [],
      serviceErrors: new Map(),
    }   
  },
  computed: {
    aggStatus() {
      if (this.pinging) {
        return 1
      } else if (this.serviceErrors.size == 0) {
        return 2
      } else {
        return 0
      }
    }
  },
  mounted () {
    this.getServiceStatuses(Object.keys(this.serviceMap))
  },
  methods: {
    capitalize: filters.capitalize,
    getServiceStatuses(services) {
      this.pinging = true;
      this.serviceStatusItems = Array.from(this.serviceMap, ([name, value]) => ({ name, value }));
      
      let newPingServiceListRequest = new PingServiceListRequest();
      newPingServiceListRequest.services = services;
      this.$store.dispatch('pingServices', newPingServiceListRequest).then(() => {
        let serviceStatuses = this.$store.getters.getConversionServiceStatuses;
        //set status for each service
         for (let i = 0; i < serviceStatuses.length; i++){
          this.serviceMap.set(serviceStatuses[i].name, serviceStatuses[i].status)
          
          //add/remove from error map
          if(serviceStatuses[i].status != 2){
            this.serviceErrors.set(serviceStatuses[i].name, serviceStatuses[i].errorsList);
          } else {
            this.serviceErrors.delete(serviceStatuses[i].name)
          }
        } 
        //update service status items for display
        this.serviceStatusItems = Array.from(this.serviceMap, ([name, value]) => ({ name, value }));
        this.pinging = false;

        //if there are any errors displapy error message(s)
        if(this.serviceErrors.size != 0){
          let errors = Array.from(this.serviceErrors.values());
          this.$store.dispatch('setErrorMessage', 'An Error Occured While Pinging Service Statuses: ' + errors.join(','))
        }
      });
    },
  }
}