import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { mapActions, mapMutations } from 'vuex';
import {
  bus
} from '../../../main';


export default {
  name: 'conversions-import',
  components: {
    vueDropzone,
  },
  props: {
    conversion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      disableImport: true,
      dropzoneErrored: false,
      file: null,
      fileCount: 0, //for drawer permanence
      dropzoneOptions: {
        url: 'http://example.org', //required param, but the component is only being used for validation and event listening
        method: 'put',
        dictDefaultMessage: 'Drag a .zip file here or click to select it...',
        maxFilesize: 256,
        addRemoveLinks: true,
        autoProcessQueue: false,
        acceptedFiles: ".zip",
        maxFiles: 1,
        previewTemplate: `
          <div class="dz-preview dz-file-preview">
            <div class="dz-image">
              <img data-dz-thumbnail />
            </div>
            <div class="dz-details">
              <div class="dz-filename"><span data-dz-name></span></div>
              <div class="dz-size" data-dz-size></div>
            </div>
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
          </div>
        `
      }
    }
  },
  mounted() {
    this.dz().setOption('accept', this.validateZipFile);
  },
  computed: {
    drawer() {
      return this.$store.getters.getConversionShowImportDrawer;
    },
  },
  methods: {
    ...mapMutations(["setConversionsPresignedURL"]),
    ...mapActions(["initializeXMLImportForConversion"]),
    initImport() {
      var self = this;
      self.initializeXMLImportForConversion(self.file);
      self.clearFileUpload();
      self.$emit('showJobLogTab');
      bus.$emit('refreshImportStatus');
    },
    validateZipFile(file, done) {
      //custom validation steps only
      //already handled by dropzone component: file size / type
      //must also handle maxFilesExceeded case, because manually setting
      //dropzone.accept overrides that validation
      let self = this,
        fileName = file.name.split('.')[0].toLowerCase().split(' ').join(''),
        siteName = self.conversion.siteName.toLowerCase(),
        siteFileName = siteName.split(' ').join('');

      if (!(fileName == self.conversion.siteNumber || fileName === siteFileName)) {
        done(`Filename must equal the site name or number (e.g. 123456.zip).`);
        return;
      }
      self.file = file;
      self.setDropzoneErrored(false);
      done()
    },
    setDropzoneErrored(isErrored) {
      this.dropzoneErrored = isErrored;
    },
    clearFileUpload() {
      this.setConversionsPresignedURL('');
      this.dz().removeAllFiles();
      this.setDropzoneErrored(false);
      this.$store.commit('setConversionShowImportDrawer', false);
      this.file = null;
    },
    handleDropzoneError(_, error, xhr) {
      if (xhr) {
        return //we are handling ajax errors via the snackbar
      }
      this.setDropzoneErrored(error != '')
    },
    dz() {
      return this.$refs.cvDropzone
    },
    getFiles() {
      let accepted = this.dz().getAcceptedFiles(),
        rejected = this.dz().getRejectedFiles(),
        totalFileCount = accepted.length + rejected.length;

      return { accepted, rejected, totalFileCount }
    },
    handleRemoveFile() {
      --this.fileCount;
      let { accepted, totalFileCount } = this.getFiles();
      if (totalFileCount === 1 && accepted.length === 1) {
        this.setDropzoneErrored(false);
        return
      }
      if (accepted.length !== 1) {
        this.file = null;
        return
      }
    },
    handleAddFile() {
      ++this.fileCount;
    },
  }
}


