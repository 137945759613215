import { render, staticRenderFns } from "./ProductDetailView.html?vue&type=template&id=13acd548&scoped=true&external"
import script from "./ProductDetailView.js?vue&type=script&lang=js&external"
export * from "./ProductDetailView.js?vue&type=script&lang=js&external"
import style0 from "./ProductDetailView.scss?vue&type=style&index=0&id=13acd548&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13acd548",
  null
  
)

export default component.exports