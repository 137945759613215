import ExpandableNavBar from '../../components/ExpandableNavBar/index.vue'
export default {
  name: 'admin',
  components: {
    ExpandableNavBar
  },
  props: [],
  data: () => ({
    navItems: [
      { link: '/Admin/Landing', title: 'Landing', icon: 'mdi-view-dashboard' },
      { link: '/Admin/UserManagement', title: 'UserManagement', icon: 'mdi-account-box-multiple' },
      { link: '/Admin/Environments', title: 'Environments', icon: 'mdi-pine-tree' },
      { link: '/Admin/Applications', title: 'Applications', icon: 'mdi-cellphone' }
    ],
  }),
  computed: {

  },
  methods: {

  }
}


