import { ConversionRequest, QueueJobRequest } from "@acst/mono-realm-conversions";
import filters from "../../../filters/filters"
import {
  bus
} from '../../../main'

export default {
  name: 'conversions-service-job-log',
  components: {},
  props: [],
  data () {
    return {
      serviceJobLog: [],
      interval: 0,
      showNoJobsMessage: false,
      loading: true,
      showQueueJobModal: false,
      jobStatusEnumMap: {
        0: 'NotSet',
        1: 'Pending',
        2: 'Queued',
        3: 'Running',
        4: 'Errored',
        5: 'Completed',
      },
      jobTypeEnumMap: {
        0:   "NotSpecified",
        1:   "Import",
        2:   "StagingMigration",
        3:   "Archive",
        4:   "StartConversion",
        5:   "RequeueConversion",
        101: 'Service',
      },
      queueJobOptions: [
        { text: "Staging Migration", value: 2 },
        { text: "Test Service", value: 101 },
      ],
      queueJobValue: null,
    }
  },
  computed: {
    inMaintenance() {
      return this.$store.getters.getConversionsInMaintenance;
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },

  created() {
    // refreshes the conversions job log for updated state
    bus.$off('refreshConversionsServiceJobLog');
    bus.$on('refreshConversionsServiceJobLog', () => {
      this.loading = true;
      this.pollingForServiceJobLog();
    });
  },

  methods: {
    secondsToDateTime: filters.secondsToDateTime,

    // determines whether to poll or not depending on tab status
    pollingForServiceJobLog() {
      this.interval = setInterval(function() {
        this.getServiceJobLog();
      }.bind(this), 5000);
    },

    // gets the conversions job data
    getServiceJobLog() {
      var conversionServiceJobLogRequest = new ConversionRequest();
      this.$store.dispatch('getConversionsServiceJobLog', conversionServiceJobLogRequest).then(() => {
        this.setServiceJobLog(this.$store.getters.getAllConversionsServiceJobLog);
        this.loading = false;
        if (this.$store.getters.getAllConversionsServiceJobLog.length == 0) {
          this.showNoJobsMessage = true;
        }
      });
    },

    // sets the job log data to the v-model for display
    setServiceJobLog(e) {
      this.serviceJobLog = [];
      for (var i = 0; i < e.length; i++) {
        var newConversionJobLogItem = {
          jobType: "",
          lastUpdated: "",
          inProgress: false,
          errors: []
        };

        // need to updated enum maps as more are added
        newConversionJobLogItem.jobType = this.jobTypeEnumMap[e[i].type];
        newConversionJobLogItem.lastUpdated = this.secondsToDateTime(e[i].updatedAt.seconds);
        newConversionJobLogItem.status = this.jobStatusEnumMap[e[i].status];
        newConversionJobLogItem.errors = e[i].errorsList;
        this.serviceJobLog.push(newConversionJobLogItem);
      }
    }, 
    queueJob() {
      let jobRequest = new QueueJobRequest();
      jobRequest.setType(this.queueJobValue)
      this.$store.dispatch('queueJob', jobRequest).then(() => {
        this.queueJobValue = null;
        this.showQueueJobModal = false;
      })
    }
  }
}

