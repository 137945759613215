import filters from "@/filters/filters.js";
import { ConversionRequest, ConversionStatus, ConversionType, HardResetData, JobStatus, JobType, QueueJobRequest } from "@acst/mono-realm-conversions";
import ConversionsActionControl from "../ConversionsActionControl";
import ConversionsAreaStatuses from '../ConversionsAreaStatuses';
import ConversionsImport from "../ConversionsImport";
import ConversionsImportFeedback from "../ConversionsImportFeedback";
import ConversionsJobLog from "../ConversionsJobLog";

export default {
  name: 'conversions-detail-view',
  components: {
    ConversionsActionControl,
    ConversionsImport,
    ConversionsImportFeedback,
    ConversionsJobLog,
    ConversionsAreaStatuses,
  },
  props: [],
  data() {
    return {
      expand_info_card: false,
      siteNumberHardResetValue: '',
      info_tab: null,
      conversionInfoInterval: undefined,
      hardResetInterval: undefined,
      ConversionStatus,
      ConversionTypeMapByValue: Object.fromEntries(Object.entries(ConversionType).map(([k, v]) => [v, k.toLowerCase()])),
      ConversionStatusMapByValue: Object.fromEntries(Object.entries(ConversionStatus).map(([k, v]) => [v, k.slice(10).toLowerCase()])),
    }
  },
  watch: {
    conversion(newVal) {
      let isRunningOrErrored = conversion => {
        return [
          ConversionStatus.CONVERSIONRUNNING,
          ConversionStatus.CONVERSIONRUNNINGWITHERRORS,
          ConversionStatus.CONVERSIONERRORED,
          ConversionStatus.CONVERSIONCOPYSITERUNNING,
          ConversionStatus.CONVERSIONCOPYSITEERRORED,
        ].indexOf(conversion.status) > -1
      }

      if (isRunningOrErrored(newVal)) {
        if (!this.conversionInfoInterval) {
          this.$store.dispatch('initPolling', {
            cb: this.fetchConversionInfo,
            duration: 5000,
          }).then(ivlID => {
            this.conversionInfoInterval = ivlID;
          })
        }
      } else if (this.isCompletedOrCanceled) {
        this.$store.dispatch('clearPolling', this.conversionInfoInterval);
      }
    },
    async pollForHardReset(newVal) {
      if (newVal) {
        this.$store.dispatch('initPolling', () => {
          this.$store.dispatch('getConversionJobLog', this.newConversionRequest());
        }).then(ivlID => {
          this.hardResetInterval = ivlID;
        })
      } else if (this.hardResetHistory && this.hardResetHistory.status === JobStatus.JOBSTATUSCOMPLETED) {
        let hardResetData = Object.assign(new HardResetData, JSON.parse(this.hardResetHistory.data));
        await this.$router.push({ name: 'Conversions.Detail', params: { id: hardResetData.new_conversion_id } });
        await this.$store.dispatch('resetConversionDetails');

        this.fetchConversionInfo().then(() => {
          this.info_tab = 0;
        });
      }
    }
  },
  mounted() {
    this.fetchConversionInfo().then(() => {
      this.expand_info_card = this.conversion.status === ConversionStatus.CONVERSIONNEW
    });
    this.$store.dispatch('getConversionJobLog', this.newConversionRequest());
  },
  beforeDestroy() {
    this.$store.dispatch('clearPolling', this.conversionInfoInterval);
    this.$store.dispatch('clearPolling', this.hardResetInterval);
    this.$store.dispatch('resetConversionDetails');
  },
  computed: {
    conversion() {
      return this.$store.getters.allConversionDetails;
    },
    statusIconClass() {
      if (!this.conversion) {
        return 'new';
      }
      return this.ConversionStatusMapByValue[this.conversion.status];
    },
    statusText() {
      if (!this.conversion) {
        return 'New';
      }
      //For multi-word enum values, adjust text here
      switch  (this.conversion.status) {
        case ConversionStatus.CONVERSIONRUNNINGWITHERRORS:
          return 'Running with Errors';
        case ConversionStatus.CONVERSIONCOPYSITEREADY:
          return 'Copy Site Ready';
        case ConversionStatus.CONVERSIONCOPYSITERUNNING:
          return 'Copy Site Running';
        case ConversionStatus.CONVERSIONCOPYSITEERRORED:
          return 'Copy Site Errored';
        case ConversionStatus.CONVERSIONCOPYSITEFINISHED:
          return 'Copy Site Finished';
        default:
          return this.ConversionStatusMapByValue[this.conversion.status];
      }
    },
    typeText() {
      return this.ConversionTypeMapByValue[!this.conversion ? ConversionType.FULL : this.conversion.conversionType];
    },
    jobHistory() {
      return this.$store.getters.getConversionsJobLog
    },
    startHistory() {
      let filteredJobs = this.jobHistory.filter(j => j.type === JobType.JOBTYPESTARTCONVERSION);
      return filteredJobs.length > 0 && filteredJobs[0];
    },
    importHistory() {
      let filteredJobs = this.jobHistory.filter(j => j.type === JobType.JOBTYPEIMPORT)
      return filteredJobs.length > 0 && filteredJobs[0];
    },
    hardResetHistory() {
      let filteredJobs = this.jobHistory.filter(j => j.type === JobType.JOBTYPERESET && j.status != JobStatus.JOBSTATUSERRORED);
      return filteredJobs.length > 0 && filteredJobs[0];
    },
    runAction() {
      if (!this.startHistory) {
        return {
          action: () => { this.startConversion(); },
          modalText: 'This will begin the conversion. This action is irreversible.',
          disabled: !this.conversion || this.conversion.status !== ConversionStatus.CONVERSIONREADY,
          icon: 'mdi-play-circle-outline',
          text: 'Start',
        }
      }
      return {
        action: () => {
          this.$store.dispatch('toggleConversionPause', this.newConversionRequest())
            .then(() => {
              this.fetchConversionInfo();
            });
        },
        modalText: this.conversion && this.conversion.isPaused ? 'This will resume processing the conversion. Do you want to continue?' : undefined,
        disabled: !this.isRunningOrErrored || (this.isNewOrReady && this.startHistory.status !== JobStatus.JOBSTATUSCOMPLETED),
        icon: `mdi-${this.conversion && this.conversion.isPaused ? 'play-circle-outline' : 'pause-circle-outline'}`,
        text: this.conversion && this.conversion.isPaused ? 'Resume' : 'Pause',
      }
    },
    isNewOrReady() {
      return this.conversion && (
        this.conversion.status === ConversionStatus.CONVERSIONNEW ||
        this.conversion.status === ConversionStatus.CONVERSIONREADY || 
        this.conversion.status === ConversionStatus.CONVERSIONCOPYSITEFINISHED
      )
    },
    isRunningOrErrored() {
      return this.conversion && (
        this.conversion.status === ConversionStatus.CONVERSIONRUNNING ||
        this.conversion.status === ConversionStatus.CONVERSIONERRORED ||
        this.conversion.status === ConversionStatus.CONVERSIONRUNNINGWITHERRORS ||
        this.conversion.status === ConversionStatus.CONVERSIONCOPYSITEREADY ||
        this.conversion.status === ConversionStatus.CONVERSIONCOPYSITERUNNING ||
        this.conversion.status === ConversionStatus.CONVERSIONCOPYSITEERRORED
      )
    },
    showAreaStatuses() {
      return this.conversion && (
        this.conversion.status === ConversionStatus.CONVERSIONRUNNING ||
        this.conversion.status === ConversionStatus.CONVERSIONERRORED ||
        this.conversion.status === ConversionStatus.CONVERSIONRUNNINGWITHERRORS ||
        this.conversion.status === ConversionStatus.CONVERSIONCOMPLETED
      )
    },
    isCompletedOrCanceled() {
      return this.conversion && (
        this.conversion.status === ConversionStatus.CONVERSIONCANCELED ||
        this.conversion.status === ConversionStatus.CONVERSIONCOMPLETED
      )
    },
    hardResetValidationRules() {
      return {
        required: value => !!value || 'Please provide a Site Number.',
        matchesSiteNumber: value => value === this.conversion.siteNumber || 'Does not match the Site Number for this conversion.'
      }
    },
    pollForImportFeedback() {
      return this.$store.getters.getConversionsPollForImportFeedback;
    },
    pollForHardReset() {
      return this.hardResetHistory && !(
        this.hardResetHistory.status === JobStatus.JOBSTATUSERRORED ||
        this.hardResetHistory.status === JobStatus.JOBSTATUSCOMPLETED
      );
    }
  },
  methods: {
    fetchConversionInfo() {
      return this.$store.dispatch('getConversionDetails', this.newConversionRequest());
    },
    toggleDialog(dialog) {
      this.dialogs[dialog] = !this.dialogs[dialog];
    },
    newConversionRequest() {
      let req = new ConversionRequest();
      req.setConversionId(this.$route.params.id);
      return req;
    },
    showImportDrawer() {
      this.$store.commit('setConversionShowImportDrawer', true);
    },
    secondsToDate: filters.secondsToDate,
    expandOnTabClick() {
      // wrapping in setTimeout for more seemless expand transition, since the tab click can
      // change the content (and height) of the card, its a little jumpy without it.
      setTimeout(() => {
        this.expand_info_card = true;
      }, 40);
    },
    showJobLogTab() {
      this.info_tab = 1;
    },
    toggleReady() {
      this.$store.dispatch('toggleConversionReady', this.newConversionRequest())
        .then(() => {
          this.fetchConversionInfo();
        });
    },
    deleteConversion() {
      this.$store.dispatch('deleteConversion', this.newConversionRequest()).then(() => {
        this.$router.replace({ name: 'Conversions' });
      });
    },
    archiveConversion() {
      this.$store.dispatch('archiveConversion', this.newConversionRequest()).then(() => {
        this.fetchConversionInfo();
      });
    },
    cancelConversion() {
      this.$store.dispatch('cancelConversion', this.newConversionRequest()).then(() => {
        this.fetchConversionInfo();
      });
    },
    queueConversionHardReset() {
      let req = new QueueJobRequest();
      req.setConversionId(this.$route.params.id);
      req.setType(JobType.JOBTYPERESET);
      this.$store.dispatch('queueJob', req);
      this.expandOnTabClick();
      this.showJobLogTab();
    },
    startConversion() {
      this.$store.dispatch('startConversion', this.$route.params.id).then(() => {
        // this.fetchConversionInfo();
        this.$store.dispatch('initPolling', {
          cb: this.fetchConversionInfo,
          duration: 5000,
        }).then(ivlID => {
          this.conversionInfoInterval = ivlID;
          this.showJobLogTab();
        });
      });
    },
  }
}


