import { ProductRequest } from "@acst/mono-corp-sites"

export default {
  name: 'product-detail-view',
  components: {},

  data () {
    return {
      permission: true,
      footerProps: {
        'items-per-page-options': [10],
        'items-per-page-text': ''
      },
    }
  },

  computed: {
    computedColor: function () {
      return this.$store.getters.getTheme ? 'black' : 'white';
    },

    product: function() {
      return this.$store.getters.productDetails;
    }
  },

  mounted() {
    var getProduct = new ProductRequest;
    getProduct.setProductId(this.$route.params.id)

    this.$store.dispatch('getProductDetails', getProduct);
  },

  methods: {},
}