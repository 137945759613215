import { ConversionRequest } from "@acst/mono-realm-conversions";

export default {
  name: 'conversions-search',
  data: () => ({
    loader: null,
    loading: false,
    errorMessages: '',
    dialog: false,
    siteNameSearch: null,
    siteNumberSearch: null,
    includeInactive: false,
    conversionStatuses: [{
        enum: 0,
        string: "New"
      },
      {
        enum: 1,
        string: "Ready"
      },
      {
        enum: 2,
        string: "Running"
      },
      {
        enum: 3,
        string: "Paused"
      },
      {
        enum: 4,
        string: "Errored"
      },
      {
        enum: 5,
        string: "Created"
      },
      {
        enum: 6,
        string: "Completed"
      }
    ],
    statusState: [],
    conversionTypes: [{
        enum: 1,
        string: "Full"
      },
      {
        enum: 2,
        string: "People"
      },
      {
        enum: 3,
        string: "Accounting"
      },
    ],
    conversionTypeState: [],
    dateCreatedOnStart: "",
    dateCreatedOnEnd: "",
    menuCreatedOnStart: false,
    menuCreatedOnEnd: false,
    newSearchParams: null,
    currentUser: '',
    onlyFollowed: false,
    watchers: [],
    watcherState: [],
  }),

  watch: {
    loader() {
      const l = this.loader
      this[l] = !this[l]

      this.loader = null
    },
    name() {
      this.errorMessages = ''
    },
    dialog(visible) {
      //set dialog to current applied filter values on open
      if(visible) {
        this.siteNameSearch = this.searchFilterValues.siteName;
        this.siteNumberSearch = this.searchFilterValues.siteNumber;
        this.includeInactive = this.searchFilterValues.includeInactive;
        this.onlyFollowed = this.searchFilterValues.onlyFollowed;
        this.statusState = this.searchFilterValues.statuses;
        this.conversionTypeState = this.searchFilterValues.conversionTypes;
        this.dateCreatedOnStart = this.searchFilterValues.dateCreatedOnStart;
        this.dateCreatedOnEnd = this.searchFilterValues.dateCreatedOnEnd;
      }
    }
  },

  destroyed() {
    window.removeEventListener('keypress', this.searchCommandForEnterKey);
  },

  created() {
    window.addEventListener('keypress', this.searchCommandForEnterKey);
  },

  computed: {
    searchFilterValues() {
      return this.$store.getters.getConversionSearchFilters;
    }
  },

  mounted() {
    this.setCurrentUser();
  },

  methods: {
    openSearch() {
      this.dialog = true;
    },

    cancelSearch() {
      this.dialog = false;
      this.resetForm();
    },

    // resets search parameters to default and performs a search.
    resetForm() {
      this.errorMessages = []
      this.loader = 'loading';

      this.siteNumberSearch = "";
      this.siteNameSearch = "";
      this.statusState = [];
      this.conversionTypeState = [];
      this.dateCreatedOnStart = "";
      this.dateCreatedOnEnd = "";
      this.includeInactive = false;
      this.onlyFollowed = false;
    },

    // used to submit the final query parameters for the search.
    submit() {
      this.dialog = false;
      this.loader = 'loading'; // loading until complete on search button.

      // build params for v-chips display on each search.
      this.newSearchParams = {
        siteNumber: this.siteNumberSearch,
        siteName: this.siteNameSearch,
        statuses: this.statusState,
        conversionTypes: this.conversionTypeState,
        dateCreatedOnStart: this.dateCreatedOnStart,
        dateCreatedOnEnd: this.dateCreatedOnEnd,
        includeInactive: this.includeInactive,
        onlyFollowed: this.onlyFollowed
      }

      if(this.onlyFollowed) {
        this.newSearchParams.followerIds = [this.currentUser.id];
      }
      
      // add searchParams to Vuex.
      this.$store.dispatch('settingConversionSearchFilters', this.newSearchParams);
    },

    // sets the current user and gets email
    setCurrentUser() {
      let newConversionAllWatcherRequest = new ConversionRequest();
      newConversionAllWatcherRequest.setConversionId("");

      this.$store.dispatch('listConversionWatchers', newConversionAllWatcherRequest).then(() => {
        var watchersList = this.$store.getters.getConversionsWatchers;
        var currentUserEmail = (this.$store.getters.getUserObject).email;
        var a;
        var watcherListValues = Object.values(watchersList);
        for (a = 0; a < watcherListValues.length; a++) {
          if (watcherListValues[a].emailAddress == currentUserEmail) {
            this.currentUser = watcherListValues[a];
          }
        }
      });
    },

    // registers and unregisters enter key for search
    searchCommandForEnterKey(e) {
      if (e.code == 'Enter') {
        this.submit();
      }
    }
  },
}