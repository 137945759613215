var mesa = require("../../store/mesa_client.js").default;
import { PromosClient } from "@acst/mono-corp-promos";
import { MediaClient } from "@acst/mono-ops-relay";

const getDefaultState = () => {
    return {
        promosClient: new PromosClient(mesa.do, ""),
        mediaClient: new MediaClient(mesa.do, "")
    }
}

const state = getDefaultState()

export default {
    state,
    getters: {},
    mutations: {},
    actions: {
        async getPromosList({ commit }, listPromosRequest) {
            return await state.promosClient.listPromos(listPromosRequest).then(resp => {
                return resp.toObject().promosList;
            }).catch(err => {
                console.log(err);
                commit('setErrorMessage', 'An Error Occurred Getting Promotions: ' + err.message);
            });
        },

        async addPromo({ commit }, addPromoRequest) {
            return await state.promosClient.addPromo(addPromoRequest).then(resp => {
                return resp.toObject();
            }).catch(err => {
                console.log(err);
                commit('setErrorMessage', 'An Error Occurred Adding the Promotion: ' + err.message);
            });
        },

        async deletePromo({ commit }, deletePromoRequest) {
            return await state.promosClient.deletePromo(deletePromoRequest).then(resp => {
                return resp.toObject();
            }).catch(err => {
                console.log(err);
                commit('setErrorMessage', 'An Error Occurred Deleting the Promotion: ' + err.message);
            });
        },

        async updatePromo({ commit }, updatePromoRequest) {
            return await state.promosClient.updatePromo(updatePromoRequest).then(resp => {
                return resp.toObject();
            }).catch(err => {
                console.log(err);
                commit('setErrorMessage', 'An Error Occurred Updating the Promo: ' + err.message);
            });
        },

        async getMediaQuickToken({ commit }) {
            return await state.mediaClient.getMediaQuickToken().then(resp => {
                return resp.getQuickToken();
            }).catch(err => {
                console.log(err);
                commit('setErrorMessage', 'An Error Occurred Getting the Quick Token: ' + err.message);
            })
        },

        async imageUpload({ commit }, imageUploadRequest) {
            return await state.mediaClient.imageUpload(imageUploadRequest).then(resp => {
                return resp.toObject().imageurl;
            }).catch(err => {
                console.log(err);
                commit('setErrorMessage', 'An Error Occurred Uploading the Image: ' + err.message);
            })
        },

        async wipeImage({ commit }, wipeImageRequest) {
            return await state.mediaClient.wipeImage(wipeImageRequest).then(resp => {
                return resp;
            }).catch(err => {
                console.log(err);
                commit('setErrorMessage', 'An Error Occurred Wiping the Image: ' + err.message);
            })
        }
    }
}