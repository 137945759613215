// NOTE: Avoid making changes in this module, it contains several sensitive global pieces to DevConsole
import router from '../../router/index.js';
export default {
    state: {
        // When accessing a variable inside of JS, it will convert said variable to camelCase.
        // Ex: user.email == what you expect, but is_super will turn into user.isSuper
        user: {
            id: "",
            email: "",
            profile_pic: "",
            given_name: "",
            family_name: "",
            full_name: "",
            registered_date: null,
            last_online: null,
            is_super: false,
        },

        user_settings: {
            darkTheme: false,
        },

        envVars: [],
        currentEnvironment: {},
        permissions: {},
        is_logging_in: false,
        is_logged_in: false,
        config: null,
        is_app_init: false
    },

    // Accessors used to retrieve data from our global store
    getters: {
        getIsAppReady: state => {
            return state.is_app_init;
        },
        // Check to see if we are in the middle of logging in
        getIsLoggingIn: state => {
            return state.is_logging_in;
        },
        // Check to see if we're done with initial logging in flow
        getIsLoggedIn: state => {
            return state.is_logged_in;
        },
        // Env Variable stuff
        getCurrentEnv: state => {
            return state.currentEnvironment;
        },
        getEnvVarsObject: state => {
            return state.envVars;
        },

        // Retrieve User Object
        getUserObject: state => {
            return state.user;
        },

        // This checks to see if a user has an Area-scope such as "Conversion:" or "Shepherd:".
        // This is NOT a full singular scope check but instead it is...
        // a "hey, do you have ANY scopes related to this Area?"
        hasAreaScope: (state) => (val) => {
            // is_super translates to isSuper in javascript
            if (state.user.isSuper) {
                return true;
            }

            else if (val != null) {
                let permsForEnvironment = state.permissions[state.currentEnvironment.label];
                // If we can find permissions for the current environment then we try to evaluate...
                if (permsForEnvironment != null) {
                    // We concat ':' onto the end of the value to prevent non-exact matches.
                    // Doing this means we don't need to split on ':' and only checking left-hand.
                    val = val + ":";

                    for (let x = 0; x < permsForEnvironment.length; x++) {
                        let element = permsForEnvironment[x];
                        if (element.includes(val)) {
                            return true;
                        }
                    }
                }
            }

            // Default to false if we don't hit a true statement above
            return false;
        },

        // You wrap this around your components, and pass the string of the expected needed permission.
        hasPermission: (state) => (val) => {
            // is_super translates to isSuper in javascript
            if (state.user.isSuper) {
                return true;
            }
            // Otherwise we check to see if the value being passed exists in the user's permissions object
            // This is based on currently selected environment from the Environment Dropdown 
            else if (val != null) {
                let permsForEnvironment = state.permissions[state.currentEnvironment.label];

                // If we can find permissions for the current environment then we try to evaluate...
                if (permsForEnvironment != null) {
                    if (permsForEnvironment.includes(val)) {
                        return true;
                    }
                }
            }

            // Default to false
            return false;
        },

        // Returns Admin permissions bool
        isAdmin(state) {
            // Need to check for generic area admin in the future, right now only checking for SuperAdmins
            return state.user.isSuper;
        },

        // Misc global settings
        getTheme: state => {
            return state.user_settings.darkTheme;
        },

        getUserPermissions: state => {
            return state.permissions;
        },
        getConfig: state => {
            return state.config;
        }
    },

    // Setters that are used by 'Actions' to set data inside of our store.
    // Mutators are synchronus methods that are called by Asynchronus 'Actions' to "Do" things.
    mutations: {
        setAppIsDone(state, isDone) {
            state.is_app_init = isDone;
        },
        // Save the isLoggingIn value
        saveIsLoggingInMutation(state, isLogin) {
            state.is_logging_in = isLogin;
            state.is_logged_in = true;
        },

        // Saves new user info
        saveUserMutation(state, newUser) {
            state.user = newUser;
        },

        // Mutation that deals with setting known environment variables from DevCon-Core
        setEnvVarsMutation(state, newVersion) {
            state.envVars = newVersion;
        },

        // Mutation that sets the current Env Var when a newConnObject is passed with a different ID than the last
        setCurEnvVarConnMutation(state, newConnObject) {
            if (state.currentEnvironment != null && state.currentEnvironment.id != newConnObject.id) {

                // Set our environment
                state.currentEnvironment = newConnObject;

                // If different or null then we set the selectedEnv to local storage
                if (state.currentEnvironment.label != null && state.currentEnvironment.label != "") {
                    window.localStorage.setItem("selectedEnv", state.currentEnvironment.label);
                }

                // Doing queryParam stuff based on currently selected Environment starts here...
                // Here we set our current Environment to a queryParam for shareability
                // This is how we can access $router from within a Vuex module
                let currentHistory = router.history.current;
                let currentEnvID = state.currentEnvironment.label;

                // If our environment param is null we want to grab the currently-known selectedEnv.
                // Otherwise we want to wait until the app is done initializing so this can drive our environmentParam from our DDL.
                if ((currentHistory.query.env == null) || (state.is_app_init == true && currentEnvID != null && (currentHistory.query.env != currentEnvID))) {
                    router.push({ query: Object.assign({}, currentHistory.query, { env: currentEnvID }) });
                }
            }
        },

        // Saves misc global settings
        setThemeMutation(state, themeBool) {
            state.user_settings.darkTheme = themeBool;
            // Set LocalStorage here for DarkTheme
            window.localStorage.setItem("userTheme", state.user_settings.darkTheme);
        },

        updateUserPermsMutation(state, jwtObject) {
            //If jwt is null, redirect
            //Checks for login and callback pages so there are no infinite loops
            if (jwtObject === null) {
                // If JWT doesn't exist, and not on /login or /callback then redirect to login
                return false;
            }
            else {  //If JWT exists
                var jwtSplice = jwtObject.split(".");
                var jwtBody = window.atob(jwtSplice[1]);
                var claimsObj = JSON.parse(jwtBody);

                if (claimsObj == null) {
                    return; // Leave without doing anything because JWT is broken...maybe redirect back to login?
                }

                // Creep through claims object a level at a time, checking for nulls
                var perms = claimsObj.pri;
                if (perms != null) {
                    state.permissions = perms.permissions;
                }
            }
        },

        // Sets the app config to whatever value given
        setConfig(state, config) {
            state.config = config;
        }
    },

    actions: {
        // Note: Moved from our "root" route.js due to modularization of routes being incompatible.
        // Custom AuthRoute method that can be used to protect specific routes behind scopes.
        // Accepts a "scope" of type string that checks to see if the user should be able to traverse to the
        // given route. Ex: 'conversions:admin' would be passed to this method to be checked against the
        // currently logged-in user's scopes JWT claim. Failure to auth redirects to login landing.
        authorizedRoute(context, next, permToCheck) {
            let isAuthorized = this.getters.hasPermission(permToCheck);
            if (isAuthorized) {
                next();
            } else {
                next('/Dashboard'); // return disallowed users to the dashboard (considered /home)
            }
        },
        // setAppIsDone
        saveAppIsDone(context, isDone) {
            context.commit('setAppIsDone', isDone);
        },
        saveLoggingIn(context, isLogin) {
            context.commit('saveIsLoggingInMutation', isLogin);
        },
        // Saves new user info using mutation
        saveUserObject(context, newUser) {
            context.commit('saveUserMutation', newUser); // Calls the Mutation 'saveUser' to store current user information.
        },
        // Saves latest available Environments that were pulled from DevCon-Core on the FE for use in the DDL
        setNewEnvVarsObject(context, newVersion) {
            context.commit('setEnvVarsMutation', newVersion);
        },
        // Changes the current Environment Connection String to what has been recently selected in the Environments DDL
        setNewEnvVarConnString(context, newConnObject) {
            context.commit('setCurEnvVarConnMutation', newConnObject);
        },
        // Setting user permissions object so we can do FrontEnd checks with our permissions helper, lets us use logic against known permissions of a user
        storeUserPermissions(context, permsToUpdate) {
            context.commit('updateUserPermsMutation', permsToUpdate)
        },
        // Changes root-color scheme of entire project to whatever has been switched in the Settings view
        setCurrentColorScheme(context, newThemeBool) {
            context.commit('setThemeMutation', newThemeBool);
        },
        // Gather the config from file or server
        async gatherConfig(context) {
            if (process.env.NODE_ENV === "production") {
                // Get configs from server
                const axios = require("axios").default;
                let resp = await axios.get('/config')
                context.commit("setConfig", resp.data)
            } else {
                // Read from disk (using raw-loader)
                const config = require('../../../config.json');
                context.commit("setConfig", config);
            }
        }
    }
}