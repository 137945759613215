//Accounts Imports
import Accounts from '../../views/Accounts/index.vue'
import AccountsList from './AccountsList/index.vue'

export default [
  {
    path: '/Accounts',
    component: Accounts,
    children: [
      {
        name: 'AccountList',
        path: '',
        component: AccountsList
      },
    ]
  },
];