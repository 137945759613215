import ExpandableNavBar from '../../components/ExpandableNavBar/index.vue'
export default {
  name: 'site-list',
  components: {
    ExpandableNavBar
  },
  props: [],
  data: () => ({
    navItems: [
      { link: '../SiteList/ListView', title: 'SiteList Table', icon: 'mdi-view-dashboard' },
    ],
    backgroundColor: '#BDBDBD'
  }),
  computed: {
    computedBackgroundColor: function () {
      return this.$store.getters.getTheme ? 'black' : '#f2f3f6';
    }
  },
}