<template>
  <v-dialog persistent eager v-model="visible" max-width="640">
    <v-card>
      <v-card-title>{{
          (!this.tokenExists ? "Add" : "Update") + " Token"
      }}</v-card-title>
      <v-card-subtitle>
        Name this token and select which scopes should be made available to it
        per environment.
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="addUpdateForm">
          <v-text-field label="Name" hint="What will this token be used for?" v-model="name"
            :rules="[rules.required, rules.counter]"></v-text-field>
          <v-container>
            <h3>Environment scopes</h3>
            <div>
              <p>
                If no environments are enabled, all scopes {{ isApplication ? "" : "available to you" }} will
                be made available to the token.
              </p>
              <p>
                If no scopes are provided for an enabled environment, all scopes
                available {{ isApplication ? "" : "to you" }} in that environment will be made available to the
                token.
              </p>
            </div>
            <v-row dense v-for="env in availableEnvs" :key="env.name">
              <v-col cols="1">
                <v-checkbox v-model="env.enabled"></v-checkbox>
              </v-col>
              <v-col>
                <v-autocomplete v-if="!isApplication" :disabled="!env.enabled" :items="env.availableScopes" chips
                  deletable-chips multiple clearable return-object v-model="env.selectedScopes"
                  :label="env.name + ' environment'">
                </v-autocomplete>
                <v-combobox v-else :disabled="!env.enabled" :items="env.availableScopes" chips deletable-chips multiple
                  clearable return-object v-model="env.selectedScopes" :label="env.name + ' environment'">
                </v-combobox>
              </v-col>
            </v-row>

          </v-container>
          <v-checkbox v-if="this.tokenExists" label="Regenerate token" v-model="regenerate">
          </v-checkbox>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancelUpdateToken">Cancel</v-btn>
        <v-btn text color="primary" @click="addUpdateToken">{{
            !this.tokenExists ? "Add" : "Update"
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { AddApplicationTokenRequest, AddUserTokenRequest, GetTokenRequest, TokenPermission, UpdateTokenRequest } from "@acst/mono-ops-devconsole";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "add-update-token-dialog",
  props: {
    value: Boolean,
    id: String,
    envs: Object,
    applicationID: String,
  },
  data() {
    return {
      name: "",
      regenerate: false,
      rules: {
        required: (value) => !!value || "Name is required",
        counter: (value) =>
          value.length <= 50 || "Name cannot be more than 50 characters",
      },
    };
  },
  computed: {
    ...mapGetters(["newTokenValue"]),
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    availableEnvs: function () {
      return this.isApplication ? this.envs : Object.values(this.envs).filter(
        (env) => env.availableScopes.length > 0
      );
    },
    tokenExists: function () {
      return this.id !== null;
    },
    isApplication() {
      return !!this.applicationID;
    }
  },
  watch: {
    id: function (newValue) {
      this.resetForm();

      if (newValue === null) {
        return;
      }
      // Get details for selected token. 
      let req = new GetTokenRequest().setId(newValue);
      this.$store.dispatch("getToken", req).then((response) => {
        this.name = response.name;

        // Set selected scopes based on response.
        for (let perm of response.permissionsList) {
          this.envs[perm.envId].enabled = true;
          if (perm.scope !== "") {
            this.envs[perm.envId].selectedScopes.push(perm.scope);
          }
        }
      });
    },
  },
  methods: {
    ...mapActions(["addApplicationToken", "addUserToken", "updateToken", "cancelUpdateToken"]),
    async addUpdateToken() {
      if (!this.$refs.addUpdateForm.validate()) {
        return;
      }

      const perms = [];
      for (const [id, env] of Object.entries(this.envs)) {
        if (env.enabled) {
          if (env.selectedScopes.length > 0) {
            // Limit to selected scopes for this env.
            for (const scope of env.selectedScopes) {
              perms.push(new TokenPermission().setEnvId(id).setScope(scope));
            }
          } else {
            // Default: implicitly include all scopes available to user for this env.
            perms.push(new TokenPermission().setEnvId(id));
          }
        }
      }

      if (!this.tokenExists) {
        if (this.isApplication) {
          let req = new AddApplicationTokenRequest()
            .setApplicationId(this.applicationID)
            .setName(this.name)
            .setPermissionsList(perms);
          await this.addApplicationToken(req)
        } else {
          let req = new AddUserTokenRequest()
            .setName(this.name)
            .setPermissionsList(perms);
          await this.addUserToken(req);
        }

        this.showTokenCopyDialog = true;
      } else {
        let req = new UpdateTokenRequest()
          .setId(this.id)
          .setName(this.name)
          .setPermissionsList(perms)
          .setRegenerateToken(this.regenerate);

        await this.updateToken(req);
        if (this.regenerate) {
          this.showTokenCopyDialog = true;
        }
      }

      this.$emit('add-update-token');
      this.resetForm();
      this.visible = false;
    },
    cancelUpdateToken() {
        this.$emit('cancel-update-token');
        this.resetForm();
        this.visible = false;
    },
     resetForm() {
      // Clear name, enabled, and selected scopes.
      for (let key of Object.keys(this.envs)) {
        this.envs[key].enabled = false;
        this.envs[key].selectedScopes = [];
      }
      this.regenerate = false;
      this.$refs.addUpdateForm.resetValidation();
    },
  },
};
</script>
