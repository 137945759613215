// NOTE: This is currently a WIP, but is being commented out to get other work in place
// for other teams.

// This module directly accesses only the parent service "Relay".
// Nested Services should have their own module created that contain their specific calls.
import { RelayClient } from "@acst/mono-ops-relay";
var mesa = require("../../store/mesa_client.js").default;
export default {
    state: {
        // ************************************************************************
        // TODO:
        // Change this from static -> use selectedEnvironment before committing.
        // ************************************************************************
        relayClient: new RelayClient(mesa.do, "")
    },
    // Accessors used to retrieve data from our global store
    getters: {
    },
    // Setters that are used by 'Actions' to set data inside of our store.
    // Mutators are synchronus methods that are called by Asynchronus 'Actions' to "Do" things.
    mutations: {
    },
      
    actions: {
        async getRelayServices({state}) {
            return await state.relayClient.getServices().then(function(response){
                return response.toObject();
            });
        },
        async getRelayNameSpaces({state}) {
            return await state.relayClient.getNamespaces().then(function(response){
                return response.toObject();
            });
        },
        async helloWorld() {
            console.log("Hello There Mister!");
        },
        setNewConnectionString({state}, newConnString) {
            state.relayClient.setSourceURL(newConnString);
        }
    }
}