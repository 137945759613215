export default {
  name: 'settings',
  components: {},
  props: [],
  data: () => ({
    goDark: false,
  }),
  watch:{
    // Change colorScheme from here
    goDark: function () {
      this.$store.dispatch('setCurrentColorScheme', this.goDark)
    }
  },
  beforeDestroy() {
    this.watchTheme();
  },
  created () {
    // Watching for the theme-switch under settings to change
    this.watchTheme = this.$store.subscribe((mutation) => {
      if (mutation.type == 'setThemeMutation'){
        this.SetCurrentSettingValues();
      }
    });
  },
  mounted () {
    this.SetCurrentSettingValues();
  },
  methods: {
    SetCurrentSettingValues(){
      this.goDark = this.$store.getters.getTheme;
    },
  }
}


