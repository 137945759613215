export default {
  name: 'product-list',
  components: {},

  data () {
    return {
      permission: true,
      footerProps: {
        'items-per-page-options': [10],
        'items-per-page-text': ''
      },
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: "left", 
          sortable: true
        },
        {
          text: 'CRM Product Name',
          value: 'crmProductName', 
          sortable: true
        },
        {
          text: 'Status',
          value: 'status', 
          sortable: true
        },
        {
          text: 'Description',
          value: 'description', 
          sortable: true
        },
      ],
    }
  },

  computed: {
    computedColor: function () {
      return this.$store.getters.getTheme ? 'black' : 'white';
    },

    productList: function () {
      return Object.values(this.$store.getters.allGlobalSitesProducts ?? []);
    },
  },

  mounted() {
    this.$store.dispatch('getProductList');
  },

  methods: {},
}