<template>
  <v-dialog v-model="visible" max-width="320">
    <v-card>
      <v-card-title>Delete Token?</v-card-title>
      <v-card-text>
        <p>This will permanently delete the <b>{{ name }}</b> token.</p>
        <p>This action cannot be undone.</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="visible = false">Cancel</v-btn>
        <v-btn text color="error" @click="deleteToken(id)">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DeleteTokenRequest } from "@acst/mono-ops-devconsole";

export default {
  name: "delete-token-dialog",
  props: { value: Boolean, id: String, name: String },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async deleteToken(id) {
      let req = new DeleteTokenRequest().setId(id);
      await this.$store.dispatch("deleteToken", req);
      this.visible = false;
      this.$emit("delete-token");
    },
  },
};
</script>
