import { GetUserDetailsRequest } from "@acst/mono-ops-devconsole";
export default {
  name: 'admin-user-details',
  components: {},
  props: [],
  data () {
    return {
      userId: "",
      userDetailsObject: {},
      that: this
    }
  },
  computed: {

  },
  mounted () {
    this.GetUserIDFromRoute();
  },
  methods: {
    GetUserIDFromRoute() {
      this.userId = this.$router.history.current.query.user;
      this.GetUserDetails();
    },
    GetUserDetails() {
      let that = this;
      let newDetailsReq = new GetUserDetailsRequest();
      newDetailsReq.setId(this.userId);

      this.$store.dispatch('getUserDetails', newDetailsReq)
      .then(function(response) {
        that.userDetailsObject = response.user;
      })
      .catch(function(response) {
        console.log('///////----////////');
        console.log(`Error: ${response.message}`);
        console.log('///////----////////');
      });
    }
  }
}


