// NOTE: Avoid changing this unless something needs to be imported/added to the base of the project

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import store from './plugins/vuex'
import Axios from 'axios';

// This attaches interceptors to Vue's http calls so we can listen to all responses coming back.
// Doing this allows us to listen for certain events globally on DevConsole such as new headers, etc.
Axios.interceptors.response.use((response) => {

  // If a new refreshed token exists in the response's headers then we replace current jwt with the new one.
  checkForJWTRefresh(response);

  // Pass through the response to original caller
  return response;

}, (error) => {
  // If an error occurs, return a rejected promise and pass through the error to the original caller.
  return Promise.reject(error);
});


export const bus = new Vue();

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  computed: {
    user () {
      return store.state.user
    }
  },
  beforeCreate (){
    this.unsubscribe = store.subscribe((mutation) => {
      if (mutation.type == 'setAppIsDone'){
        if (store.getters.getIsAppReady == true){
          this.$mount('#app');
          this.unsubscribe();
        }
      }
    });
  },
  beforeDestroy(){
    this.unsubscribe();
  },
  render: h => h(App)
});

//// -----------------------------------
//// CUSTOM MAIN-ONLY FUNCTIONS BELOW HERE
// These functions will be called before the app is even loaded.
// Note: Do not put non-critical non-core functions here for a specific area.

// If a new JWT exists in the response then we replace the old with that one.
function checkForJWTRefresh(response) {
  if (response.headers["x-new-token"]) {
    let refreshedJWT = response.headers["x-new-token"];
    // Store the new jwt by overwriting the old one.
    window.localStorage.setItem("jwt", refreshedJWT);
    // Overwrite old Scoped Permissions with new ones.
    store.dispatch('storeUserPermissions', window.localStorage.getItem("jwt"));
  }
}

//// END OF CUSTOM MAIN-ONLY FUNCTIONS
//// -----------------------------------