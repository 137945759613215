var mesa = require("../../store/mesa_client.js").default;
import { AuthenticationClient } from "@acst/mono-corp-authentication";
import { ListAccountsRequest } from "@acst/mono-corp-authentication";
import { DateFilter } from "@acst/mono-corp-authentication";
import Vue from "vue";

const getDefaultState = () => {
  return {
    authClient: new AuthenticationClient(mesa.do, ""),
    global_auth_accounts: [],
    account_search_filters: [],
  }
}

const state = getDefaultState()
  
export default {
  state,

  getters: {
    allGlobalAuthAccounts: state => state.global_auth_accounts,

    accountSearchFilters: state => state.account_search_filters,
  },

  mutations: {
    resetAccounts(state) {
      state.accounts = {};
    },

    setAccounts(state, accounts) {
        Vue.set(state, "global_auth_accounts", accounts.map( account => {
            return {
                accountID: account.accountId,
                email: account.email,
                emailValidated: account.emailValidated,
                lastSeen: account.lastSeenAt,
                lockedAt: account.lockedAt,
                locked: account.locked,
                firstName: account.firstName,
                lastName: account.lastName,
                photoURL: account.photoUrl,
                createdAt: account.createdAt,
            }
        }));
    },

    setAccountSearchFilters(state, filters) {
        Vue.set(state, "account_search_filters", filters)
    }
  },
    
  actions: {
    // getAccountList retrieves a list of all the accounts
    async getAccountList({ commit }, ListAccountsRequest) {
      return await state.authClient.listAccounts(ListAccountsRequest).then(function(response) {
          commit('setAccounts', response.toObject().accountsList);
          commit('setErrorMessage', '');
          return response.toObject();
      }).catch(function(error) {
          console.log(error);
          commit('setErrorMessage', 'An Error Occured Getting the Accounts List');
      });
    },

    // settingAccountSearchFilters will set the search filters to be displayed
    settingAccountSearchFilters({commit}, e) {
        commit('setAccountSearchFilters', e)
    },

    removeAccountSearchFilter({commit, dispatch, getters}, filter) {
        var allFilters = getters.accountSearchFilters;
        var afterDate = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
        var beforeDate = new Date().toISOString().substr(0,10);

        if (filter.filterType == "Site ID") {
            allFilters.siteID = "";
        }
        
        else if (filter.filterType == "Email") {
            allFilters.email = "";
        }

        else if (filter.filterType == "Email Validated") {
            allFilters.emailValidated = false;
        }

        else if (filter.filterType == "Created Before") {
            allFilters.createdAt.stopDate = beforeDate;
        }

        else if (filter.filterType == "Created After") {
            allFilters.createdAt.startDate = afterDate;
        }

        else if (filter.filterType == "Locked Before") {
            allFilters.lockedAt.stopDate = beforeDate;
        }

        else if (filter.filterType == "Locked After") {
            allFilters.lockedAt.startDate = afterDate;
        }

        else if (filter.filterType == "Last Seen Before") {
            allFilters.lastSeen.stopDate = beforeDate;
        }

        else if (filter.filterType == "Last Seen After") {
            allFilters.lastSeen.startDate = afterDate;
        }

        var listAccounts = new ListAccountsRequest;

        listAccounts.setEmail(allFilters.email);
        listAccounts.setEmailValidated(allFilters.emailValidated);
        listAccounts.setSiteId(allFilters.siteID);
  
        // set all date filters
        if (allFilters.createdAt != undefined) {
          var created = new DateFilter();
          created.setStartDate(allFilters.createdAt.startDate);
          created.setStopDate(allFilters.createdAt.stopDate);
  
          // checks if the default range has been set
          if (allFilters.createdAt.startDate == afterDate) {
            created.setStartDate("");
          }
  
          if (allFilters.createdAt.stopDate == beforeDate) {
            created.setStopDate("");
          }
  
          listAccounts.setCreatedAt(created);
        }
  
        if (allFilters.lockedAt != undefined) {
          var lockedAt = new DateFilter();
          lockedAt.setStartDate(allFilters.lockedAt.startDate);
          lockedAt.setStopDate(allFilters.lockedAt.stopDate);
          
          // checks if the default range has been set
          if (allFilters.lockedAt.startDate == afterDate) {
            lockedAt.setStartDate("");
          }
  
          if (allFilters.lockedAt.stopDate == beforeDate) {
            lockedAt.setStopDate("");
          }
  
          listAccounts.setLockedAt(lockedAt);
        }
  
        if (allFilters.lastSeen != undefined) {
          var lastSeen = new DateFilter();
          lastSeen.setStartDate(allFilters.lastSeen.startDate);
          lastSeen.setStopDate(allFilters.lastSeen.stopDate);
          
          // checks if the default range has been set
          if (allFilters.lastSeen.startDate == afterDate) {
            lastSeen.setStartDate("");
          }
  
          if (allFilters.lastSeen.stopDate == beforeDate) {
            lastSeen.setStopDate("");
          }
  
          listAccounts.setLastSeen(lastSeen);
        }

        commit('setAccountSearchFilters', allFilters);
        dispatch('getAccountList', listAccounts);
      },
  },
}