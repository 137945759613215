<template>
  <v-dialog persistent v-model="visible" max-width="720">
    <v-card>
      <v-card-title>Token Generated</v-card-title>
      <v-card-text>
        Copy the token and store it in a safe place. It will not be shown again.
      </v-card-text>
      <v-card-text>
        <v-text-field v-model="newTokenValue" readonly outlined filled>{{ newTokenValue }}
          <template v-slot:append-outer>
            <v-btn icon @click="copyToClipboard(newTokenValue)">
              <v-icon :color="copyClicked ? 'success' : ''">{{
                  copyClicked ? "mdi-check" : "mdi-content-copy"
              }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="clear">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "copy-token-dialog",
  data() {
    return {
      copyClicked: false,
    };
  },
  computed: {
    ...mapGetters(["newTokenValue"]),
    visible() {
      return this.newTokenValue !== null
    }
  },
  methods: {
    ...mapActions(["clearNewTokenValue"]),
    clear() {
      this.clearNewTokenValue();
      this.copyClicked = false;
    },
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
      this.copyClicked = true;
    },
  },
};
</script>