import ConversionsHeadlineMetrics from "../ConversionsHeadlineMetrics/index.vue"
import ConversionsServiceStatus from "../ConversionsServiceStatus/index.vue"
import ConversionsUpdateWorkerCount from "../ConversionsUpdateWorkerCount/index.vue";
import ConversionsServiceJobLog from "../ConversionsServiceJobLog/index.vue";
import ConversionsInMaintenanceAlert from "@/components/Conversions/ConversionsInMaintenanceAlert";
import {bus} from '../../../main'

export default {
  name: 'conversions-admin-view',
  components: {
    ConversionsHeadlineMetrics,
    ConversionsInMaintenanceAlert,
    ConversionsServiceStatus,
    ConversionsUpdateWorkerCount,
    ConversionsServiceJobLog
  },
  props: [],
  data() {
    return {
      permission: false,
      renderConversionServiceJobLogComponent: true,
    }
  },
  computed: {
    inMaintenance: {
      get: function() {
        return this.$store.getters.getConversionsInMaintenance;
      },
      set: function() {
        this.$store.dispatch('toggleMaintenanceModeStatus');
      }
    }
  },
  created() {
    this.permission = this.$store.getters.hasPermission('conversions:superadmin');
    if (!this.permission) {
      this.$router.push({name: 'Conversions'})
    }
  },
  mounted() {
    bus.$emit('refreshConversionsServiceJobLog')
  },
  methods: {
    // renders the conversions service job log
    renderConversionsJobsComponent() {
      setTimeout(() => {
        bus.$emit('refreshConversionsServiceJobLog');
      }, 250);
      
      this.renderConversionServiceJobLogComponent = false;
      this.$nextTick(() => {
        this.renderConversionServiceJobLogComponent = true;
      });
    }
  }
}
