import { AreaStatus, ConversionListRequest, ConversionRequest, ConversionsClient, JobType, QueueJobRequest } from "@acst/mono-realm-conversions";
import { ShepherdClient } from "@acst/mono-realm-shepherd";
import axios from "axios";
import Vue from "vue";
var mesa = require("../../store/mesa_client.js").default;

const getDefaultState = () => {
    return {
        conversions_inital_error_message: "",
        conversions_area_errors: [],
        conversions_area_statuses: [],
        conversion_details: {},
        conversions: [],
        conversion_list: [],
        conversions_xml_upload_status: "",
        conversions_search_filters: {
            conversionTypes: [],
            dateCreatedOnEnd: "",
            dateCreatedOnStart: "",
            includeInactive: false,
            siteName: "",
            siteNumber: "",
            statuses: [],
            onlyFollowed: false,
            followerIds: [],
        },
        conversions_watchers: {},
        conversions_active_watchers: [],
        conversions_polling_for_import_status: false,
        conversions_presigned_url: "",
        conversionsClient: new ConversionsClient(mesa.do, ""),
        conversions_summary_statuses: {},
        conversions_statistics: {},
        conversion_service_statuses: {},
        area_type_worker_counts: {},
        conversions_in_maintenance: false,
        conversions_in_maintenance_at: 0,
        conversions_import_log: [],
        conversions_job_log: [],
        conversions_service_job_log: [],
        conversion_show_import_drawer: false,
        conversions_poll_for_import_feedback: false,
        shepherdClient: new ShepherdClient(mesa.do, "")
    }
}

const state = getDefaultState()

export default {
    state,

    // Accessors used to retrieve data from our global store
    getters: {
        // get the conversions
        allConversions: state => state.conversions,

        // get the conversions_inital_error_message
        getConversionsInitialErrorMessage: state => state.conversions_inital_error_message,

        // get the area statuses
        getAllConversionAreaStatuses: state => state.conversions_area_statuses,

        // get the conversionDetails
        allConversionDetails: state => state.conversion_details,

        // get the getAreaErrors
        getConversionsAreaErrors: state => state.conversions_area_errors,

        // get the conversions_presigned_url
        getConversionsPresignedURL: state => state.conversions_presigned_url,

        // get the conversions_xml_upload_status
        getConversionsUploadStatus: state => state.conversions_xml_upload_status,

        // get the conversion search filters
        getConversionSearchFilters: state => state.conversions_search_filters,

        // get the conversions_watchers for the search filter
        getConversionsWatchers: state => state.conversions_watchers,

        //  get the conversions_active_watchers for the search filter
        getConversionsActiveWatchers: state => state.conversions_active_watchers,

        // get the conversions_polling_for_import_status
        getConversionsPollingForImportStatus: state => state.conversions_polling_for_import_status,

        // get the conversions_summary_statuses
        getConversionsSummary: state => state.conversions_summary_statuses,

        // get the conversions_statistics
        getConversionStatistics: state => state.conversions_statistics,

        // get the service statuses
        getConversionServiceStatuses: state => state.conversion_service_statuses,

        // get the list of all worker counts
        getWorkerCounts: state => state.area_type_worker_counts,

        // get the maintenance mode status of the conversions service
        getConversionsInMaintenance: state => state.conversions_in_maintenance,

        // get the timestamp of when the service went into maintenance
        getConversionsInMaintenanceAt: state => state.conversions_in_maintenance_at,

        // get the conversion's import log
        getConversionsImportLog: state => state.conversions_import_log,

        // get the conversion's job log
        getConversionsJobLog: state => state.conversions_job_log,

        // get the conversion's service job log
        getAllConversionsServiceJobLog: state => state.conversions_service_job_log,

        // get open state of conversion import drawer
        getConversionShowImportDrawer: state => state.conversion_show_import_drawer,

        getConversionsPollForImportFeedback: state => state.conversions_poll_for_import_feedback,
    },
    // Setters that are used by 'Actions' to set data inside of our store.
    // Mutators are synchronus methods that are called by Asynchronus 'Actions' to "Do" things.
    mutations: {
        // resetConversions resets the conversions.
        resetConversions(state) {
            state.conversions = [];
        },

        // resets the conversions_area_errors to a blank array
        resetConversionsAreaStatusErrors(state) {
            state.conversions_area_errors = [];
        },

        // resets the conversions_details object
        resetConversionDetails() {
            state.conversion_details = {};
        },

        // resets the conversions_import_log to an empty array
        resetConversionsImportLog() {
            state.conversions_import_log = [];
        },

        // set the conversions to the state
        setConversions(state, conversions) {
            state.conversions = conversions;
        },

        // set the inital_error_message to the state
        setConversionsInitialErrorMessage(state, inital_error_message) {
            state.conversions_inital_error_message = inital_error_message;
        },

        // set area_statuses to the state
        setConversionsAreaStatuses(state, area_type_statuses) {
            const areaStatusesByValue = Object.fromEntries(Object.entries(AreaStatus).map(([k, v]) => [v, k]));
            let at_stat_maps = area_type_statuses.map(([id, { rank, label, statusesMap }]) => {
                return {
                    id,
                    label,
                    rank,
                    AREATOTAL: statusesMap.reduce((acc, [, count]) => acc + (count ?? 0), 0),
                    ...Object.fromEntries(statusesMap.map(([e, count]) => [areaStatusesByValue[e], count])),
                }
            });
            Vue.set(state, 'conversions_area_statuses', at_stat_maps);

        },

        // set the conversion_details to the state
        setConversionDetails(state, conversion_details) {
            Vue.set(state, 'conversion_details', conversion_details);
        },

        // set the conversions_area_errors to the state
        setConversionsAreaErrors(state, area_errors) {
            Vue.set(state, 'conversions_area_errors', area_errors)
        },

        // set the conversions_presigned_url to the state
        setConversionsPresignedURL(state, presigned_url) {
            state.conversions_presigned_url = presigned_url;
        },

        // set the conversions_xml_upload_status to the state
        setConversionsUploadStatus(state, status) {
            state.conversions_upload_status = status;
        },

        // set the filters to the state
        setConversionsFilters(state, filters) {
            if (filters.length > 0) {
                for (const prop in filters) {
                    Vue.set(state.conversions_search_filters, prop, filters[prop]);
                }
            }
            else {
                state.conversions_search_filters = filters;
            }
        },

        // set the conversions_watchers for filtering the conversions
        setConversionsWatchers(state, watchers) {
            watchers.forEach(watcher => {
                Vue.set(state.conversions_watchers, watcher.id, {
                    id: watcher.id,
                    fullName: watcher.fullName,
                    firstName: watcher.firstName,
                    lastName: watcher.lastName,
                    role: watcher.role,
                    slackUserId: watcher.slackUserId,
                    emailAddress: watcher.emailAddress
                });
            });
        },

        // set the conversions_active_watchers for filtering the conversions
        setConversionsActiveWatchers(state, active_watchers) {
            if (active_watchers.length > 0) {
                for (const prop in active_watchers) {
                    Vue.set(state.conversions_active_watchers, prop, active_watchers[prop]);
                }
            }
            else {
                state.conversions_active_watchers = active_watchers;
            }
        },

        // set the conversions_polling_for_import_status to the state
        setConversionsPollingForImportStatus(state, polling) {
            state.conversions_polling_for_import_status = polling;
        },

        // set the conversions_summary_statuses to the state
        setConversionsSummary(state, conversions_summary) {
            state.conversions_summary_statuses = conversions_summary;
        },

        // set the conversions_statistics to the state
        setConversionStatistics(state, conversions_statistics) {
            state.conversions_statistics = conversions_statistics;
        },

        // sets the statuses of all the services
        setConversionServiceStatuses(state, service_statuses) {
            state.conversion_service_statuses = service_statuses;
        },

        // sets the worker counts of all area types
        setAreaTypeWorkerCounts(state, worker_counts) {
            state.area_type_worker_counts = worker_counts;
        },

        // sets the maintenance mode status
        setConversionsInMaintenance(state, new_status) {
            state.conversions_in_maintenance = new_status;
        },

        // sets the timestamp of when all conversions were paused
        setPausedTime(state, paused_time) {
            var removed_nano = paused_time.split("+", 1)[0];
            var newDate = new Date(removed_nano);
            state.paused_time = newDate.toLocaleString("en-US", { timeZone: "EST" });
        },

        // sets the current conversion's import log to the state
        setConversionsImportLog(state, import_log) {
            state.conversions_import_log = import_log;
        },

        // sets the current conversion's import log to the state
        setConversionsJobLog(state, conversions_job_log) {
            Vue.set(state, 'conversions_job_log', conversions_job_log);
        },

        // sets the current conversion's import log to the state
        setConversionsServiceJobLog(state, conversions_service_job_log) {
            state.conversions_service_job_log = conversions_service_job_log;
        },

        // sets the timestamp of when the service went into maintenance
        setConversionsInMaintenanceAt(state, new_time) {
            state.conversions_in_maintenance_at = new_time ? new_time.seconds : 0;
        },

        // sets the open state of the conversion import drawer
        setConversionShowImportDrawer(state, new_val) {
            state.conversion_show_import_drawer = new_val;
        },

        setConversionsPollForImportFeedback(state, new_val) {
            state.conversions_poll_for_import_feedback = new_val;
        },
    },

    actions: {
        // resetConversions resets the details.
        resetConversions({ commit }) {
            commit('resetConversions');
        },

        // resetConversionDetails resets the details.
        resetConversionDetails({ commit }) {
            commit('resetConversionDetails');
        },

        // resets the conversions_active_watchers to a blank array
        clearConversionsActiveWatchers({ commit }) {
            commit('setConversionsActiveWatchers', []);
        },

        // resets the conversions_import_log to a blank array
        clearConversionsImportLog({ commit }) {
            commit('resetConversionsImportLog');
        },

        // getConversionAreaStatuses gets area statuses from endpoint
        async getConversionAreaStatuses({ commit }, conversionRequest) {
            return await state.conversionsClient.getAreaStatuses(conversionRequest).then(resp => {
                commit('setConversionsAreaStatuses', resp.toObject().areaTypeStatusesMap);
            }).catch(err => {
                console.log(err);
                commit('setErrorMessage', 'An Error Occured Getting Area Statuses: ' + err.message);
            });

        },

        // getConversionDetails from endpoint and put following in that request. as well as other requests
        async getConversionDetails({ commit }, GetConversionRequest) {
            return await state.conversionsClient.getConversion(GetConversionRequest).then(function (response) {
                commit('setConversionDetails', response.toObject().conversion);
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured Getting Conversion Details: ' + error.message);
            });
        },

        // resetConversionAreaStatuses takes a list of area statuses are returns them to the 'Not Started' state
        async resetConversionAreaStatuses({ commit }, ResetAreaStatusesRequest) {
            return await state.conversionsClient.resetAreaStatuses(ResetAreaStatusesRequest).then(function (response) {
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured Resetting the Area Statuses: ' + error.message);
            });
        },

        // toggleConversionReady toggles a conversion between 'Ready' and 'New'
        async toggleConversionReady({ commit }, ToggleReadyRequest) {
            return await state.conversionsClient.toggleReady(ToggleReadyRequest).then(function (response) {
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured Updating the Conversion State: ' + error.message);
            });
        },

        // toggleConversionPause toggles a conversion between 'Running' or 'Errored' and 'Paused'
        async toggleConversionPause({ commit }, TogglePauseRequest) {
            return await state.conversionsClient.togglePause(TogglePauseRequest).then(function (response) {
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured Pausing the Conversion: ' + error.message);
            });
        },

        // startConversion begins running a conversion that is in the ready state
        async startConversion({ dispatch }, conversionID) {
            let req = new QueueJobRequest();
            req.setConversionId(conversionID);
            req.setType(JobType.JOBTYPESTARTCONVERSION);
            return dispatch('queueJob', req);
        },

        // deleteConversion removes a conversion
        async deleteConversion({ commit }, DeleteConversionRequest) {
            return await state.conversionsClient.deleteConversion(DeleteConversionRequest).then(function (response) {
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Deleting the Conversion: ' + error.message);
            });
        },

        // createConversion takes a site_id and conversion_type and creates a new conversion for that site
        async createConversion({ commit }, CreateConversionRequest) {
            return await state.conversionsClient.createConversion(CreateConversionRequest).then(function (response) {
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                let errorDetail = error.message;
                if (error.hasOwnProperty('meta')) {
                    for (const attr in error.meta) {
                        errorDetail += ': ' + error.meta[attr]
                    }
                }

                commit('setErrorMessage', 'An Error Occured While Creating the Conversion: ' + errorDetail);
            });

        },

        // getAreaStatusErrors takes a conversion_id and area_type of an errored area_type and returns individual errors
        async getAreaStatusErrors({ commit }, GetErrorsRequest) {
            return await state.conversionsClient.getErrors(GetErrorsRequest).then(function (response) {
                commit('setConversionsAreaErrors', response.toObject());
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Getting Errors For The Area Type: ' + error.message);
            });
        },

        // cancelConversion cancels a conversion
        async cancelConversion({ commit }, CancelConversionRequest) {
            return await state.conversionsClient.cancelConversion(CancelConversionRequest).then(function (response) {
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Canceling the Conversion: ' + error.message);
            });
        },

        // archiveConversion archives a conversion
        async archiveConversion({ commit }, ArchiveConversionRequest) {
            return await state.conversionsClient.archiveConversion(ArchiveConversionRequest).then(function (response) {
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Archiving the Conversion: ' + error.message);
            });
        },

        async initializeXMLImportForConversion({ dispatch, commit, getters }, file) {
            let status = getters.getConversionsPollingForImportStatus;
            let conversionID = getters.allConversionDetails.id;
            let presignedURL = getters.getConversionsPresignedURL;
            let args = {
                conversionID,
                presignedURL,
                file,
            }

            commit('setConversionsImportLog', undefined);

            switch (status) {
                case 'importStarted':
                    dispatch('generateConversionsPresignedURL', args);
                    break;
                case 'fileUploaded':
                    dispatch('startConversionsXMLFileImport', args.conversionID);
                    break;
                case 'presignedURL':
                    if (!args.presignedURL) {
                        dispatch('generateConversionsPresignedURL', args);
                        break;
                    }
                    dispatch('uploadConversionsXMLFileToS3', args);
                    break;
                default:
                    dispatch('generateConversionsPresignedURL', args);
            }
        },

        async generateConversionsPresignedURL({ getters, commit, dispatch }, args) {
            //generateConversionsPresignedURL calls the conversion service to get a presigned url for upload to s3
            //thenn passes the file off to be uploaded to that url
            let conversion = getters.allConversionDetails;
            let GetUploadURLRequest = new ConversionRequest();
            GetUploadURLRequest.setConversionId(args.conversionID);

            return await state.conversionsClient.getUploadURL(GetUploadURLRequest).then(function (response) {
                args.presignedURL = response.toObject().uploadUrl;
                commit('setConversionsPresignedURL', args.presignedURL);
                commit('setConversionsUploadStatus', 'presignedURL');
                dispatch('uploadConversionsXMLFileToS3', args);
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', `[ ${conversion.siteName} ] - an error occurred getting presigned url: ${error.message}`);
            });
        },

        async uploadConversionsXMLFileToS3({ getters, commit, dispatch }, args) {
            //uploadConversionsXMLFileToS3 uploads an xml zip file to s3, then passes the
            //conversion id off to have an import job started for this conversion
            let conversion = getters.allConversionDetails;
            await axios.put(args.presignedURL, args.file, {
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    console.log(percentCompleted);
                },
                headers: {
                    'Content-Type': args.file.type
                }
            }).then(() => {
                commit('setConversionsUploadStatus', 'fileUploaded');
                let newQueueJobStartImportRequest = new QueueJobRequest();
                newQueueJobStartImportRequest.setConversionId(args.conversionID);
                newQueueJobStartImportRequest.setType(1); // import enum
                dispatch('startConversionsXMLFileImport', newQueueJobStartImportRequest);
                commit('setConversionsPollForImportFeedback', true);
                dispatch('clearConversionsImportLog');
            }).catch(error => {
                commit('setErrorMessage', `[ ${conversion.siteName} ] - an error occurred uploading to s3: ${error.message}`);
            });
        },

        async startConversionsXMLFileImport({ getters, commit, dispatch }, QueueJobStartImportRequest) {
            //startConversionsXMLFileImport calls the conversion service to start an import job
            //on success it then emits an 'import-running' event so the client knows
            //to listen for state changes
            let conversion = getters.allConversionDetails;
            let newQueueJobStartImportRequest = QueueJobStartImportRequest;
            return await state.conversionsClient.queueJob(newQueueJobStartImportRequest).then(function (response) {
                commit('setConversionsUploadStatus', 'importStarted');
                let req = new ConversionRequest();
                req.setConversionId(conversion.id);
                dispatch('getConversionJobLog', req);

                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', `[ ${conversion.siteName} ] - an error occurred starting the import: ${error.message}`);
            });
        },

        // settingConversionSearchFilters will set the search filters to be displayed
        settingConversionSearchFilters({ commit }, e) {
            commit('setConversionsFilters', e);
        },

        // removeConversionSearchFilter takes a search filter as a string and removes it from vuex
        removeConversionSearchFilter({ dispatch, getters }, filter) {
            var allFilters = getters.getConversionSearchFilters;
            if (filter.filterType == "Site Name") {
                allFilters.siteName = "";
            }
            else if (filter.filterType == "Site Number") {
                allFilters.siteNumber = "";
            }
            else if (filter.filterType == "Created Before") {
                allFilters.dateCreatedOnEnd = ""
            }
            else if (filter.filterType == "Created After") {
                allFilters.dateCreatedOnStart = "";
            }
            else if (filter.filterType == "Archived Included") {
                allFilters.includeInactive = false;
            }
            else if (filter.filterType == "Followed By Me") {
                allFilters.onlyFollowed = false;
            }
            else if (filter.filterType == "Status") {
                var s
                for (s = 0; s < allFilters.statuses.length; s++) {
                    if (allFilters.statuses[s] == filter.filterValue) {
                        allFilters.statuses.splice(s, 1);
                    }
                }
            }
            else if (filter.filterType == "Conversion Type") {
                var t
                for (t = 0; t < allFilters.conversionTypes.length; t++) {
                    if (allFilters.conversionTypes[t] == filter.filterValue) {
                        allFilters.conversionTypes.splice(t, 1);
                    }
                }
            }

            let newConversionListFilterVar = new ConversionListRequest();
            newConversionListFilterVar.setSiteNumber(allFilters.siteNumber);
            newConversionListFilterVar.setSiteName(allFilters.siteName);
            newConversionListFilterVar.setStatusList(allFilters.statuses);
            newConversionListFilterVar.setStartDate(allFilters.dateCreatedOnStart);
            newConversionListFilterVar.setStopDate(allFilters.dateCreatedOnEnd);
            newConversionListFilterVar.setUserIdsList(allFilters.watchers);
            newConversionListFilterVar.setIncludeInactive(allFilters.includeInactive);
            newConversionListFilterVar.setConversionTypesList(allFilters.conversionTypes);

            dispatch('settingConversionSearchFilters', allFilters);
        },

        // listConversionWatchers returns a list of all watchers in the database
        async listConversionWatchers({ commit }, ListConversionWatchersRequest) {
            if (ListConversionWatchersRequest.toObject().conversionId != "") {
                return await state.conversionsClient.listUsers(ListConversionWatchersRequest).then(function (response) {
                    if (Object.keys(response.toObject().usersList).length) {
                        commit('setConversionsActiveWatchers', response.toObject().usersList);
                        commit('setConversionsInitialErrorMessage', '');
                    }
                    return response.toObject();
                }).catch(function (error) {
                    console.log(error);
                    commit('setErrorMessage', 'An Error Occured Getting Watchers');
                });
            } else {
                return await state.conversionsClient.listUsers(ListConversionWatchersRequest).then(function (response) {
                    if (response.toObject().usersList != {}) {
                        commit('setConversionsWatchers', response.toObject().usersList);
                        commit('setConversionsInitialErrorMessage', '');
                    }
                    return response.toObject();
                }).catch(function (error) {
                    console.log(error);
                    commit('setErrorMessage', 'An Error Occured Getting Watchers');
                });
            }
        },

        // addWatcherToConversion adds a watcher to a conversion
        async addWatcherToConversion({ commit }, AddWatcherToConversionRequest) {
            return await state.conversionsClient.addUserToConversion(AddWatcherToConversionRequest).then(function (response) {
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured Adding Watcher To Conversion');
            });
        },

        // removeWatcherFromConversion removes a watcher from a conversion
        async removeWatcherFromConversion({ commit }, RemoveWatcherToConversionRequest) {
            return await state.conversionsClient.removeUserFromConversion(RemoveWatcherToConversionRequest).then(function (response) {
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured Removing Watcher From Conversion');
            });
        },

        // checkWatcher determines whether a watcher had already visited Conversions, and adds them if not
        async checkWatcher({ commit }, CheckUserRequest) {
            return await state.conversionsClient.checkUser(CheckUserRequest).then(function (response) {
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Adding to Watchers: ' + error + ": " + error.message);
            });
        },

        // getConversionsSummary returns the current statuses of conversions in the conversions database
        async getConversionsSummary({ commit }) {
            return await state.conversionsClient.getConversionsSummary().then(function (response) {
                commit('setConversionsSummary', response.toObject());
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setConversionsInitialErrorMessage', 'An Error Occured Getting Conversions Summary');
            });
        },

        // getConversionStatistics gets the statistics of conversions that have been run
        async getConversionStatistics({ commit }, GetConversionStatisticsRequest) {
            return await state.conversionsClient.getStatistics(GetConversionStatisticsRequest).then(function (response) {
                commit('setConversionStatistics', response.toObject());
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Getting Conversion Statistics: ' + error.message);
            });
        },

        // pingServices checks the status of the services that conversions uses
        async pingServices({ commit }, PingServiceListRequest) {
            return await state.conversionsClient.pingServiceList(PingServiceListRequest).then(function (response) {
                commit('setConversionServiceStatuses', response.toObject().servicesList)
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Pinging Service Statuses: ' + error.message);
            });
        },

        // updateWorkerCount updates the amount of workers assigned to an area type
        async updateWorkerCount({ commit }, WorkerCountRequest) {
            return await state.conversionsClient.updateWorkerCount(WorkerCountRequest).then(
            ).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Updating Worker Counts: ' + error.message);
            });
        },

        // listWorkerCounts gets a list of how many workers are assigned to each area type
        async listWorkerCounts({ commit }) {
            return await state.conversionsClient.listWorkerCounts().then(function (response) {
                commit('setAreaTypeWorkerCounts', response.toObject().workerCountsList)
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Getting Worker Counts: ' + error.message);
            });
        },

        // toggleMaintenanceModeStatus updates the 'Maintenance Mode' status of the conversions service
        async toggleMaintenanceModeStatus({ commit, dispatch }) {
            return await state.conversionsClient.toggleMaintenanceModeStatus().then(() => {
                dispatch('getMaintenanceModeStatus');
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Updating Maintenance Mode Status: ' + error.message);
            });
        },

        // getMaintenanceModeStatus gets the 'Maintenance Mode' status of the conversions service
        async getMaintenanceModeStatus({ commit }) {
            return await state.conversionsClient.getMaintenanceModeStatus().then(function (response) {
                commit('setConversionsInMaintenance', response.toObject().on)
                commit('setConversionsInMaintenanceAt', response.toObject().lastUpdated)
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Getting the Maintenance Mode Status: ' + error.message);
            });
        },

        // getImportLog returns the import log for a conversion
        async getImportLog({ commit }, GetConversionImportLogRequest) {
            return await state.conversionsClient.getImportLog(GetConversionImportLogRequest).then(function (response) {
                commit('setConversionsImportLog', response.toObject().importLogsList);
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Getting the Import Log: ' + error.message);
            });
        },

        // queueJob queues a job to be completed
        async queueJob({ dispatch, commit, getters }, queueJobRequest) {
            return await state.conversionsClient.queueJob(queueJobRequest).then(function () {
                let jobType = queueJobRequest.getType();

                if (jobType === JobType.JOBTYPESTAGINGMIGRATION || jobType === JobType.JOBTYPETESTSERVICEJOB) {
                    dispatch('getConversionsServiceJobLog');
                } else {
                    let req = new ConversionRequest();
                    req.setConversionId(getters.allConversionDetails.id);
                    dispatch('getConversionJobLog', req)
                }
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Queuing the Job: ' + error.message);
            });
        },

        // getConversionJobLog returns the service job log for a conversion
        async getConversionJobLog({ commit }, ConversionsJobRequest) {
            return await state.conversionsClient.listConversionJobs(ConversionsJobRequest).then(function (response) {
                commit('setConversionsJobLog', response.toObject().jobsList);
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Getting the Conversions Job Log: ' + error.message);
            });
        },

        // getConversionsServiceJobLog returns the service job log for a conversion
        async getConversionsServiceJobLog({ commit }, ConversionsServiceJobRequest) {
            return await state.conversionsClient.listServiceJobs(ConversionsServiceJobRequest).then(function (response) {
                commit('setConversionsServiceJobLog', response.toObject().jobsList);
            }).catch(function (error) {
                console.log(error);
                commit('setErrorMessage', 'An Error Occured While Getting the Service Job Log: ' + error.message);
            });
        },

        getConversions({ commit }, listConversionsRequest) {
            return state.conversionsClient.listConversions(listConversionsRequest).then(function (response) {
                return response.toObject();
            }).catch(function (error) {
                console.log(error);
                commit('setConversionsInitialErrorMessage', 'An Error Occured Getting Conversions');
            });
        },
        initPolling({ getters }, cb) {
            let duration = 10000;
            let debugMsg;
            //dispatch methods only take one param, can be anything or an args object.
            //detect if an object here if needing to override the default duration - props expexted `{
            //  cb Function
            //  duration Number    
            //}`
            if (typeof (cb) === typeof ({})) {
                ({ cb, duration, debugMsg } = cb);
            }
            return setInterval(() => {
                if (!getters.getConversionsInMaintenance) {
                    cb();
                    if (debugMsg) {
                        console.log(debugMsg);
                    }
                }
            }, duration);
        },
        clearPolling(_, ivlID) {
            if (ivlID) {
                clearInterval(ivlID);
            }
        },
        setConversionsPollForImportFeedback({ commit }, newVal) {
            commit('setConversionsPollForImportFeedback', newVal);
        },
        resetConversionsPollForImportFeedback({ commit }) {
            commit('setConversionsPollForImportFeedback', false);
        },
        setErrorMessage({commit}, message) {
            commit('setErrorMessage', message);   
        },
        async getSiteInfo({ commit }, GetSiteByIdRequest) {
            return await state.shepherdClient.getSiteByID(GetSiteByIdRequest).then(resp => {
                return resp.toObject().site;
            }).catch(err => {
                console.log(err);
                commit('setErrorMessage', 'An Error Occurred Getting Site by Id: ' + err.message);
            });
        }
    }
}