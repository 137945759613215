import { bus } from "../../main";

export default {
    state: {
        notification: {},
        currentMESAURL: "",
        currentProxyURL: ""
    },

    getters: {
        // get the notification
        getNotification: state => state.notification,

        momentSecondsToDateTime: () => (seconds) => {
            var newDate = new Date(1970, 0, 1); // Epoch
            newDate.setSeconds(seconds);
            return newDate.toLocaleDateString('en-US') + ", " + newDate.toLocaleTimeString('en-US');
        },
    },

    mutations: {
        // set the notification to the state
        setNotification(state, message) {
            state.notification = { message };
            if (message != "") {
                bus.$emit('notify');
            }
        },
        // set the notification to the state as an error
        setErrorMessage(state, message) {
            state.notification = { message, isError: message != "" }
            if (message != "") {
                bus.$emit('notify');
            }
        },
    },

    actions: {
        // Register your Client's source URL here so you can drive API calls with the environments drop down list.
        setClientURL({ state, rootState }) {
            // Prevent initialization spam of DDL component from changing anything
            if (state.currentProxyURL != rootState.globalModule.currentEnvironment.connectionString) {

                // MESA Host is used when a client needs to communicate to an endpoint on Relay that is...
                // deployed to the selected Environment.
                // **NOTE: You would not use your own Service's Client with this connection string...
                // you *should* be using the RelayClient when utilizing this connection string.
                let MESAHost = rootState.globalModule.currentEnvironment.connectionString;

                // DevConProxy Host is used when a client needs to communicate directly to an existing service...
                // in the selected Environment via a "Proxy Helper" that exists within DevConRelay.
                // **NOTE: You would primarily use this connection string with an existing generated Client.
                let DevConProxyHost = rootState.globalModule.currentEnvironment.proxyConnectionString;

                // Example
                // rootState.exampleModule.exampleClient.setSourceURL(DevConProxyHost);

                // Relay Client Module
                rootState.relayModule.relayClient.setSourceURL(MESAHost);

                // Clients Module
                rootState.clientModule.clientsClient.setSourceURL(DevConProxyHost);

                // Conversions Module
                rootState.conversionsModule.conversionsClient.setSourceURL(DevConProxyHost);
                rootState.conversionsModule.shepherdClient.setSourceURL(DevConProxyHost)

                // Marketing Module
                rootState.marketingModule.marketingClient.setSourceURL(DevConProxyHost);

                // Product Module
                rootState.productModule.sitesClient.setSourceURL(DevConProxyHost);

                // SiteList Module
                rootState.siteListModule.sitesClient.setSourceURL(DevConProxyHost);

                // Accounts Module
                rootState.accountsModule.authClient.setSourceURL(DevConProxyHost);

                // Promos Module
                rootState.promosModule.promosClient.setSourceURL(DevConProxyHost);
                rootState.promosModule.mediaClient.setSourceURL(DevConProxyHost);

                // Save previously set URL to our local flag
                state.currentMESAURL = MESAHost;
                state.currentProxyURL = DevConProxyHost;
            }
        },
    }
}