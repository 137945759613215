import { AreaStatus, ConversionRequest, ConversionStatus, ResetAreaStatusesRequest } from "@acst/mono-realm-conversions";
import ConversionsAreaErrorsCard from "../ConversionsAreaErrorsCard";

const sortSettingKey = 'conversions:area_status:sort_setting';

const itemSort = {
  Alphabetical: 0,
  Status: 1,
  Timeline: 2,
}

const areaStatusList = {
  "AREATOTAL": -1,
  ...AreaStatus
};

//define static global classes to be applied to `th` elements of the data table component here...
const globalHeaderClasses = [
  'at-status-table-header',
  'overline',
  'px-3',
  'font-weight-bold',
  'sticky-header',
];

//define static global classes to be applied to `td` elements of the data table component here...
const globalCellClasses = [
  'grey--text',
];

//Strips 'AREA' from start of string and converts from upper to title case (e.g. 'AREAQUEUED' -> 'Queued').
//It is expected for additional formatting such as converting to multiple words, that this value would be overridden.
const formatAreaTypeText = text => text.charAt(4) + text.slice(5).toLowerCase();

export default {
  name: 'conversions-area-statuses-new',
  components: {
    ConversionsAreaErrorsCard
  },
  props: {},
  data () {
    return {
      AreaStatus: areaStatusList,
      loading: false,
      filterStatus: areaStatusList.AREATOTAL,
      expandAreaType: undefined,
      sortSetting: itemSort.Alphabetical,
      sortOptions: Object.entries(itemSort).map(([text, value]) => { return { text, value } }),
      statusOrder: [
        AreaStatus.AREAHASERRORS,
        AreaStatus.AREARUNNING,
        AreaStatus.AREAQUEUED,
        AreaStatus.AREANOTSTARTED,
        AreaStatus.AREACOMPLETED
      ],
      areaStatusMapByValue: Object.fromEntries(Object.entries(areaStatusList).map(([k,v]) => [v,k])),
      areaStatusFormattedMap: Object.fromEntries(
        Object.entries(AreaStatus)
          .map(([k,v]) => {
            let fmt = formatAreaTypeText(k);
            if (v == AreaStatus.AREAHASERRORS) {
              fmt = "Has Errors";
            }
            if (v == AreaStatus.AREANOTSTARTED) {
              fmt = "Not Started";
            }
            return [v, fmt];
          })
      ),
      pollingInterval: undefined,
    }
  },
  mounted () {
    this.refreshAreaTypeStatuses();
    this.pollForStatuses();

    this.sortSetting = this.getSortSetting();

    let tableHeaders = document.querySelectorAll('.conversions-area-statuses th[class*="AREA"]');
    tableHeaders.forEach(th => {
      th.addEventListener('click', this.filterAreaTypesByStatus, false);
    });
  },
  beforeDestroy() {
    let tableHeaders = document.querySelectorAll('.conversions-area-statuses th[class*="AREA"]');
    tableHeaders.forEach(th => {
      th.removeEventListener('click', this.filterAreaTypesByStatus, false);
    });

    this.stopPollingForStatuses();
  },
  watch: {
    conversionStatus(newStatus, oldStatus) {
      if (this.isRunningStatus(newStatus)) {
        if (!this.isRunningStatus(oldStatus)) {
          this.pollForStatuses();
        }
        return
      }
      this.refreshAreaTypeStatuses()
      this.stopPollingForStatuses()
    },
    sortSetting(newVal) {
      localStorage.setItem(sortSettingKey, newVal.toString())
    },
  },
  computed: {
    headers() {
      return [
        { text: 'Area Type', value: 'label', class: [...globalHeaderClasses], width: 250},
        this.newStatusHeader(this.AreaStatus.AREATOTAL, undefined, 'display-xl-only', 'table-cell'), 
        this.newStatusHeader(this.AreaStatus.AREANOTSTARTED, 'Pending'),
        this.newStatusHeader(this.AreaStatus.AREAQUEUED),
        this.newStatusHeader(this.AreaStatus.AREARUNNING),
        this.newStatusHeader(this.AreaStatus.AREAHASERRORS, 'Errored'),
        this.newStatusHeader(this.AreaStatus.AREACOMPLETED),
      ]
    },
    items() {
      if (this.loading) {
        return [];
      }

      return this.$store.getters.getAllConversionAreaStatuses
        .map(at => {
          // set area type status
          at.status = this.getAreaTypeStatus(at);
          at.show_error_panel = at.status === this.AreaStatus.AREAHASERRORS && this.expandAreaType === at.id;
          return at
        })
        .filter(at => at[this.areaStatusMapByValue[this.filterStatus]])
        .sort(this.sortItems);
    },
    goDark() {
      return this.$store.getters.getTheme;
    },
    //used by watcher of same name to determine need for polling...
    conversionStatus() {
      let info = this.$store.getters.allConversionDetails
      return (info && !info.is_paused) && info.status;
    },
    textColorClass() {
      return `${this.goDark ? 'white--text' : 'grey--text text--darken-2'} font-weight-bold`;
    },
  },
  methods: {
    fetchAreaTypeStatuses() {
      let req  = new ConversionRequest();
      req.setConversionId(this.$route.params.id);
      return this.$store.dispatch('getConversionAreaStatuses', req)
    },
    refreshAreaTypeStatuses() {
      this.loading = true;
      this.fetchAreaTypeStatuses().then(() => { this.loading = false; });
    },
    atStatusText(area) {
      if (area.status === AreaStatus.AREAHASERRORS) {
        return 'errored';
      }
      if (area.status !== AreaStatus.AREANOTSTARTED && area.status !== AreaStatus.AREAQUEUED) {
        return this.areaStatusMapByValue[area.status].slice(4).toLowerCase();
      }
    },
    //determines overall area status based on precedence
    getAreaTypeStatus(area) {
      if (area.AREAHASERRORS) {
        return AreaStatus.AREAHASERRORS;
      }
      if (area.AREARUNNING) {
        return AreaStatus.AREARUNNING;
      }
      if (area.AREAQUEUED) {
        return AreaStatus.AREAQUEUED;
      }
      if (area.AREACOMPLETED === area.AREATOTAL) {
        return AreaStatus.AREACOMPLETED;
      }
      return AreaStatus.AREANOTSTARTED;
    },
    rowClasses(area) {
      return [ 
        this.areaStatusMapByValue[area.status],
      ]
    },
    cellClasses(statusText) {
      return [
        'text-center',
        'grey--text',
        statusText,
        this.AreaStatus[statusText] === this.filterStatus ? 'active' : '',
      ].join(' ');
    },
    filterAreaTypesByStatus({ currentTarget }) {
      this.fetchAreaTypeStatuses();
      const selectedStatus = [...currentTarget.classList].filter(cl => cl.startsWith('AREA'))[0]
      this.filterStatus = this.AreaStatus[selectedStatus];
    },
    clearAreaTypeStatusFilter() {
      this.filterStatus = this.AreaStatus.AREATOTAL;
      this.fetchAreaTypeStatuses();
    },
    newStatusHeader(areaStatus, text, ...additionalColClasses) {
      let areaStatusText = this.areaStatusMapByValue[areaStatus];
      let classList = [areaStatusText, ...globalHeaderClasses];
      let cellClassList = [areaStatusText, ...globalCellClasses];

      if (additionalColClasses.length) {
        classList.push(...additionalColClasses);
        cellClassList.push(...additionalColClasses);
      }

      if (areaStatus === this.filterStatus) {
        classList.push('active', 'primary--text');
        cellClassList.push('active');
      }

      return {
        text: text ?? formatAreaTypeText(areaStatusText),
        value: areaStatusText,
        align: 'center',
        class: classList,
        cellClass: cellClassList,
      }
    },
    pollForStatuses() {
      if (!this.pollingInterval) {
        this.$store.dispatch('initPolling', this.fetchAreaTypeStatuses).then(ivlID => {
          this.pollingInterval = ivlID;
        });
      }
    },
    stopPollingForStatuses() {
      this.$store.dispatch('clearPolling', this.pollingInterval);
    },
    resetAreaStatus(area) {
      this.expandAreaType = undefined;

      let req = new ResetAreaStatusesRequest();
      req.setConversionId(this.$route.params.id);
      req.setAreasList([area]);
      this.$store.dispatch('resetConversionAreaStatuses', req)
        .then(this.fetchAreaTypeStatuses);
    },
    toggleErrorPanel(area) {
      this.expandAreaType = this.expandAreaType === area ? undefined : area;
    },
    getSortSetting() {
      return parseInt(localStorage.getItem(sortSettingKey) || itemSort.Alphabetical);
    },
    sortItems(a,b) {
      let alphaCompare = (a, b) => a.label > b.label ? 1 : -1;
      if (this.sortSetting == itemSort.Status) {
        let statusDiff = this.statusOrder.indexOf(a.status) - this.statusOrder.indexOf(b.status);
        return statusDiff || alphaCompare(a, b);
      }
      if (this.sortSetting == itemSort.Timeline) {
        let rankDiff = a.rank - b.rank;
        return rankDiff || alphaCompare(a, b);
      }
      return alphaCompare(a, b);
    },
    isRunningStatus(status) {
      return [
        ConversionStatus.CONVERSIONRUNNING,
        ConversionStatus.CONVERSIONRUNNINGWITHERRORS,
        ConversionStatus.CONVERSIONERRORED,
      ].indexOf(status) > -1
    }
  },
}


