import { ListAccountsRequest } from "@acst/mono-corp-authentication";
import { DateFilter } from "@acst/mono-corp-authentication";
import AccountSearch from "../AccountSearch/index.vue"
import {
  bus
} from '../../../main'

export default {
  name: 'accounts-list',
  components: {
    AccountSearch
  },
  data () {
    return {
      showFilters: false,
      searchFilters: [],
      footerProps: {
        'items-per-page-options': [15],
        'items-per-page-text': ''
      },
      headers: [
        {
          text: 'Email',
          value: 'email',
          align: "left", 
          sortable: true
        },
        {
          text: 'Email Validated',
          value: 'emailValidated', 
          sortable: true
        },
        {
          text: 'Last Seen',
          value: 'lastSeen', 
          sortable: true
        },
        {
          text: 'Account Locked',
          value: 'locked', 
          sortable: true
        },
        {
          text: 'Locked At',
          value: 'lockedAt', 
          sortable: true
        },
        {
          text: 'Created At',
          value: 'createdAt', 
          sortable: true
        },
      ],
    }
  },

  created () {
    bus.$on('listAccounts', () => {
      this.listAccounts();
    });
  },

  mounted() {
    this.listAccounts();
  },

  computed: {
    computedColor: function () {
      return this.$store.getters.getTheme ? 'black' : 'white';
    },

    accountsList: function () {
      return this.$store.getters.allGlobalAuthAccounts.map(this.setAccountForTable);
    },
  },

  methods: {
    listAccounts() {
      var listAccounts = new ListAccountsRequest;
      var filters = this.$store.getters.accountSearchFilters;
      this.setFiltersForDisplay(filters)

      listAccounts.setEmail(filters.email);
      listAccounts.setEmailValidated(filters.emailValidated);
      listAccounts.setSiteId(filters.siteID);

      // set all date filters
      if (filters.createdAt != undefined) {
        var created = new DateFilter();
        created.setStartDate(filters.createdAt.startDate);
        created.setStopDate(filters.createdAt.stopDate);

        var startSearchDateDefault = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
        var endSearchDateDefault = new Date().toISOString().substr(0,10);

        // checks if the default range has been set
        if (filters.createdAt.startDate == startSearchDateDefault) {
          created.setStartDate("");
        }

        if (filters.createdAt.stopDate == endSearchDateDefault) {
          created.setStopDate("");
        }

        listAccounts.setCreatedAt(created);
      }

      if (filters.lockedAt != undefined) {
        var lockedAt = new DateFilter();
        lockedAt.setStartDate(filters.lockedAt.startDate);
        lockedAt.setStopDate(filters.lockedAt.stopDate);
        
        // checks if the default range has been set
        if (filters.lockedAt.startDate == startSearchDateDefault) {
          lockedAt.setStartDate("");
        }

        if (filters.lockedAt.stopDate == endSearchDateDefault) {
          lockedAt.setStopDate("");
        }

        listAccounts.setLockedAt(lockedAt);
      }

      if (filters.lastSeen != undefined) {
        var lastSeen = new DateFilter();
        lastSeen.setStartDate(filters.lastSeen.startDate);
        lastSeen.setStopDate(filters.lastSeen.stopDate);
        
        // checks if the default range has been set
        if (filters.lastSeen.startDate == startSearchDateDefault) {
          lastSeen.setStartDate("");
        }

        if (filters.lastSeen.stopDate == endSearchDateDefault) {
          lastSeen.setStopDate("");
        }

        listAccounts.setLastSeen(lastSeen);
      }
      this.$store.dispatch('getAccountList', listAccounts);
    },

    setAccountForTable(account) {
      var lastSeenDisplay = "--";
      var lockedAtDisplay = "--";
      var createdAtDisplay = "--";
      if (account.lastSeen != undefined && account.lastSeen != "") {
        var lastSeen = this.trimDate(account.lastSeen.seconds);
        if (lastSeen != undefined) {
          lastSeenDisplay = lastSeen.time + " " + lastSeen.date;
        }
      }
      if (account.lockedAt != undefined && account.lockedAt != "") {
        var lockedAt = this.trimDate(account.lockedAt.seconds);
        if (lockedAt != undefined) {
          lockedAtDisplay = lockedAt.time + " " + lockedAt.date;
        }
      }
      if (account.createdAt != undefined && account.createdAt != "") {
        var createdAt = this.trimDate(account.createdAt.seconds);
        if (createdAt != undefined) {
          createdAtDisplay = createdAt.time + " " + createdAt.date;
        }
      }
      
      return {
        lastSeen: lastSeenDisplay,
        email: account.email,
        emailValidated: account.emailValidated,
        locked: account.locked,
        lockedAt: lockedAtDisplay,
        createdAt: createdAtDisplay,
      }
    },

    // removeFilter removes a search filter from the searchFilters array and the vuex filters when a filter is clicked
    removeFilter(filter) {
      // update vuex with the updated list
      if (filter.filterType == "Created After") {
        bus.$emit('resetCreatedStartDate');
      }
      else if (filter.filterType == "Created Before") {
        bus.$emit('resetCreatedEndDate');
      }
      else if (filter.filterType == "Locked After") {
        bus.$emit('resetLockedStartDate');
      }
      else if (filter.filterType == "Locked Before") {
        bus.$emit('resetLockedEndDate');
      }
      else if (filter.filterType == "Last Seen After") {
        bus.$emit('resetLastSeenStartDate');
      }
      else if (filter.filterType == "Last Seen Before") {
        bus.$emit('resetLastSeenEndDate');
      }
      else if (filter.filterType == "Email") {
        bus.$emit('resetEmail');
      }

      this.$store.dispatch('removeAccountSearchFilter', filter).then(() => {
        this.listAccounts();
      });
    },

    // resets the searchFilters
    resetFilters() {
      this.$store.dispatch('settingAccountsSearchFilters', []); // Reset displayed searchFilters
      this.searchFilters = [];
    },

    // setFiltersForDisplay recieves an object of all the searchFilters and labels them
    setFiltersForDisplay(e) {
      // Reset searchFilters
      this.searchFilters.splice(0, this.searchFilters.length);

      //  Push active searchFilters to display
      if (e.siteID != undefined && e.siteID != ""){
        this.searchFilters.push(this.makeNewFilterObject("Site ID", e.siteID, e.siteID));
      }
      
      if (e.email != undefined && e.email != ""){
        this.searchFilters.push(this.makeNewFilterObject("Email", e.email, e.email));
      }

      if (e.emailValidated != undefined && e.emailValidated != false){
        this.searchFilters.push(this.makeNewFilterObject("Email Validated", e.emailValidated, e.emailValidated));
      }

      // default dates for checking change in date query
      var startSearchDateDefault = new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10);
      var endSearchDateDefault = new Date().toISOString().substr(0,10);
      if (e.createdAt != undefined && e.createdAt != "") {
        if (e.createdAt.startDate != undefined && e.createdAt.startDate != "" && e.createdAt.startDate != startSearchDateDefault) {
          this.searchFilters.push(this.makeNewFilterObject("Created After",  e.createdAt.startDate, e.createdAt.startDate));
        }

        if (e.createdAt.stopDate != undefined && e.createdAt.stopDate != "" && e.createdAt.stopDate != endSearchDateDefault) {
          this.searchFilters.push(this.makeNewFilterObject("Created Before",  e.createdAt.stopDate, e.createdAt.stopDate));
        }
      }

      if (e.lockedAt != undefined && e.lockedAt != "") {
        if (e.lockedAt.startDate != undefined && e.lockedAt.startDate != "" && e.lockedAt.startDate != startSearchDateDefault) {
          this.searchFilters.push(this.makeNewFilterObject("Locked After",  e.lockedAt.startDate, e.lockedAt.startDate));
        }
        if (e.lockedAt.stopDate != undefined && e.lockedAt.stopDate != "" && e.lockedAt.stopDate != endSearchDateDefault) {
          this.searchFilters.push(this.makeNewFilterObject("Locked Before",  e.lockedAt.stopDate, e.lockedAt.stopDate));
        }
      }

      if (e.lastSeen != undefined && e.lastSeen != "") {
        if (e.lastSeen.startDate != undefined && e.lastSeen.startDate != "" && e.lastSeen.startDate != startSearchDateDefault) {
          this.searchFilters.push(this.makeNewFilterObject("Last Seen After",  e.lastSeen.startDate, e.lastSeen.startDate));
        }
        if (e.lastSeen.stopDate != undefined && e.lastSeen.stopDate != "" && e.lastSeen.stopDate != endSearchDateDefault) {
          this.searchFilters.push(this.makeNewFilterObject("Last Seen Before",  e.lastSeen.stopDate, e.lastSeen.stopDate));
        }
      }

      // Only display the filters if any are active
      if (this.searchFilters.length > 0) {
        this.showFilters = true;
      }
      else {
        this.showFilters = false;
      }
    },

    trimDate(e) {
      if (e == null || e == 0) {
        return;
      }

      var date = new Date(1970, 0, 1); // Epoch
      date.setSeconds(e);
      var newDate = new Date(date); // pass in new date.
      const dateTimeFormat = new Intl.DateTimeFormat('en-US', {month: '2-digit', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'America/New_York'});
      const [{ value: month },,{ value: day },,{ value: year },,{value: hour},,{value: minute},,{value:seconds},,{value: timeZone}] = dateTimeFormat .formatToParts(newDate);
      var returnedDate = {
        date: `${month}/${day}/${year}`,
        time: `${hour}:${minute}:${seconds} ${timeZone}`
      }
      return returnedDate;
    },

    makeNewFilterObject(type, value, name) {
      return {
        filterType: type,
        filterValue: value,
        filterDisplayName: name
      }
    },
  }
}