// Product
import Products from '../../views/Products/index.vue'
// import ProductDetails from '../../views/Products/ProductDetails'
// import ProductInfo from './ProductInfo'
// import ProductMetrics from './ProductMetrics'
// import MarketingFlow from './MarketingFlow'
// import ProductPricing from './ProductPricing'
// import ProductLogic from './ProductLogic'
// import AuthSecret from './AuthSecret'
import ProductList from './ProductList/index.vue'
import ProductDetailView from './ProductDetailView/index.vue'

export default [
  {
    path: '/Products',
    component: Products,
    children: [
      {
        name: 'Products',
        path: '',
        component: ProductList
      },
      {
        name: 'Products.Detail',
        path: 'Detail/:id',
        component: ProductDetailView
      },
      // {
      //   path: ':id',
      //   name: 'ProductDetails',
      //   component: ProductDetails,
      //   children: [
      //     {
      //       path: 'product-info',
      //       component: ProductInfo,
      //     },
      //     {
      //       path: 'metrics',
      //       component: ProductMetrics,
      //     },
      //     {
      //       path: 'marketing-flow',
      //       component: MarketingFlow,
      //     },
      //     {
      //       path: 'pricing',
      //       component: ProductPricing,
      //     },
      //     {
      //       path: 'logic',
      //       component: ProductLogic,
      //     },
      //     {
      //       path: 'auth-secret',
      //       component: AuthSecret,
      //     },
      //   ],
      // },
    ],
  },
];