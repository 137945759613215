// NOTE: This is a global component for the footer of the bottom of DevConsole.
// Only update this with needed information, if needed.
export default {
  name: 'global-footer',
  components: {},
  props: {
    source: String,
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
  },
  computed: {
  }
}

