// Avoid changing/overwriting anything in this file
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

let opts = {
  theme: {
    dark: false,
  },
  themes: {
    // NOTE: Not actually being used currently.
    // Leaving this here as an example until we figure out other "themes" we want to add.
    light: {
      primary: "#4682b4",
      secondary: "#b0bec5",
      accent: "#8c9eff",
      error: "#b71c1c",
    },
  }
}

export default new Vuetify(opts)