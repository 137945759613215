import { render, staticRenderFns } from "./AdminUserDetails.html?vue&type=template&id=796194d3&scoped=true&external"
import script from "./AdminUserDetails.js?vue&type=script&lang=js&external"
export * from "./AdminUserDetails.js?vue&type=script&lang=js&external"
import style0 from "./AdminUserDetails.scss?vue&type=style&index=0&id=796194d3&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796194d3",
  null
  
)

export default component.exports