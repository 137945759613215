import moment from "moment"

export default {
    formatSeconds(seconds, formatString) {
        var fmt = formatString || "LLL"
        return moment.unix(seconds).format(fmt)
    },
    secondsToDate(seconds) {
        return moment.unix(seconds).format("MM/DD/YYYY")
    },
    secondsToDateTime(seconds) {
        return moment.unix(seconds).format("MM/DD/YYYY h:mm a")
    },
    capitalize(word) {
        return word.charAt(0).toUpperCase() + word.slice(1)
    }
}