import { WorkerCountRequest } from "@acst/mono-realm-conversions";

export default {
  name: 'conversions-update-worker-count',
  components: {},
  data () {
    return {
      height: 600,
      editDialog: false,
      areaTypes: [],
      editedAreaType: {
        areaTypeName: "",
        areaType: 0,
        workerCount: 0,
      },
      search: '',
      headers: [
        {
          text: 'Area Type',
          value: 'areaTypeName',
          align: "left", 
          sortable: false,
        },
        {
          text: 'Worker Count',
          value: 'workerCount',
          align: "left", 
          sortable: false,
        },
        {
          text: 'Edit',
          value: 'edit',
          align: "left", 
          sortable: false,
        },
      ],
      areaTypeEnums: {
        'AreaTypeNotSpecified': 0,
        'Contacts': 1,
        'MinistryAreas': 2,
        'Groups': 3,
        'GroupRostersActive': 4,
        'Events': 5,
        'Meetings': 6,
        'Gifts': 7,
        'FamilyPositions': 8,
        'GivingFunds': 9,
        'GroupTags': 10,
        'Tags': 11,
        'Campuses': 12,
        'Families': 13,
        'Locations': 14,
        'MinistryAreaLeaderPositions': 15,
        'MinistryAreaLeaders ': 16,
        'MinistryAreaAttributes': 17,
        'Notes': 19,
        'SacramentSettings': 20,
        'GroupRostersInactive': 21,
        'Sacraments': 22,
        'SacramentReportSettings': 23,
        'SacramentRecords': 24,
        'SacramentNotes': 25,
        'BankAccounts': 26,
        'TaxReportingInfo': 27,
        'LedgerProjects': 28,
        'FinancialSettings': 29,
        'TransactionTypes': 30,
        'LedgerFunds': 31,
        'Departments': 32,
        'Markings': 33,
        'LedgerHeadings': 34,
        'CoreAccounts': 35,
        'Budgets': 36,
        'BudgetDetails': 37,
        'Vendors': 38,
        'Transactions': 39,
        'UnclearedTransactions': 40,
        'PayrollAdjustments': 41,
        'PayrollEmployeeAdjustments': 42,
        'TimeOffTypes': 43,
        'Media': 44,
        'Employees': 45,
        'PayrollJobs': 46,
        'TransactionBatches': 47,
        'EmployeeJobs': 48,
        'GivingBatches': 49,
        'Campaigns': 50,
        'Pledges': 51,
        'VoidedTransactions': 52,
        'Attachments': 53,
        'RecurringTransactions': 54,
        'Invoices': 55,
        'InvoiceTransactionLinks': 56,
        'Custom1Segments': 57,
        'Custom2Segments': 58,
        'NowProfileInfo ': 59,
        'NowGivingRecord': 60,
        'Fields': 61,
        'BackgroundChecks': 62,
        'NowFamilyBarcodes': 63,
        'EmployeeHistory': 64,
        'EmployeeTimeOffDetails': 65,
      },
      areaTypeEnumNames: {
        0 : 'AreaTypeNotSpecified',
        1 : 'Contacts',
        2 : 'MinistryAreas',
        3 : 'Groups',
        4 : 'GroupRostersActive',
        5 : 'Events',
        6 : 'Meetings',
        7 : 'Gifts',
        8 : 'FamilyPositions',
        9 : 'GivingFunds',
        10 : 'GroupTags',
        11 : 'Tags',
        12 : 'Campuses',
        13 : 'Families',
        14 : 'Locations',
        15 : 'MinistryAreaLeaderPositions',
        16 : 'MinistryAreaLeaders ',
        17 : 'MinistryAreaAttributes',
        19 : 'Notes',
        20 : 'SacramentSettings',
        21 : 'GroupRostersInactive',
        22 : 'Sacraments',
        23 : 'SacramentReportSettings',
        24 : 'SacramentRecords',
        25 : 'SacramentNotes',
        26 : 'BankAccounts',
        27 : 'TaxReportingInfo',
        28 : 'LedgerProjects',
        29 : 'FinancialSettings',
        30 : 'TransactionTypes',
        31 : 'LedgerFunds',
        32 : 'Departments',
        33 : 'Markings',
        34 : 'LedgerHeadings',
        35 : 'CoreAccounts',
        36 : 'Budgets',
        37 : 'BudgetDetails',
        38 : 'Vendors',
        39 : 'Transactions',
        40 : 'UnclearedTransactions',
        41 : 'PayrollAdjustments',
        42 : 'PayrollEmployeeAdjustments',
        43 : 'TimeOffTypes',
        44 : 'Media',
        45 : 'Employees',
        46 : 'PayrollJobs',
        47 : 'TransactionBatches',
        48 : 'EmployeeJobs',
        49 : 'GivingBatches',
        50 : 'Campaigns',
        51 : 'Pledges',
        52 : 'VoidedTransactions',
        53 : 'Attachments',
        54 : 'RecurringTransactions',
        55 : 'Invoices',
        56 : 'InvoiceTransactionLinks',
        57 : 'Custom1Segments',
        58 : 'Custom2Segments',
        59 : 'NowProfileInfo ',
        60 : 'NowGivingRecord',
        61 : 'Fields',
        62 : 'BackgroundChecks',
        63 : 'NowFamilyBarcodes',
        64 : 'EmployeeHistory',
        65 : 'EmployeeTimeOffDetails',
      },
      numAreaTypes: 65,
    }
  },

  mounted() {
    this.refreshWorkerCounts()
  },

  methods: {
    close() {
      this.dialog = false;
    },

    updateWorkerCount(areaType, count) {
      let workerCount = new WorkerCountRequest();
      
      workerCount.setAreaType(areaType);
      workerCount.setWorkerCount(count);
      
      this.$store.dispatch('updateWorkerCount', workerCount).then(() => 
        this.refreshWorkerCounts()
      );
      this.editDialog = false;
    },

    refreshWorkerCounts() {
      this.$store.dispatch('listWorkerCounts').then(() =>
        this.setAreaTypesForDisplay(this.$store.getters.getWorkerCounts)
      );
    },

    // Convert all the are types to a way that is easily displayed in the datatable
    setAreaTypesForDisplay(e){
      var checkReturnedObject;
      this.areaTypes = [];
      var newDataRowItem;
      var found;
      var j;
      var i;

      checkReturnedObject = (e != undefined && e.length > 0)

      // For each area type, check if it is included in the returned area types, otherwises set to default
      for (j = 0; j < this.numAreaTypes; j++) {
        // 18 is not assigned to an area type
        if (j === 18) {
          j++;
        }

        found = false;
        // Don't bother checking this loop if e is defined and longer than 0
        if (checkReturnedObject) {
          for (i = 0; i < e.length; i++) {
            // If one of the area types matches one in the list, set found to try and make the new entry
            if (e[i].areaType === j) {
              found = true;
              newDataRowItem = {
                areaTypeName: e[i].areaTypeName,
                areaType: e[i].areaType,
                workerCount: e[i].workerCount,
              };
              break;
            }
          }
        }

        // Otherwise, set the default value for worker count
        if (!found) {
          newDataRowItem = {
            areaTypeName: this.areaTypeEnumNames[j],
            areaType: j,
            workerCount: 50,
          };
        }
        //Add the new object to the list
        this.areaTypes.push(newDataRowItem);
      }
    },

    openEdit(areaType) {
        this.editDialog = true;
        this.editedAreaType = areaType;
    },
  }
}