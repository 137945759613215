import {
  AddClientRequest,
  DeleteClientRequest,
  GetClientByClientIDRequest,
  ClientsClient
} from '@acst/mono-corp';
var mesa = require("../../store/mesa_client.js").default;

const setupRequest = (req, obj) => {
  for (const prop in req) { // eslint-disable-line no-restricted-syntax
    if (prop.startsWith('set') && prop !== 'setCreatedAt' && prop !== 'setUpdatedAt') {
      let fieldName = prop.substr(3);
      fieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);

      if (typeof obj[fieldName] !== 'undefined') {
        req[prop](obj[fieldName]);
      }
    }
  }

  return req;
}

const getDefaultState = () => {
  return {
    client: {},
    clientsClient: new ClientsClient(mesa.do, "")
  }
}

const state = getDefaultState()

const api = {
  addClient: async (client) => {
    let request = new AddClientRequest();
    setupRequest(request, client);
    return (await state.clientsClient.addClient(request)).toObject();
  },
  deleteClient: (client) => {
    let request = new DeleteClientRequest();
    setupRequest(request, client);
    return state.clientsClient.deleteClient(request);
  },
  getClientByClientID: async (id) => {
    let request = new GetClientByClientIDRequest();
    setupRequest(request, { id });
    return (await state.clientsClient.getClientByClientID(request)).toObject();
  },
}

export default {
    state,

    getters: {
      getClient(state) {
        return state.client;
      },
    },

    mutations: {
      SET_CLIENT(state, payload) {
        state.client = payload;
      },
    },
      
    actions: {
      async addClient({ commit }, client) {
        const object = await api.addClient(client);
        commit('SET_CLIENT', object);
      },
      async deleteClient({ commit, state }) {
        if (state.client && state.client.id) {
          await api.deleteClient(state.client);
          commit('SET_CLIENT', {});
        }
      },
      async getByClientID({ commit }, id) {
        const object = await api.getClientByClientID(id);
        commit('SET_CLIENT', object || {});
      },
    }
}