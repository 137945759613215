// Conversions
import Conversions from '../../views/Conversions/index.vue'
import ConversionsListView from './ConversionsListView/index.vue'
import ConversionsDetailView from './ConversionsDetailView/index.vue'
import ConversionsAdminView from './ConversionsAdminView/index.vue'

export default [
    // Conversions Routes
    {
        path: '/Conversions',
        component: Conversions,
        children: [
            {
                name: 'Conversions',
                path: '',
                component: ConversionsListView
            },
            {
                name: 'Conversions.Detail',
                path: 'Detail/:id',
                component: ConversionsDetailView
            },
            {
                name: 'Conversions.Admin',
                path: 'Admin',
                component: ConversionsAdminView
            },
        ]
    },
];