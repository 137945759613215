// NOTE: Leaving some comments strewn about on here to troubleshoot permission stages if needed in future.
// It can be a pain to remember where each major sticking point is without these left in.
import { UpdateUserPermissionRequest, ListUserPermissionsRequest, GetUserDetailsRequest } from "@acst/mono-ops-devconsole";

export default {
  name: 'admin-user-permissions',
  components: {},
  props: [],
  data () {
    return {
      userId: "",
      userDetailsObject: {},
      mockedJSONPermissionsObj: {},
      relayPermissionsObj: {},
      selectedEnvironment: "",
      environmentPermissions: null,
      envVar: {},
      originalPermissions: {},
      rawOriginalPerms: [],
      selectedPermissions: [],
      confirmationDialog: false,
      readyForSaving: true,
      errorToDisplay: "",
      permsToDelete: [],
      permsToInsert: []
    }
  },
  watch:{
    // Attach to confirmationDialog changes
    confirmationDialog: function () {
      this.readyForSaving = true;
    }
  },
  computed: {},
  mounted () {
    this.GetUserIDFromRoute();
    this.SubscribeToConnString();
    this.GetCurrentEnv();
    this.GetEnvironmentPermissions();
    this.GetCurrentPermissionsForUser();
    this.ResetSelections();
  },
  methods: {
    // Check to see if the SuperAdmin has made any selection changes before trying to make API call
    SelectionsHaveChanged(){
      let hasChanged = false;

      // If these lengths aren't the same we already say something changed (most likely deletions)
      if (this.selectedPermissions.length != this.rawOriginalPerms.length) {
        hasChanged = true; 
      }

      // If length of current selected perms vs original perms are the same length check their contents
      if (!hasChanged){
        this.selectedPermissions.forEach(element => {
          if (!this.rawOriginalPerms.includes(element)){
            hasChanged = true; 
            return hasChanged; // break and return
          }
        });
      }

      return hasChanged;
    },
    // Make sure no changes have occured while we were changing permissions for current user.
    // This is to prevent another SuperAdmin from changing permissions out from under another at the same time.
    ChangesSinceLastPull(newlyPulledPerms) {
      let permsToFilter = newlyPulledPerms;
      let permsToCheck = [];
      let failedComparisonCheck = false;

      permsToFilter.forEach(element => {
        if (element.envId == this.envVar.id) {
          permsToCheck.push(element.scope);
        }
      });

      // console.log("=========")
      // console.log(permsToCheck)
      // console.log(this.rawOriginalPerms)
      // console.log("=========")

      // If the length is the exact same, and both arrays have the same elements, then we return success!
      if (this.rawOriginalPerms.length == permsToCheck.length){
        permsToCheck.forEach(element => {
          if (!this.rawOriginalPerms.includes(element)) {
            failedComparisonCheck = true;
          }
        });
      } else {
        failedComparisonCheck = true;
      }

      return failedComparisonCheck;
    },
  
    ValidPermissionState(newPermsToCheck) {
      let isValidState = true;

      if (!this.SelectionsHaveChanged()){
        // console.log("Failed here");
        isValidState = false;
      }

      // this.selectedPermissions.length > 0 && 
      if (this.SelectionsHaveChanged()) {
        let changesOccured = this.ChangesSinceLastPull(newPermsToCheck);
        if (changesOccured) {
          // console.log("Failed here");
          isValidState = false;
        }
      } else {
        // console.log("Failed here");
        isValidState = false;
      }

      return isValidState;
    },

    SetSelectedPerms() {
      this.selectedPermissions = this.rawOriginalPerms;
    },

    BuildSelectedPermissionsByEnvironment(){
      this.rawOriginalPerms = [];
      this.permsToDelete = [];
      this.permsToInsert = [];

      // Make sure we don't try to iterate over an empty collection
      if (this.originalPermissions.length > 0) {
        this.originalPermissions.forEach(element => {
          if (element.envId == this.envVar.id) {
            this.rawOriginalPerms.push(element.scope);
          }
        });
      }

      this.SetSelectedPerms()
    },
  
    GetCurrentPermissionsForUser() {
      let that = this

      let listUserPermReq = new ListUserPermissionsRequest();
      listUserPermReq.setId(this.userId);

      this.$store.dispatch('listUserPermissions', listUserPermReq)
      .then(function(response) {
        that.originalPermissions = response.permissionsList;
        that.BuildSelectedPermissionsByEnvironment();
      })
      .catch(function(response) {
        console.log('///////----////////');
        console.log(`Error: ${response.message}`);
        console.log('///////----////////');
      });
    },
    // Grab current environment selected, and set the known label for the environment
    GetCurrentEnv() {
      this.envVar = this.$store.getters.getCurrentEnv;
      this.selectedEnvironment = this.envVar.label;
    },

    // Subscribe to the environment dropdown selection event and call this anytime we change environments.
    SubscribeToConnString() {
      this.$store.subscribe((mutation) => {
        if (mutation.type == 'setCurEnvVarConnMutation'){
          // We want to get what environment is currently selected from the global DDL
          this.GetCurrentEnv();
          this.GetEnvironmentPermissions();
          this.BuildSelectedPermissionsByEnvironment();

          // If user changes to another environment reset selections/deselections
          this.ResetSelections();
        }
      });
    },
    GetUserIDFromRoute() {
      this.userId = this.$router.history.current.query.user;
      this.GetUserDetails();
    },

    GetUserDetails() {
      let that = this;
      let newDetailsReq = new GetUserDetailsRequest();
      newDetailsReq.setId(this.userId);

      this.$store.dispatch('getUserDetails', newDetailsReq)
      .then(function(response) {
        that.userDetailsObject = response.user;
      })
      .catch(function(response) {
        console.log('///////----////////');
        console.log(`Error: ${response.message}`);
        console.log('///////----////////');
      });
    },

    // Get latest permissions from Relay based on currently selected Environment
    GetEnvironmentPermissions() {
      let that = this;

      // Grab known service permissions for current Environment from Relay
      this.$store.dispatch("getRelayNameSpaces").then(function(response) {
        // So long as we have *some* kind of response back
        if (response != "") {
          // Set repsonse to an Object for us to do something with.
          that.environmentPermissions = response;
        }
      })
      .catch(function(response) {
        console.log('///////----////////');
        console.log(`Error: ${response.message}`);
        console.log('///////----////////');

        // Reset known permissions if we get an error
        that.environmentPermissions = null;
      });
    },

    ResetSelections() {
      // Reset everything
      this.selectedPermissions = this.rawOriginalPerms;
      this.permsToDelete = [];
      this.permsToInsert = [];
    },
    SaveChangesClicked() {
      this.confirmationDialog = true;
    },
    SaveChangesConfirmation(ans) {
      if (ans == "no"){
        this.confirmationDialog = false;
      } else if (ans == "yes"){
        this.SaveChanges();
      }
    },
    CheckForDeletions() {
      // permsToDelete
      this.originalPermissions.forEach(element => {
        if (!this.selectedPermissions.includes(element.scope)) {
          this.permsToDelete.push(element.id)
        }
      });
    },
    CheckForInserts() {
      // permsToInsert
      this.selectedPermissions.forEach(element => {
        if (!this.rawOriginalPerms.includes(element)) {
          this.permsToInsert.push(element)
        }
      });
      return;
    },
    SaveChanges() {
      let that = this;
      let userIdForPerm = "";
      let envIdForPerm = "";

      if (this.userId != ""){
        userIdForPerm = this.userId;
      } else {
        this.errorToDisplay = "UserId is Invalid";
        console.log("UserId is null or invalid. Try to come back to this page, relog, or contact a developer.");
        this.confirmationDialog = false;
      }

      if (this.envVar.id != "") {
        envIdForPerm = this.envVar.id;
      } else {
        this.errorToDisplay = "EnvId is Invalid";
        console.log("EnvId is null or invalid. Try to come back to this page, relog, or contact a developer.");
        this.confirmationDialog = false;
      }

      // Pull latest User Permissions for selected User
      let listUserPermReq = new ListUserPermissionsRequest();
      listUserPermReq.setId(this.userId);

      this.$store.dispatch('listUserPermissions', listUserPermReq)
      .then(function(response) {
        // Do not allow saving if invalid
        if (!response.permissionsList) {
          return;
        }

        if (!that.ValidPermissionState(response.permissionsList)){
          that.errorToDisplay = "No Changes To Save";
          that.readyForSaving = false;
          // *******************************************************
          // TODO: Fire a modal saying data changed since last time!
          // *******************************************************

          // Stop anything else from happening
          return; 
        }

        that.CheckForDeletions();
        that.CheckForInserts();
        that.GetCurrentPermissionsForUser();

        // Since we passed basic validation for data state we continue!
        let updateUserPermsRequest = new UpdateUserPermissionRequest();
        updateUserPermsRequest.setUserId(userIdForPerm);
        updateUserPermsRequest.setEnvId(envIdForPerm);
        updateUserPermsRequest.setScopestoinsertList(that.permsToInsert);
        updateUserPermsRequest.setScopestodeleteList(that.permsToDelete);

        that.$store.dispatch('updateUserPermission', updateUserPermsRequest).then(function() {
          // -> Refresh/Reset the selected permissions to newly pulled permissions
          that.GetCurrentPermissionsForUser();
          that.confirmationDialog = false;
          that.readyForSaving = false;
        })
        .catch(function(response) {
          console.log('///////----////////');
          console.log(`Error: ${response.message}`);
          console.log('///////----////////');
        });
      })
      .catch(function(response) {
        console.log('///////----////////');
        console.log(`Error: ${response.message}`);
        console.log('///////----////////');
      });
    }
  }
}


