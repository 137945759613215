import { DeactivateUserRequest, ActivateUserRequest } from "@acst/mono-ops-devconsole";
export default {
  name: 'admin-user-management',
  components: {},
  props: [],
  data () {
    return {
      usersList: [],
      hasInitialized: false,
      confirmationDialog: false,
      userToActionOn: {},
      viewType: "active",
      that: this
    }
  },
  computed: {
    // Sort grabbed userList by ASC
    alphabeticalUsersASC(){
      if (this.usersList.length < 1) {
        return []
      }
      return this.usersList.sort((a, b) => (a.email > b.email) ? 1 : -1);
    }
  },
  mounted () {
    // Once the page loads we want to get the list of users available
    this.RefreshUsersList();
  },
  methods: {
    // When this method is called it will swap from active -> inactive and vice versa.
    // This drives a LOT of functionality for this component!
    SwapViewType: function() {
      if (this.viewType == "active"){
        this.viewType = "inactive";
      } else if (this.viewType == "inactive"){
        this.viewType = "active";
      }
      this.RefreshUsersList();
    },

    // Show our confirmation modal when any inactivate/activate button is clicked on a user record.
    ShowConfirmation: function(userId){
      if (userId != ""){
        var foundUser = this.usersList.find(user => user.id === userId);
        
        if (foundUser != null){
          this.confirmationDialog = true;
          this.userToActionOn = foundUser;
        }
      }
    },

    // This is the final barrier for deactivating a user. This is called from the Deactivation modal.
    DeactivateUserConfirmation: function(ans){
      if (ans == "no"){
        this.confirmationDialog = false;
      } else if (ans == "yes"){
        this.DoDeactivation(this.userToActionOn.id)
      }
    },

    // This is the final barrier for activating a user. This is called from the Activation modal.
    ActivateUserConfirmation: function(ans){
      if (ans == "no"){
        this.confirmationDialog = false;
      } else if (ans == "yes"){
        this.DoActivation(this.userToActionOn.id)
      }
    },

    // Generic method made for fetching users based on viewType
    RefreshUsersList: function() {
      // This changes context inside of a promise
      let that = this;

      // If we're viewing 'Active' users we want to pull the endpoint specific to Active only users.
      if (this.viewType == "active"){
        this.$store.dispatch('getUserList')
        .then(function(response) {
          // Assign incoming users to usersObj so we can interact with them
          that.usersList = response["usersList"];
          that.hasInitialized = true;
        })
        .catch(function(response) {
          console.log('///////----////////');
          console.log(`Error: ${response.message}`);
          console.log('///////----////////');
        });
      } else if (this.viewType == "inactive"){ // If we're viewing 'InActive' users we want to pull the endpoint specific to Active only users.
        this.$store.dispatch('getInactiveUserList')
        .then(function(response) {
          // Assign incoming users to usersObj so we can interact with them
          that.usersList = response["usersList"];
          that.hasInitialized = true;
        })
        .catch(function(response) {
          console.log('///////----////////');
          console.log(`Error: ${response.message}`);
          console.log('///////----////////');
        });
      }
    },

    // This method houses the endpoint call itself for deactivating a user
    DoDeactivation: function(givenUserId) {
      let newDeActivateRequest = new DeactivateUserRequest();
      newDeActivateRequest.setId(givenUserId);

      // This changes context inside of a promise
      let that = this;

      this.$store.dispatch('deactivateUser', newDeActivateRequest)
      .then(function(response) {
        console.log(response);
        that.confirmationDialog = false // Close modal once we get a response back
        that.RefreshUsersList();
      })
      .catch(function(response) {
        console.log('///////----////////');
        console.log(`Error: ${response.message}`);
        console.log('///////----////////');
      });
    },

    // This method houses the endpoint call itself for activating a user
    DoActivation: function(givenUserId) {
      let newActivateRequest = new ActivateUserRequest();
      newActivateRequest.setId(givenUserId);

      // This changes context inside of a promise
      let that = this;

      this.$store.dispatch('activateUser', newActivateRequest)
      .then(function(response) {
        console.log(response);
        that.confirmationDialog = false // Close modal once we get a response back
        that.RefreshUsersList();
      })
      .catch(function(response) {
        console.log('///////----////////');
        console.log(`Error: ${response.message}`);
        console.log('///////----////////');
      });
    }
  }
}


