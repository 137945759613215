<template>
  <v-app id="inspire" :dark="setTheme">
    <!-- Font for Vuetify -->
    <link
      href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900"
      rel="stylesheet"
    />
    <link
      href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css"
      rel="stylesheet"
    />
    <v-main>
      <GlobalNotificationBar />
      <!-- Trying to turn this into a shared component causes issues with mutating the v-model for it -->
      <v-navigation-drawer
        v-model="navVisibility"
        app
        clipped
        temporary
        class="spacing"
      >
        <v-list dense>
          <v-list-item
            v-for="navItem in globalNavItem"
            :key="navItem.title"
            link
            :to="navItem.link"
          >
            <v-list-item-icon>
              <v-icon>{{ navItem.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ navItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app clipped-left class="main-nav">
        <v-app-bar-nav-icon @click.stop="navVisibility = !navVisibility" />

        <v-toolbar-title>ACST - Developer Console</v-toolbar-title>

        <v-col cols="2" sm="2" class="mt-7">
          <!-- <v-select v-on:change="EnvironmentSelected" :items="envVars" :item-text="'label'" :item-value="'id'" v-model="selectedVariableId" dense outlined></v-select> -->
          <v-select
            v-on:change="EnvironmentSelected"
            :items="envVars"
            :item-text="'label'"
            :item-value="'id'"
            v-model="selectedVariable"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col>
          <b>MESAHost:</b> {{ selectedVariable.connectionString }}
        </v-col>
        <v-col>
          <b>DevConProxyHost:</b> {{ selectedVariable.proxyConnectionString }}
        </v-col>

        <v-layout align-right justify-end>
          <!-- Turn this into a global component -->
          <div class="float-right">
            <v-list-item v-if="this.$store.getters.getUserObject.email">
              <v-list-item-avatar>
                <img :src="this.$store.getters.getUserObject.profilePicLink" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                  this.$store.getters.getUserObject.email
                }}</v-list-item-title>
                <v-list-item-subtitle>Logged In</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div class="align-self-center">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-btn primary dark link :to="'/Settings'">Settings</v-btn>
                </v-list-item>

                <v-list-item v-if="this.$store.getters.isAdmin">
                  <v-btn primary dark link :to="'/admin/landing'">Admin</v-btn>
                </v-list-item>

                <v-list-item>
                  <v-btn primary dark @click.stop="dialog = true"
                    >Log Out</v-btn
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-layout>
      </v-app-bar>

      <!-- Display view based on route -->
      <router-view></router-view>

      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="290">
          <v-card>
            <v-card-title class="headline">Are you sure?</v-card-title>
            <v-card-text> Are you sure you wish to log out? </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="LogOutClicked('no')">
                Cancel
              </v-btn>

              <v-btn color="green darken-1" text @click="LogOutClicked('yes')">
                Log Me Out
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <!-- Our footer that we display everywhere -->
      <v-footer app>
        <GlobalFooter />
      </v-footer>
      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="290">
          <v-card>
            <v-card-title class="headline">Are you sure?</v-card-title>
            <v-card-text> Are you sure you wish to log out? </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="LogOutClicked('no')">
                Cancel
              </v-btn>

              <v-btn color="green darken-1" text @click="LogOutClicked('yes')">
                Log Me Out
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
.v-application::v-deep {
  .row {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .v-card__actions {
    padding: 8px !important;
  }
}
.theme--light.v-application {
  background: #f5f5f5;
}
.theme--light.v-app-bar.main-nav {
  background: #ffffff;
}
</style>

<script>
// ==================================================================================================
// NOTE: This is the "root" parent view of the entire project. Things "Global" in nature go here.
// This file is also heavily policed to prevent teams from stepping on each other's toes.
// ==================================================================================================
import Vuetify from "./plugins/vuetify";
import GlobalFooter from "../src/components/GlobalFooter/index.vue";
import GlobalNotificationBar from "../src/components/GlobalNotificationBar/index.vue";

export default {
  name: "App",
  components: {
    GlobalFooter,
    GlobalNotificationBar,
  },
  data: () => ({
    navVisibility: false, // Side-Nav modal
    dialog: false, // This is the logout modal
    goDark: false, // Bool that drives dark/light theme being enabled from Settings
    // This controls what is seen, and clickable, from the Global NavBar
    // Only add to this if you're creating a new Area for your team/project
    globalNavItem: [],
    envVars: [],
    selectedVariable: {},
    watchConn: null,
    watchEnvVars: null,
    watchTheme: null,
  }),
  beforeDestroy() {
    // Calling a subscribed-to watcher will destroy it to prevent duplicate subscriptions
    this.watchConn();
    this.watchTheme();
    this.watchEnvVars();
  },
  created() {
    // This method is responsible for inserting NavItems into the GlobalNavBar based on scope visibility for the user.
    this.InsertNavItems();

    // Subscribe to our connection string being set when DDL is interacted with.
    this.watchConn = this.$store.subscribe((mutation) => {
      if (mutation.type == "setCurEnvVarConnMutation") {
        this.$store.dispatch("setClientURL");
      }
    });

    // Watching for the theme-switch under settings to change
    this.watchTheme = this.$store.subscribe((mutation) => {
      if (mutation.type == "setThemeMutation") {
        this.goDark = this.$store.getters.getTheme;
      }
    });

    // Subscribing to the setEnvVars mutation so we can async update our DDL list on first login
    // We only want this to fire once after logging into a fresh session.
    this.watchEnvVars = this.$store.subscribe((mutation) => {
      if (
        mutation.type == "setEnvVarsMutation" &&
        this.$store.getters.getIsLoggingIn
      ) {
        this.RefreshVarsDDL();
      }
    });
  },
  mounted() {
    // Grabbing our JWT from local storage so we can leverage blocks of its user and permissions data
    let jwt = window.localStorage.getItem("jwt");
    // If our JWT doesn't exist we might as well not even try to do Authenticated calls to Core
    if (jwt != null) {
      this.GrabEnvVarsFromCore();
    }

    this.CheckForUserThemeChoice();
  },
  methods: {
    // This is where teams define their NavBar link items, and what 'Area' scope is needed to see it.
    // Leave the last param as an empty string to not require a navbar item to need a scope for access.
    InsertNavItems() {
      //// Params: Link, Title, Icon, and a Scope that restricts visibility for said NavItem
      // ------------------------------------------------------------------------------------
      // Dashboard
      this.WrapNavWithScope(
        "/Dashboard",
        "Dashboard",
        "mdi-view-dashboard",
        ""
      );
      // Products
      this.WrapNavWithScope("/Products", "Products", "mdi-file-multiple", "");
      // Conversions -- [Requires 'conversions:' scope of some kind]
      this.WrapNavWithScope(
        "/Conversions",
        "Conversions",
        "mdi-view-dashboard",
        "conversions"
      );
      // Sites
      this.WrapNavWithScope(
        "/SiteList",
        "Site List",
        "mdi-format-list-bulleted-square",
        ""
      );
      // Accounts
      this.WrapNavWithScope("/Accounts", "Accounts", "mdi-account-circle", "");
      // Promotions
      this.WrapNavWithScope("/Promotions", "Promotions", "mdi-tag", "");
      // Tokens
      this.WrapNavWithScope("/Tokens", "Tokens", "mdi-account-key", "");
      // Example
      this.WrapNavWithScope(
        "/Exampler/Landing",
        "Exampler",
        "mdi-help-circle-outline",
        ""
      );
      // ------------------------------------------------------------------------------------
    },

    // This method is a helper that wraps the checks for Scope around inserting of NavBar items with...
    // the new hasAreaScope() helper method found in the `global_module` vuex module.
    WrapNavWithScope(linkToWrap, titleToWrap, iconToWrap, ScopeToCheck) {
      // We want to show SuperAdmins what NavItems they'd not normally have access to, but are protected.
      // This is just a visual artifact for SuperAdmins. It does nothing otherwise.
      if (this.$store.getters.isAdmin && ScopeToCheck != "") {
        titleToWrap = titleToWrap + " (*)";
      }

      if (
        this.$store.getters.hasAreaScope(ScopeToCheck) ||
        ScopeToCheck == ""
      ) {
        this.globalNavItem.push({
          link: linkToWrap,
          title: titleToWrap,
          icon: iconToWrap,
        });
      }
    },

    // We want to check to see if the user had previously changed their userTheme
    CheckForUserThemeChoice() {
      // check to see if a key exists for our theme
      let userTheme = window.localStorage.getItem("userTheme");

      // If none exists drop through, otherwise check the string value and assign it an appropriate bool
      if (userTheme != null) {
        if (userTheme == "false") {
          this.$store.dispatch("setCurrentColorScheme", false);
        } else if (userTheme == "true") {
          this.$store.dispatch("setCurrentColorScheme", true);
        }
      }
    },

    // Pulls latest Environment Varables from DevCon-Core
    GrabEnvVarsFromCore() {
      // Keeping context with this=that for use inside promises
      let that = this;

      // Getting latest known Environmant Variables, if any
      this.$store
        .dispatch("getCoreEnvironments")
        .then(function (returnedItem) {
          that.UpdateEnvVars(returnedItem.environmentvarsList);
          that.RefreshVarsDDL();
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    // When the DDL for Environment is interacted with we want to change current ConnString value with it
    EnvironmentSelected() {
      if (this.selectedVariable != null) {
        this.envVars = this.$store.getters.getEnvVarsObject;
        this.selectedVariable = this.envVars.find(
          (env) => env.id === this.selectedVariable
        );
        this.$store.dispatch("setNewEnvVarConnString", this.selectedVariable);
      }
    },
    // Refreshes the DropDown containing EnvVariables
    RefreshVarsDDL() {
      this.envVars = this.$store.getters.getEnvVarsObject;

      // Check to make sure we have environment variables to even look at.
      if (this.envVars.length > 0) {
        // We need to check localStorage for what the previous selectedVar was.
        let foundEnvironment = false;
        let prevSelectedEnv = window.localStorage.getItem("selectedEnv");

        // Read from queryParam on page load to see if we should override
        let urlEnvOverride = this.$router.history.current.query.env;

        // Check to see if we have a environment param in the url we should check to override with.
        if (urlEnvOverride != null && urlEnvOverride != "") {
          // Make sure the url query param is NOT the same as our previously saved local storage env variable
          if (urlEnvOverride != prevSelectedEnv) {
            // Now we check to make sure the url env variable is a valid environment
            if (this.CheckEnvUrlOverride(urlEnvOverride)) {
              prevSelectedEnv = urlEnvOverride;
            }
          }
        }

        // Checking to see if the environment variable in local storage is valid.
        // If it is then we'll set the environment dropdown and current environment to this variable.
        if (prevSelectedEnv != null) {
          for (let x = 0; x < this.envVars.length; x++) {
            if (this.envVars[x].label == prevSelectedEnv) {
              this.selectedVariable = this.envVars[x];
              this.$store.dispatch(
                "setNewEnvVarConnString",
                this.selectedVariable
              );
              foundEnvironment = true;
              break;
            }
          }
        }

        // Otherwise we default to first item in the list!
        // Defaults to first item in the list as the "set" variable
        if (!foundEnvironment) {
          this.selectedVariable = this.envVars[0];
          this.$store.dispatch("setNewEnvVarConnString", this.selectedVariable);
        }
      }
    },

    // Updates the Vuex-saved value for what environments exist from DevCon-Core
    UpdateEnvVars(newVer) {
      this.$store.dispatch("setNewEnvVarsObject", newVer);
    },

    // Logout Button Clicked Event
    LogOutClicked: function (ans) {
      if (ans === "yes") {
        this.$store.dispatch("logout").then(function () {
          // Clear jwt from localStorage
          if (localStorage.getItem("jwt") != null) {
            localStorage.removeItem("jwt");
          }
          window.location.href = "/Login";
        });
      }
      this.dialog = false;
    },
    // Are we Core-Admin? If so, show the Admin Area button on the parent view
    ShowAdminArea: function () {
      // Worry about wrapping this around isAdmin later, need to push this now for the moment.
      this.globalNavItem.unshift({
        link: "/Admin/Landing",
        title: "Admin",
        icon: "mdi-view-dashboard",
      });
    },

    // Check the URL for any environment query params to override our current environment with
    CheckEnvUrlOverride: function (queryParam) {
      let validEnv = false;
      if (queryParam != null && queryParam != "") {
        for (let x = 0; x < this.envVars.length; x++) {
          if (this.envVars[x].label == queryParam) {
            validEnv = true;
            break;
          }
        }
      }
      return validEnv;
    },
  },
  computed: {
    // This will change the root-theme of the project when the `goDark` variable is switched
    setTheme() {
      if (this.goDark == true) {
        //return true;
        return (Vuetify.framework.theme.dark = true);
      } else {
        //return false;
        return (Vuetify.framework.theme.dark = false);
      }
    },
  },
};
</script>