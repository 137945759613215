
export default {
  name: 'conversions-action-control',
  components: {},
  props: {
    action: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    modalDisabled: {
      tyle: Boolean,
      default: false,
    },
    menuAction: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      dialog: false,
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


