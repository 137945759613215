// import consoleStore from "../../store/modules/consoleStore";
// import { DevConsoleClient } from "@acst/mono-ops-devconsole";

export default {
  name: 'callback',
  components: {},
  props: {},
  data: () => ({
    posts: [],
    errors: [],
    text: ""
  }),
  created () {},
  computed: {
  },
  mounted () {
  },
  methods: {
  }
}


