<template>
  <v-container>
    <v-dialog v-model="showAddApplicationDialog" max-width="360">
      <v-card>
        <v-card-title>Add Application</v-card-title>
        <v-card-text>
          <p>This will allow you to provide DevConsole access to an external application.</p>
          <v-form ref="addForm">
            <v-text-field v-model="appName" label="Name" hint="What application needs access?"
              :rules="[rules.required, rules.counter]">
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="$refs.addForm.reset(); showAddApplicationDialog = false;">Cancel</v-btn>
          <v-btn text color="primary" @click="addApp">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteApplicationDialog" max-width="360">
      <v-card>
        <v-card-title>Delete Application?</v-card-title>
        <v-card-text>
          <p>This will permanently delete the <b>{{ selectedApp.name }}</b> application and all associated tokens.
          </p>
          <p v-if="applicationTokens.length > 0">
          <ul>
            <li v-for="token in this.applicationTokens" :key="token.name">{{ token[1] }}</li>
          </ul>
          </p>
          <p>This action cannot be undone.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDeleteApplicationDialog = false;">Cancel
          </v-btn>
          <v-btn text color="error" @click="deleteApp(selectedApp.id)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DeleteTokenDialog v-model="showDeleteTokenDialog" :id="selectedToken.id" :name="selectedToken.name"
      @delete-token="listAppTokens(selectedApp.id)">
    </DeleteTokenDialog>

    <AddUpdateTokenDialog v-model="showAddUpdateTokenDialog" :envs="envs" :id="selectedToken.id"
      :applicationID="selectedApp.id" @add-update-token="listAppTokens(selectedApp.id)" @cancel-update-token="listAppTokens(selectedApp.id)">
    </AddUpdateTokenDialog>

    <CopyTokenDialog></CopyTokenDialog>

    <v-row>
      <v-col>
        <h1>Applications</h1>
      </v-col>
      <v-col align="right">
        <v-btn @click="showAddApplicationDialog = true" outlined color="success">
          <v-icon>mdi-plus</v-icon>Add Application
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-if="applications.length > 0" v-model="selectedAppIndex">
          <v-expansion-panel v-for="app of applications" :key="app.name" @click="listAppTokens(app.id)">
            <v-expansion-panel-header>
              {{ app.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row>
                  <v-col>
                    <TokensList @add-token="promptAddToken" @update-token="promptUpdateToken"
                      :applicationName="app.name" @delete-token="confirmDeleteToken">
                    </TokensList>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="right">
                    <v-btn color="error" text @click="showDeleteApplicationDialog = true">Delete Application</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card v-else>
          <v-container>
            <v-row>
              <v-col align="center">
                <v-icon size="64">mdi-cellphone</v-icon>
                <p>There are no applications.</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AddApplicationRequest, DeleteApplicationRequest, DeleteTokenRequest } from "@acst/mono-ops-devconsole";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import AddUpdateTokenDialog from "../Tokens/AddUpdateTokenDialog";
import CopyTokenDialog from "../Tokens/CopyTokenDialog";
import DeleteTokenDialog from "../Tokens/DeleteTokenDialog";
import TokensList from "../Tokens/TokensList";

export default {
  name: 'admin-applications',
  components: {
    DeleteTokenDialog,
    AddUpdateTokenDialog,
    CopyTokenDialog,
    TokensList
  },
  data() {
    return {
      envs: {},
      selectedToken: {
        id: "",
        name: "",
        token: "",
      },
      selectedAppIndex: null,
      appName: "",
      showAddApplicationDialog: false,
      showDeleteApplicationDialog: false,
      showDeleteTokenDialog: false,
      showAddUpdateTokenDialog: false,
      rules: {
        required: (value) => !!value || "Name is required",
        counter: (value) =>
          value.length <= 50 || "Name cannot be more than 50 characters",
      },
    }
  },
  computed: {
    ...mapGetters(["applications", "applicationTokens"]),
    selectedApp() {
      return this.applications[this.selectedAppIndex] ? this.applications[this.selectedAppIndex] : { id: "0", name: "app" };
    }
  },
  async created() {
    this.listApplications();

    let envs = {};
    const environmentsRes = await this.$store.dispatch("getCoreEnvironments");
    for (let env of environmentsRes.environmentvarsList) {
      // Use Vue.set to enable reactivity for objects in the environments array.
      Vue.set(envs, env.id, {
        name: env.label,
        enabled: false,
        availableScopes: [],
        selectedScopes: [],
      });
    }
    this.envs = envs;
  },
  methods: {
    ...mapActions(["listApplications", "addApplication", "deleteApplication", "listApplicationTokens", "deleteToken"]),
    async listAppTokens(appID) {
      this.selectedToken.id = null;
      await this.listApplicationTokens(appID);
    },
    async addApp() {
      if (!this.$refs.addForm.validate()) {
        return;
      }

      let req = new AddApplicationRequest().setName(this.appName);
      await this.addApplication(req);
      this.appName = "";
      this.showAddApplicationDialog = false;
    },
    async deleteApp(appID) {
      const responses = [];
      for (const token of this.applicationTokens) {
        responses.push(this.deleteToken(new DeleteTokenRequest().setId(token[0])));
      }
      await Promise.all(responses);

      let req = new DeleteApplicationRequest().setId(appID);
      await this.deleteApplication(req);
      this.selectedAppIndex = undefined;
      this.showDeleteApplicationDialog = false;
    },
    resetSelectedToken() {
      this.selectedToken.id = null;
      this.selectedToken.name = null;
      this.selectedToken.token = null;
    },
    promptAddToken() {
      this.resetSelectedToken();
      this.showAddUpdateTokenDialog = true;
    },
    promptCopyToken(name, value) {
      this.resetSelectedToken();
      this.selectedToken.name = name;
      this.selectedToken.token = value;
    },
    promptUpdateToken(id) {
      this.resetSelectedToken();
      this.selectedToken.id = id;
      this.showAddUpdateTokenDialog = true;
    },
    confirmDeleteToken(id, name) {
      this.resetSelectedToken();
      this.selectedToken.id = id;
      this.selectedToken.name = name;
      this.showDeleteTokenDialog = true;
    },
  }
}
</script>