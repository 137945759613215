// NOTE: This file controls the 'Router' for the project and is where they're defined and registered.

// These are App-default components and will not be migrated to dynamic modular-routing.
// Core imports
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../plugins/vuex'

// v----- Generic/Misc -----v
// Login Redirect
import Login from '../views/Login/index.vue'
// Dashboard (initial login landing)
import Dashboard from '../views/Dashboard/index.vue'
// Login-Flow redirect
import Callback from '../views/Callback/index.vue'
// Settings
import Settings from '../views/Settings/index.vue'
// ^----- Generic/Misc -----^

// ************************************************************************
// Routes for new components/areas are no longer done here. All routes are imported...
//    ...dynamically from a 'router.js' file put at the root of your component folder.
// See the ./components/admin folder as an example!
// ************************************************************************

// ------------------------------
// We dynamically search, and validate, for component router definitions here.
const foundRouters = {};

// Find any "router.js" definitions inside of our components folder
// Routes should always be defined inside of a component folder if possible.
// Note: There are a few exceptions to this rule, but they're made for core-app "Views"...
//       that currently do not have any components strictly associated with them.
findRouters(require.context('../components', true, /\/router\.js$/));

// Helper method for finding router.js files.
function findRouters(def) {
  def.keys().forEach((key) => { 
    // Make sure what we found is a valid router definition for a component
    if (def(key).toString() == "[object Module]" && def(key).default != null){
      (foundRouters[key] = def(key));
    }
  });
}
// ------------------------------

Vue.use(VueRouter)

// Base Routes, do not change these!
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes : [
    // =============================
    // Misc Routes, don't touch these!
    {
      path: "/callback",
      name: "Callback",
      component: Callback
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    // DevCon Global Routes
    {
      path: '/',
      name: 'Home',
      component: Dashboard
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    // Settings Routes
    {
      path: '/settings',
      name: 'Settings',
      component: Settings
    },
  ]
})

// Global routeGuard that will redirect to login if a user's JWT is expired or not found (not authenticated).
router.beforeEach(async (to, from, next) => {
  // Check for App Init and check some critical states here...
  // Note: We have to do this here, as a Synch-blocker from routing, as routing..
  //       happens before most of the App is even ready! This only triggers once on App Init.
  await checkForInit();

  // Get our isAuth method setup for use
  let isAuthed = isAuthenticated();

    // Ok cool, we have our user in current-context, so we can just do normal auth-route stuff 
    // Note: Changing this will cause an infinite login/logout loop!
    if (to.name !== 'Login' && to.name != 'Callback' && !isAuthed){
      // Clear jwt from localStorage
      if (localStorage.getItem("jwt") != null) {
        localStorage.removeItem("jwt");
      }
      next({ name: 'Login' });
    }
    else {
      next();

      // Check to see if our TO qeury already has an environment set (this should never exist, but just making sure)
      // EdgeCase: could be where a team has created a module specifically with the environment in mind?
      let currentEnv = "";
      if (to.query.env == null) {
        currentEnv = store.getters.getCurrentEnv.label;
      }

      // Assign our Environment Query Param here....this is a hacky solution but it lets us carry our env param around.
      // Idealy we'd used next({query: {env: yourParamHere}}) but it isn't working as expected!
      if (currentEnv != null && currentEnv != "" && to.path != from.path) {
        router.replace({ query: Object.assign({}, to.query, { env: currentEnv }) });
      }
    }
})

// This function will check for a jwt stored in local storage that was saved on login.
// If the JWT does not exist, or has expired, then we redirect them to login again.
function isAuthenticated() {
  var jwt = window.localStorage.getItem("jwt");

  //If jwt is null, redirect
  //Checks for login and callback pages so there are no infinite loops
  if (jwt === null) {
    // If JWT doesn't exist, and not on /login or /callback then redirect to login
    return false;
  }
  else {  //If not null, check expiry time
    var jwtSplice = jwt.split(".");
    var jwtBody = window.atob(jwtSplice[1]);
    var claimsObj = JSON.parse(jwtBody);
    var expired = claimsObj.exp;
    var expiredM = expired * 1000;
    var nowDate = new Date();
    var now = nowDate.getTime();

    //If expired, redirect
    if (now > expiredM) {
      return false;
    }
  }
  return true;
}

// function hasQueryParams(route) {
//   return !!Object.keys(route.query).length
// }

async function checkForInit() {
  if (store.getters.getIsAppReady == false) {
    // Do config checks here...
    await loadConfig();

    // Once we know we have our config values then we make sure we have our JWT..
    await checkJWTFromCore();

    // We want to make sure we have latest EnvironmentVariables so we can make API calls
    await getEnironmentVars();
  }
}

// Our init functions below here...

// // Find our config values, if any, and load them so we can make API calls
async function loadConfig() {
  
  if (store.getters.getConfig == null){
    // Load Config based on environment (are we deployed in ops, or local dev?)
    await store.dispatch("gatherConfig");

    // Update our ConsoleClientURL so we can get userdata, etc from DevCon-Core
    await store.dispatch("updateConsoleClientURL", store.getters.getConfig.VUE_APP_CONSOLE_API || "http://localhost:11001");
  }
}

// // We need to make sure that if we do not have a JWT stored then we try to grab one.
// // JWT validation checks (expiration, etc) are done elsewhere.
async function checkJWTFromCore(){
    let jwt = window.localStorage.getItem("jwt");

    // Check the status of our JWT
    if (jwt == null) {
        // Grab JWT from DevCon-Core
        await store.dispatch('getCoreToken');
        await store.dispatch('storeUserPermissions', jwt);
        store.dispatch('saveLoggingIn', true);
    } else if (jwt != null) { // Explicit to avoid potential edge-case bugs from triggering this...
        await store.dispatch('storeUserPermissions', jwt);

        // Our user is not defined most likely due to a hard page-refresh...get them!
        if (store.getters.getUserObject.email == "") {
          await getUserObjectData();
        }
    }
}

async function getEnironmentVars() {
  
  // Getting latest known Environmant Variables, if any
  store.dispatch('getCoreEnvironments').then(function(returnedItem) {
    store.dispatch('setNewEnvVarsObject', returnedItem.environmentvarsList);

    // Look for a previously selected environment from our localStorage
    let prevSelectedEnv = window.localStorage.getItem("selectedEnv");
    // Grab a copy of our currently known environments
    let envVars = store.getters.getEnvVarsObject;
    let selectedVariable = null;

    // Looping through our pulled environments we got from Core...
    if (envVars.length > 0) {
      // Did we have a previously selected environment?
      if (prevSelectedEnv != null) {
        // Let's check to see if our previously selected environment is valid.
        for (let x=0; x < envVars.length; x++) {
          if (envVars[x].label == prevSelectedEnv) {
            selectedVariable = envVars[x];
            // We don't want to keep looping if we found what we're looking for.
            break;
          }
        }
      } else {
        // We didn't have a previously selected environment (fresh session? first time login? etc)
        selectedVariable = this.envVars[0];
      }
      store.dispatch('setNewEnvVarConnString', selectedVariable);

      // Once we're done loading our config we change to the new connection string.
      store.dispatch('setClientURL');
    } else {
      // Let's throw a helpful error letting the user know we couldn't get any environments.
      // Note: This should never happen unless Core can't be reached!
      console.log("EnvVars was empty in index.js at line 203!");
    }
  })
  .catch(function(response) {
    console.log(response);
  }).then(function(){
      // We need to stop the app from continuing with its lifecycle until we're ready!
      store.dispatch("saveAppIsDone", true);
  });
}

// // This will pull latest userObject based on current user's JWT
// // JWT is needed before making this call....
async function getUserObjectData() {
    // Save the new user object from the response we got from Dev-Core
    await store.dispatch('getLoggedInUserInfo').then(async function(response) {
      await store.dispatch('saveUserObject', response.user);
    })
    .catch(function(response) {
      console.log(response);
    });
}



// Build up our new routes, if any, into an array to feed to the router.
let newRoutes = [];
for (const route in foundRouters) {
  if (foundRouters[route] != null && foundRouters[route].default[0] != null) {
    newRoutes.push(foundRouters[route].default[0]);
  }
}

// Add our new dynamically found routes
router.addRoutes(newRoutes);

// Update our routes option since getRoutes() is not in our current version of Vue
router.options.routes = router.options.routes.concat(newRoutes);

export default router
