import { StatisticsRequest } from "@acst/mono-realm-conversions";

export default {
  name: 'conversions-headline-metrics',
  components: {},
  props: [],
  data () {
    return {
      conversions_summary_statuses: {},
      statistics: {},
      successfulRecords: 0,
      menuCreatedOnStart: false,
      menuCreatedOnEnd: false,
      dateCreatedOnStart: new Date(new Date() - (30 * 86400000)).toISOString().substr(0, 10),
      dateCreatedOnEnd: new Date().toISOString().substr(0,10),
    }
  },
  created () {
    setTimeout(() => {
      this.getConversionsStatusSummary();
    }, 150);
  },
  methods: {
    getConversionsStatusSummary() {
      this.$store.dispatch('getConversionsSummary').then(() => {
          this.getSuccessfulRecords(); // temporary... Read comments at function call for more info.
      });
      
    },

    // prototype for getting successful records until endpoint update/package update in mono. 
    // TODO get endpoint confirmed/merged and then repull the conversions node package.
    getSuccessfulRecords() {
      let newConversionStatisticsVar = new StatisticsRequest();
      this.$store.dispatch('getConversionStatistics', newConversionStatisticsVar).then(() => {
        this.statistics = this.$store.getters.getConversionStatistics.statisticsList;
        var i;
        for (i = 0; i < this.statistics.length; i++) {
          this.successfulRecords += this.statistics[i].successfulRecords;
        }
        this.conversions_summary_statuses = this.$store.getters.getConversionsSummary; // this will go back on line 31 after temp change.
        this.conversions_summary_statuses.created = this.conversions_summary_statuses.created + this.conversions_summary_statuses.ready;
        this.conversions_summary_statuses.totalRecords = this.successfulRecords;
      });
    }
  }
}


