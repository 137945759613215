// Admin Imports
import store from "../../plugins/vuex"
import Admin from '../../views/Admin/index.vue'
import AdminApplications from './AdminApplications.vue'
import AdminEnvironments from './AdminEnvironments'
import AdminLanding from './AdminLanding'
import AdminUserDetails from './AdminUserDetails'
import AdminUserManagement from './AdminUserManagement'
import AdminUserPermissions from './AdminUserPermissions'

export default [
    {
        path: '/admin',
        name: 'admin',
        beforeEnter(to, from, next) {
            // Example of how to protect a route with our authorizedRoute method
            // Only users with "devconsole:admin" scope, or SuperAdmins, can traverse this route.
            store.dispatch("authorizedRoute", next, "devconsole:admin");
        },
        component: Admin,
        children: [
            {
                path: 'landing',
                name: 'admin/landing',
                component: AdminLanding
            },
            {
                path: 'usermanagement',
                name: 'admin/usermanagement',
                component: AdminUserManagement,
            },
            {
                path: 'environments',
                name: 'admin/environments',
                component: AdminEnvironments
            },
            {
                path: 'usermanagement/userdetails',
                name: 'admin/user/details',
                component: AdminUserDetails
            },
            {
                path: 'usermanagement/userdetails/userpermissions',
                name: 'admin/user/details/permissions',
                component: AdminUserPermissions
            },
            {
                path: 'applications',
                name: 'admin/applications',
                component: AdminApplications
            }
        ]
    },
];