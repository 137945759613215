export default {
  name: 'client-church-info',
  components: {},

  data () {
    return {
      permission: true,
      churchInfo: {},
      churchStatus: "Active",
      addressHasSecondLine: false,
      denominationGroups: {},
      statuses: {},
    }
  },
  
  mounted() {
    this.$store.dispatch('getDenominationsList').then(() => {
      this.denominationGroups = this.$store.getters.denominationGroups;
      this.$store.dispatch('getStatusList').then(() => {
        this.statuses = this.$store.getters.globalSiteStatuses;
        this.createChurchInfo();
      });
    });
    
  },

  methods: {
    createChurchInfo() {
      this.setChurchInfo(this.$store.getters.clientDetails);
    },

    setChurchInfo(e) {
      var endedDate;
      if (e.deactivatedAt == undefined || e.deactivatedAt == "") {
        endedDate = "--";
      }
      else {
        //only show the date for this page
        endedDate = this.trimDate(e.deactivatedAt.seconds).date;
      }

      var createdDate;
      if (e.createdAt == undefined || e.createdAt == "") {
        createdDate = "--";
      }
      else {
        //only show the date for this page
        createdDate = this.trimDate(e.createdAt.seconds).date;
      }

      this.churchStatus = this.statuses[e.status].name;

      if (e.address.line2 != "" && e.address.line2 != undefined) {
        this.addressHasSecondLine = true;
      }

      var denominationGroup = this.getDenominationGroup(e.denomination)

      this.churchInfo = {
        globalName: e.siteName,
        globalSiteNumber: e.siteNumber,
        globalSiteID: e.siteId,
        globalURL: e.siteSlug,
        phoneNumber: e.phone,
        emailAddress: e.email,
        address: e.address.line1,
        address2: e.address.line2,
        city: e.address.city,
        state: e.address.region,
        zipcode: e.address.postalCode,
        country: e.address.country,
        website: e.website,
        denominationGroup: denominationGroup.name,
        // primaryContactPosition: e.primaryContactPosition,  //Not provided
        primaryContact: 'Secretary: Mary Berry',
        status: this.statuses[e.status].name,
        statusChangeTime: e.statusChangeTime,  //Not provided
        globalSiteCreated: createdDate,
        globalSiteDeactivated: endedDate,
      };
    },

    trimDate(e) {
      if (e == null) {
        console.log("Invalid/Null Date");
        return;
      }

      var date = new Date(1970, 0, 1); // Epoch
      date.setSeconds(e);
      var newDate = new Date(date); // pass in new date.
      const dateTimeFormat = new Intl.DateTimeFormat('en-US', {month: '2-digit', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'America/New_York'});
      const [{ value: month },,{ value: day },,{ value: year },,{value: hour},,{value: minute},,{value:seconds},,{value: timeZone}] = dateTimeFormat .formatToParts(newDate);
      var returnedDate = {
        date: `${month}/${day}/${year}`,
        time: `${hour}:${minute}:${seconds} ${timeZone}`
      }

      return returnedDate;
    },

    getDenominationGroup(id) {
      var denoms = Object.values(this.denominationGroups).filter(function (denominationGroup) {
          // The denomination group will be the enum divided by a thousand and rounded to no decimal places
          return (denominationGroup.enum / 1000).toFixed(0) === (id / 1000).toFixed(0);
        });
      if (denoms.length > 0) {
        return denoms[0];
      }
      return undefined;
    },
  },
}