import { AddPromoRequest, DeletePromoRequest, ListPromosRequest, Promo, UpdatePromoRequest } from "@acst/mono-corp-promos";
import { ImageUploadRequest, WipeImageRequest } from "@acst/mono-ops-relay";
import * as google_protobuf_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import Vue from "vue";

const mediaSiteID = '00000000000000000000000000000000'; // Promos are not site-specific, so we use all zeros.
const mediaImageType = '6'; // Announcement Image Type
const maxImageSizeMB = 10
const sampleBaseURL = 'https://onrealm.org/' // Used for validating internal Realm URLs.

let today = new Date();
let nextWeek = new Date(today.getTime() + 86400000 * 7);
let todayIso = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`
let nextWeekIso = `${nextWeek.getFullYear()}-${String(nextWeek.getMonth() + 1).padStart(2, '0')}-${String(nextWeek.getDate()).padStart(2, '0')}`

export default {
  name: 'promotions',
  components: {},
  props: [],
  data() {
    return {
      calKey: 0,
      promos: {},
      colors: ["#319ACF", "#00897B", "#9135AB", "#E34D4B", "#79B70E", "deep-purple", "indigo", "cyan"],
      calMonth: todayIso,
      selectedPromoId: null,
      focusAfterLoadId: null,
      showUpsertDialog: false,
      showStartDatePicker: false,
      showRangePicker: false,
      showDeleteDialog: false,
      dialogTitle: "",
      promoList: [],
      urlDialog: false,
      urlHelperText: "Link can be an internal link beginning with '/' or an external link beginning with 'http or https",
      fetchedMonths: new Map(),
      formValues: {
        promoId: null,
        title: "",
        message: "",
        linkText: "",
        linkUrl: "",
        dates: [todayIso, nextWeekIso],
        imageFile: null,
        imagePreviewUrl: null, // For previewing local files prior to upload.
        imageLocation: null,
        newImageLocation: null
      },
      formRules: {
        titleRules: [
          v => !!v || 'Title is required',
          v => v && v.length <= 50 || 'Title must be 50 characters or fewer',
        ],
        messageRules: [
          v => !!v || 'Message is required',
          v => v && v.length <= 300 || 'Message must be 300 characters or fewer',
        ],
        startDateRules: [
          v => !!v || 'Start Date is required',
        ],
        dateRangeRules: [
          () => this.formValues.dates[0] <= this.formValues.dates[1] || "End Date must be after Start Date"
        ],
        linkTextRules: [
          v => !!v || 'Link Text is required',
          v => v && v.length <= 50 || 'Link Text must be 50 characters or fewer',
        ],
        linkUrlRules: [
          v => !!v || 'Link URL is required',
          v => v && v.length <= 255 || 'Link URL must be 255 characters or fewer',
          // Checks if 1) the link is provided 2) the link begins with https, http, or / and 3) it is a valid url
          v => (v && (((v.substring(0, 1) == '/') && this.isValidUrl(sampleBaseURL + v)) || this.isValidUrl(v))) || 'Secondary Link URL must begin with http, https, or / and be a valid URL'
        ],

        // non-required fields
        secondaryLinkTextRules: [
          v => (!v || (v && v.length <= 50)) || 'Secondary Link Text must be 50 characters or fewer',
        ],
        secondaryLinkUrlRules: [
          v => (!v || (v && v.length <= 255)) || 'Secondary Link URL must be 255 characters or fewer',
          // Checks if 1) the link begins with https, http, or / and 2) it is a valid url
          v => (!v || (v && (((v.substring(0, 1) == '/') && this.isValidUrl(sampleBaseURL + v)) || this.isValidUrl(v)))) || 'Secondary Link URL must begin with http, https, or / and be a valid URL'
        ],
        imageRules: [
          v => (!!v || this.formValues.imageLocation != null || this.formValues.newImageLocation != null) || "Image is required",
          v => (!v || v && (v.size / 1000 / 1000) <= maxImageSizeMB) || `Image size must be ${maxImageSizeMB} MB or fewer`
        ]
      },
      selectedPromoTitle: "",
    }
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  computed: {
    calPromos() {
      let promos = [];
      for (const [, value] of Object.entries(this.promos)) {
        let startDate = moment(new Date(value.startDate)).utc().format("YYYY-MM-DD");
        let stopDate = moment(new Date(value.stopDate)).utc().format("YYYY-MM-DD");

        promos.push(
          {
            id: value.id,
            name: value.title,
            start: startDate,
            end: stopDate,
            color: this.colors[this.rnd(0, this.colors.length - 1)]
          });
      }

      return promos
    },
    selectedPromo() {
      if (this.promos.hasOwnProperty(this.selectedPromoId)) {
        let promo = this.promos[this.selectedPromoId]
        promo.formattedStart = moment(new Date(promo.startDate)).utc().format("MM/DD/YYYY");
        promo.formattedStop = moment(new Date(promo.stopDate)).utc().format("MM/DD/YYYY");
        return promo
      } else {
        return null;
      }
    },
    dateRangeText() {
      return this.formValues.dates.join(' ~ ')
    }
  },
  watch: {
    showUpsertDialog(val) {
      if (!val) {
        this.formValues = {
          promoId: null,
          title: "",
          message: "",
          linkText: "",
          linkUrl: "",
          dates: [todayIso, nextWeekIso],
          imageFile: null,
          imagePreviewUrl: null,
          imageLocation: null,
          newImageLocation: null,
        };

        this.$refs.upsertForm.resetValidation();
      } else {
        this.dialogTitle = this.formValues.title ? this.formValues.title : 'New Promo';
      }
    }
  },
  methods: {
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    showPromo({ event }) {
      this.selectedPromoId = event.id
    },
    openDialog() {
      this.showUpsertDialog = true;
    },
    closeDialog() {
      this.showUpsertDialog = false;
    },
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    async upsertPromo() {
      // Make sure everything else is good before doing anything image-related.
      const validationPassed = this.$refs.upsertForm.validate();
      if (!validationPassed) {
        return;
      }

      this.formValues.newImageLocation = this.formValues.imageFile ? await this.uploadImage() : null;

      let user = this.$store.getters.getUserObject;
      let promo = new Promo();
      promo.setTitle(this.formValues.title);
      promo.setMessage(this.formValues.message);
      promo.setLinkText(this.formValues.linkText);
      promo.setLinkUrl(this.formValues.linkUrl);
      promo.setStartDate(this.formValues.dates[0]);
      promo.setStopDate(this.formValues.dates[1]);
      promo.setImageLocation(this.formValues.newImageLocation ? this.formValues.newImageLocation : this.formValues.imageLocation);
      promo.setCreatedBy(user.email);
      promo.setSecondaryLinkText(this.formValues.secondaryLinkText);
      promo.setSecondaryLinkUrl(this.formValues.secondaryLinkUrl);

      if (this.formValues.promoId == null) {
        var req = new AddPromoRequest();
        req.setPromo(promo);
        this.$store.dispatch('addPromo', req).then(res => {
          if (res) {
            this.resetCalendar(promo.getStartDate(), res.promo.id)
          } else {
            let newImageID = mediaURLToImageID(this.formValues.newImageLocation);
            this.deleteImage(newImageID); // On add error, delete the promo image.
          }
        });
      } else {
        let req = new UpdatePromoRequest();
        promo.setId(this.formValues.promoId);
        req.setPromo(promo);
        this.$store.dispatch('updatePromo', req).then(res => {
          const imageChanged = this.formValues.newImageLocation !== null;

          if (res) {
            if (imageChanged) {
              this.deleteImage(mediaURLToImageID(this.formValues.imageLocation)); // On update success, delete the old promo image.
            }

            this.resetCalendar(promo.getStartDate(), this.formValues.promoId)
          } else {
            if (imageChanged) {
              this.deleteImage(mediaURLToImageID(this.formValues.newImageLocation)); // On update error, delete the new promo image.
            }
          }
        });
      }
    },
    validateForm() {
      this.refs.upsertForm.validate()
    },
    confirmDeletePromo(promoId) {
      const imageId = mediaURLToImageID(this.selectedPromo.imageLocation);

      let req = new DeletePromoRequest();
      req.setPromoId(promoId);
      this.closeDeleteDialog();

      this.$store.dispatch('deletePromo', req).then(() => {
        let promo = this.promos[promoId];
        this.resetCalendar(promo.startDate);
        this.deleteImage(imageId);
      });
    },
    editPromo(promoId) {
      this.formValues.promoId = promoId;
      let promo = this.promos[promoId];

      let startDate = moment(new Date(promo.startDate)).utc().format("YYYY-MM-DD");
      let stopDate = moment(new Date(promo.stopDate)).utc().format("YYYY-MM-DD");

      this.formValues.title = promo.title;
      this.formValues.message = promo.message;
      this.formValues.linkText = promo.linkText;
      this.formValues.linkUrl = promo.linkUrl;
      this.formValues.dates = [startDate, stopDate]
      this.formValues.secondaryLinkText = promo.secondaryLinkText;
      this.formValues.secondaryLinkUrl = promo.secondaryLinkUrl;
      this.formValues.imageFile = null;
      this.formValues.imagePreviewUrl = null;
      this.formValues.imageLocation = promo.imageLocation;
      this.formValues.newImageLocation = null;

      this.openDialog();
    },
    deletePromo(promoId) {
      let promo = this.promos[promoId];
      this.selectedPromoTitle = promo.title;

      this.openDeleteDialog();
    },
    monthChange(value) {
      if (!this.fetchedMonths.has(value)) {
        let date = new Date(value)
        let firstDay = new Date(date.getUTCFullYear(), date.getUTCMonth(), 1);
        let lastDay = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0);
        var request = new ListPromosRequest();
        request.setStartDate(new google_protobuf_timestamp_pb.Timestamp.fromDate(firstDay));
        request.setStopDate(new google_protobuf_timestamp_pb.Timestamp.fromDate(lastDay));

        this.$store.dispatch('getPromosList', request).then((promos) => {
          promos.forEach(promo => {
            Vue.set(this.promos, promo.id, promo)
          });
          this.fetchedMonths.set(value, true)

          this.selectedPromoId = this.focusAfterLoadId;
          this.focusAfterLoadId = null;
        });
      }
    },
    resetCalendar(focusDate, focusAfterLoadId) {
      this.fetchedMonths = new Map();
      this.promos = {};
      this.selectedPromoId = null;
      this.calMonth = focusDate;
      this.showUpsertDialog = false;
      this.focusAfterLoadId = focusAfterLoadId;

      this.calKey += 1;
    },
    handleFileChange(file) {
      if (file == null) {
        this.formValues.imagePreviewUrl = null;
      } else {
        this.formValues.imagePreviewUrl = URL.createObjectURL(file);
      }
    },
    async uploadImage() {
      const quickToken = await this.$store.dispatch('getMediaQuickToken');
      const fileBytes = await fileToByteArray(this.formValues.imageFile);
      const guid = uuidv4().replaceAll('-', '');

      let imageUploadRequest = new ImageUploadRequest();
      imageUploadRequest.setSiteid(mediaSiteID);
      imageUploadRequest.setImagetype(mediaImageType);
      imageUploadRequest.setImageid(guid);
      imageUploadRequest.setQuicktoken(quickToken);
      imageUploadRequest.setFilebytes(fileBytes);

      const imageURL = await this.$store.dispatch('imageUpload', imageUploadRequest);
      return imageURL;
    },
    async deleteImage(imageId) {
      const quickToken = await this.$store.dispatch('getMediaQuickToken');

      let wipeImageRequest = new WipeImageRequest();
      wipeImageRequest.setSiteid(mediaSiteID);
      wipeImageRequest.setImagetype(mediaImageType);
      wipeImageRequest.setImageid(imageId);
      wipeImageRequest.setQuicktoken(quickToken);

      await this.$store.dispatch('wipeImage', wipeImageRequest);
    },
    openUrlDialog() {
      this.urlDialog = true
    },
    isValidUrl(baseUrl) {
      if (baseUrl.includes(' ')) {
        return false
      }

      let url;

      try {
        url = new URL(baseUrl);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    }
  }
}

// Helpers
async function fileToByteArray(file) {
  return new Uint8Array(await readFile(file));
}

function readFile(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.addEventListener("loadend", e => resolve(e.target.result));
    reader.addEventListener("error", reject);

    reader.readAsArrayBuffer(file);
  })
}

function mediaURLToImageID(mediaURL) {
  return mediaURL.split("/").pop().split(".")[0];
}