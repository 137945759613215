// Exampler Imports
import Exampler from '../../views/Exampler/index.vue'
import ExamplerLanding from './ExamplerLanding/index.vue'
import ExamplerStuff from './ExamplerStuff/index.vue'

export default [
    // Exampler Routes
    {
        path: '/exampler',
        name: 'Exampler',
        component: Exampler,
        children: [
          {
            // Landing will be rendered inside Exampler's <router-view>
            // when /user/:id/profile is matched
            path: 'landing',
            component: ExamplerLanding
          },
          {
            // Stuff will be rendered inside Exampler's <router-view>
            // when /user/:id/posts is matched
            path: 'stuff',
            component: ExamplerStuff
          }
        ]
    },
];