import {
  GetMarketingFlowRequest,
  SaveMarketingFlowRequest,
  MarketingClient,
  MarketingFlow,
} from '@acst/mono-corp-marketing';
var mesa = require("../../store/mesa_client.js").default;

const setupRequest = (req, obj) => {
  for (const prop in req) { // eslint-disable-line no-restricted-syntax
    if (prop.startsWith('set') && prop !== 'setCreatedAt' && prop !== 'setUpdatedAt') {
      let fieldName = prop.substr(3);
      fieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);

      if (typeof obj[fieldName] !== 'undefined') {
        req[prop](obj[fieldName]);
      }
    }
  }

  return req;
}

const getDefaultState = () => {
  return {
    marketing: {},
    marketingClient: new MarketingClient(mesa.do, "")
  }
}

const state = getDefaultState()

const api = {
  saveMarketingFlow: async (marketing) => {
    let entity = new MarketingFlow();
    let request = new SaveMarketingFlowRequest();
    setupRequest(request, {
      marketing: setupRequest(entity, marketing),
    });
    return (await state.marketingClient.saveMarketingFlow(request)).toObject();
  },
  getMarketingFlow: async (productId) => {
    let request = new GetMarketingFlowRequest();
    setupRequest(request, { productId });
    return (await state.marketingClient.getMarketingFlow(request)).toObject();
  },
}

export default {
    state,

    getters: {
      getMarketing(state) {
        return state.marketing;
      },
    },

    mutations: {
      SET_MARKETING(state, payload) {
        state.marketing = payload;
      },
    },
      
    actions: {
      async saveMarketing({ commit }, payload) {
        const { marketing } = await api.saveMarketingFlow(payload);
        commit('SET_MARKETING', marketing);
      },
      async getMarketingByProduct({ commit }, productId) {
        const { marketing } = await api.getMarketingFlow(productId);
        commit('SET_MARKETING', marketing || {});
      },
    }
}