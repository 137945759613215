import {
  CreateConversionRequest,
  ConversionType
} from "@acst/mono-realm-conversions";
import {
} from 'vuex';
import {
  GetSiteByIdRequest
} from "@acst/mono-realm-shepherd";

export default {
  name: 'conversions-create',
  components: {},
  props: [],
  data() {
    return {
      status: "",
      conversionID: "",
      dialog: false,
      siteID: "",
      conversionType: "",
      payload: {},
      hasError: false,
      invalidSiteIDErrorMessage: "",
      blankConversionTypeErrorMessage: "",
      conversionTypes: Object.entries(ConversionType).filter(([, e]) => e > 0).map(([v, e]) => {
        return {
          value: e,
          text: v[0] + v.slice(1).toLowerCase()
        }
      }),
      show_is_partial_checkbox: false,
      is_conversion_partial: false,
      is_partial_label: "",
      loading: false,
      sppMessage: false,
      dialogPrompt: false,
      siteObject: {},
      showShep: false,
    }
  },

  computed: {
    conversionsInMaintenance: function () {
      return this.$store.getters.getConversionsInMaintenance;
    }
  },

  methods: {
    // called on the Create button press on the create conversion dialog.
    createConversion() {
      this.loading = true;
      let newCreateConversionRequest = new CreateConversionRequest();
      if (this.siteID.length != 36) {
        this.hasError = true;
        this.invalidSiteIDErrorMessage = "Invalid Site ID";
        this.loading = false;
      } else if (this.conversionType == '') {
        this.hasError = true;
        this.blankConversionTypeErrorMessage = "Please Select A Converstion Type";
        this.loading = false;
      } else {
        newCreateConversionRequest.setSiteId(this.siteID);
        newCreateConversionRequest.setConversionType(this.conversionType);
        newCreateConversionRequest.setIsSecondPartial(this.is_conversion_partial);
        newCreateConversionRequest.setCreatedBy((this.$store.getters.getUserObject).email);

        this.$store.dispatch('createConversion', newCreateConversionRequest).then(() => {
          this.$emit('created')
          this.resetForm();
        });
      }
    },

    // resets the v-text-fields in the create conversion dialog.
    resetForm() {
      this.dialog = false;
      this.siteID = "";
      this.conversionType = "";
      this.show_is_partial_checkbox = false;
      this.loading = false;
      this.sppMessage = false;
      this.dialogPrompt = false;
    },

    // checkPartial determines the conversion type selected and displays the is_partial checkbox.
    checkPartial(val) {
      let conversionType = this.conversionTypes.filter(t => t.value == val)[0].text
      if (conversionType == 'Full' || conversionType == '') {
        this.is_conversion_partial = false;
        this.show_is_partial_checkbox = false;
        this.sppMessage = false;
      } else if (conversionType == 'Accounting') {
        this.show_is_partial_checkbox = true;
        this.is_partial_label = "Click to confirm People data has already been converted.";
      } else if (conversionType == 'People') {
        this.show_is_partial_checkbox = true;
        this.is_partial_label = "Click to confirm Accounting data has already been converted.";
      }
    },

    // secondPartialMessage shows/hides the message of a second partial conversion
    secondPartialMessage() {
      if (this.is_conversion_partial != null) {
        this.sppMessage = true;
      } else {
        this.sppMessage = false;
        
      }

    },

    promptShep() {
      this.showShep = false;
      if (this.siteID.length > 0) {
        this.dialogPrompt = true;
        let newGetSiteByIdRequest = new GetSiteByIdRequest();
        newGetSiteByIdRequest.setId(this.siteID);

        this.$store.dispatch('getSiteInfo', newGetSiteByIdRequest).then((response) => {
          if (!response.database.name.includes("conversion")) {
            this.siteObject = response;
            this.showShep = true;
          }
        })
          .catch((response) => {
            console.log('///////----////////');
            console.log(`Error: ${response.message}`);
            console.log('///////----////////');
          });

      }
    },

    clearSiteInfo() {
      this.siteID = "";
      this.conversionType = "";
      this.dialogPrompt = false;
    }
  }
}