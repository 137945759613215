//Promos
import Promos from '../../views/Promotions/index.vue'
import PromoComponentView from './PromoView/index.vue'

export default [
    // Conversions Routes
    {
        path: '/Promotions',
        component: Promos,
        children: [
            {
                name: 'Promos',
                path: '',
                component: PromoComponentView
            },
        ]
    },
];