import Vue from 'vue';
import Vuex from 'vuex';

import GlobalModule from '../plugins/vuex_modules/global_module';
import ConversionsModule from '../plugins/vuex_modules/conversions_module';
import SharedModule from '../plugins/vuex_modules/shared_module';
import CoreModule from '../plugins/vuex_modules/core_module';
import RelayModule from '../plugins/vuex_modules/relay_module';
import ExampleModule from '../plugins/vuex_modules/example_module';
import ProductModule from '../plugins/vuex_modules/product_module';
import ClientModule from '../plugins/vuex_modules/clients_module';
import MarketingModule from '../plugins/vuex_modules/marketing_module';
import SiteListModule from './vuex_modules/site_list_module';
import AccountsModule from './vuex_modules/accounts_module';
import PromosModule from './vuex_modules/promos_module';

Vue.use(Vuex);

// The Store has been split into several modules to facilitate multi-team collaboration within DevConsole.
// This is to reduce teams stepping on each others toes, and to allow deployments often.
export default new Vuex.Store({
    modules: {
        globalModule: GlobalModule,
        conversionsModule: ConversionsModule,
        sharedModule: SharedModule,
        coreModule: CoreModule,
        relayModule: RelayModule,
        exampleModule: ExampleModule,
        productModule: ProductModule,
        clientModule: ClientModule,
        marketingModule: MarketingModule,
        siteListModule: SiteListModule,
        accountsModule: AccountsModule,
        promosModule: PromosModule,
        // Teams will import their own modules below here...
        // to keep state management organized between teams.
        // ex: shepherdModule: ShephardModule,
        //     etc: etc
        // See the above global_module file as a template!
    }
});