<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Tokens</h2>
      </v-col>
      <v-col align="right">
        <v-btn @click="$emit('add-token')" outlined color="success">
          <v-icon>mdi-plus</v-icon>Add Token
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list elevation="2" rounded v-if="tokens.length > 0">
          <v-list-item v-for="token in tokens" :key="token[1]">
            <v-list-item-content>
              <v-list-item-title>{{ token[1] }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="$emit('update-token', token[0])">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn icon color="error" @click="$emit('delete-token', token[0], token[1])">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card v-else>
          <v-container>
            <v-row>
              <v-col align="center">
                <v-icon size="64">{{ this.applicationName ? "mdi-cellphone-key" : "mdi-account-key" }}</v-icon>
                <p>{{ this.applicationName ? this.applicationName + " doesn't" : "You don't" }} have any tokens.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "tokens-list",
  props: {
    applicationName: String,
  },
  computed: {
    ...mapGetters(["applicationTokens", "userTokens"]),
    tokens() {
      if (this.applicationName) {
        return this.applicationTokens ? this.applicationTokens : [];
      }
      return this.userTokens ? this.userTokens : [];
    }
  }
};
</script>
