<template>
  <v-container class="tokens-view mx-auto">
    <DeleteTokenDialog v-model="showDeleteDialog" :id="selectedToken.id" :name="selectedToken.name"
      @delete-token="refreshList"></DeleteTokenDialog>

    <AddUpdateTokenDialog v-model="showAddUpdateDialog" :envs="envs" :id="selectedToken.id"
    @add-update-token="refreshList" @cancel-update-token="refreshList"></AddUpdateTokenDialog>

    <CopyTokenDialog></CopyTokenDialog>

    <v-row>
      <v-col>
        <h1>Personal Access Tokens</h1>
        <p>
          Use Personal Access Tokens to manage programmatic access to DevConsole
          and other Mesa tools.
        </p>
      </v-col>
    </v-row>
    <TokensList :tokens="tokens" @add-token="promptAddToken" @update-token="promptUpdateToken"
      @delete-token="confirmDeleteToken"></TokensList>
  </v-container>
</template>

<script>
import AddUpdateTokenDialog from "./AddUpdateTokenDialog";
import CopyTokenDialog from "./CopyTokenDialog";
import DeleteTokenDialog from "./DeleteTokenDialog";
import TokensList from "./TokensList";

import { ListUserPermissionsRequest } from "@acst/mono-ops-devconsole";
import Vue from "vue";

export default {
  name: "tokens-view",
  components: {
    TokensList,
    DeleteTokenDialog,
    AddUpdateTokenDialog,
    CopyTokenDialog,
  },
  data() {
    return {
      tokens: [],
      envs: {},
      selectedToken: {
        id: "",
        name: "",
        token: "",
      },
      showDeleteDialog: false,
      showAddUpdateDialog: false,
      showTokenCopyDialog: false,
    };
  },
  async created() {
    this.refreshList();

    let envs = {};
    const environmentsRes = await this.$store.dispatch("getCoreEnvironments");
    for (let env of environmentsRes.environmentvarsList) {
      // Use Vue.set to enable reactivity for objects in the environments array.
      Vue.set(envs, env.id, {
        name: env.label,
        enabled: false,
        availableScopes: [],
        selectedScopes: [],
      });
    }

    let req = new ListUserPermissionsRequest().setId(
      this.$store.getters.getUserObject.id
    );

    const permissionsRes = await this.$store.dispatch(
      "listUserPermissions",
      req
    );
    for (let perm of permissionsRes.permissionsList) {
      envs[perm.envId].availableScopes.push(perm.scope);
    }

    this.envs = envs;
  },
  methods: {
    promptAddToken() {
      this.resetSelectedToken();
      this.selectedToken.id = null;
      this.showAddUpdateDialog = true;
    },
    promptUpdateToken(id) {
      this.resetSelectedToken();
      this.selectedToken.id = id;
      this.showAddUpdateDialog = true;
    },
    promptCopyToken(name, value) {
      this.resetSelectedToken();
      this.selectedToken.name = name;
      this.selectedToken.token = value;
      this.showTokenCopyDialog = true;
    },
    confirmDeleteToken(id, name) {
      this.resetSelectedToken();
      this.selectedToken.id = id;
      this.selectedToken.name = name;
      this.showDeleteDialog = true;
    },
    refreshList() {
      this.resetSelectedToken();
      this.$store.dispatch("listUserTokens").then((tokens) => {
        this.tokens = tokens;
      });
    },
    resetSelectedToken() {
      this.selectedToken.id = null;
      this.selectedToken.name = null;
      this.selectedToken.token = null;
    },
  },
};
</script>
