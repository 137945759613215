import { render, staticRenderFns } from "./AdminUserPermissions.html?vue&type=template&id=fa67eae2&scoped=true&external"
import script from "./AdminUserPermissions.js?vue&type=script&lang=js&external"
export * from "./AdminUserPermissions.js?vue&type=script&lang=js&external"
import style0 from "./AdminUserPermissions.scss?vue&type=style&index=0&id=fa67eae2&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa67eae2",
  null
  
)

export default component.exports