// Temporary Testing for JWT stuff
// import consoleStore from "../../store/modules/consoleStore";

export default {
  name: 'dashboard',
  components: {},
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    dialog: false
  }),
  created () {
    // Check if we need to refresh our JWT
    this.$store.dispatch('redirect')
    .then(function() {
      // should this contain something more?
    })
    .catch(function(response) {
      console.log("Error: " + response);
    });

  },
  computed: {
  },
  mounted () {},
  methods: {
    // TestCall: function() {
    //   this.$store.dispatch("getRelayNameSpaces").then(function(response) {
    //     console.log('///////////////');
    //     console.log(response);
    //     console.log('///////////////');
    //   })
    //   .catch(function(response) {
    //     console.log('///////----////////');
    //     console.log(`Error: ${response.message}`);
    //     console.log('///////----////////');
    //   });
    // }

    // TestCall2: function() {
    //   consoleStore.ProxyThis({}).then(function(response) {
    //     console.log('///////////////');
    //     console.log(response);
    //     console.log('///////////////');
    //   })
    //   .catch(function(response) {
    //     console.log('///////----////////');
    //     console.log(`Error: ${response.message}`);
    //     console.log('///////----////////');
    //   });
    // }

    // Maybe move most of this functionality, normally associated with logging out, into the vuex method?
    // LogOutClicked: function(ans) {
    //   if (ans === "yes"){
    //     this.$store.dispatch('logout')
    //     .then(function() {
    //       // Clear jwt from localStorage
    //       if (localStorage.getItem("jwt") != null) {
    //         localStorage.removeItem("jwt");
    //       }
    //       window.location.href = "/Login";
    //     });
    //   }
    //   this.dialog = false
    // }
  }
}


